
const sources = [
    {
        "id": 1,
        "author": "Leo Tolstoy",
        "author_img": "leo_tolstoy.jpg",
        "title": "War and Peace",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/2600",
        "lines": [
            { "t": "One step", "nl": 0 },
            { "t": "beyond that line,", "nl": 0 },
            { "t": "reminiscent of", "nl": 0 },
            { "t": "the line separating", "nl": 0 },
            { "t": "the living", "nl": 0 },
            { "t": "from the dead,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "it’s the unknown,", "nl": 0 },
            { "t": "suffering,", "nl": 0 },
            { "t": "and death.", "nl": 0 },
            { "t": "And what is there?", "nl": 0 },
            { "t": "Who is there?", "nl": 0 },
            { "t": "There,", "nl": 0 },
            { "t": "beyond this field,", "nl": 0 },
            { "t": "and the tree,", "nl": 0 },
            { "t": "and the roof", "nl": 0 },
            { "t": "it lit by the sun?", "nl": 0 },
            { "t": "No one knows,", "nl": 0 },
            { "t": "and you would like", "nl": 0 },
            { "t": "to know;", "nl": 0 },
            { "t": "and you’re afraid", "nl": 0 },
            { "t": "to cross that line,", "nl": 0 },
            { "t": "and would like", "nl": 0 },
            { "t": "to cross it;", "nl": 0 },
            { "t": "and you know that", "nl": 0 },
            { "t": "sooner or later", "nl": 0 },
            { "t": "you will have to", "nl": 0 },
            { "t": "cross it", "nl": 0 },
            { "t": "and find out", "nl": 0 },
            { "t": "what is there", "nl": 0 },
            { "t": "on the other side", "nl": 0 },
            { "t": "of the line,", "nl": 0 },
            { "t": "as you will inevitably", "nl": 0 },
            { "t": "find out", "nl": 0 },
            { "t": "what is there", "nl": 0 },
            { "t": "on the other side", "nl": 0 },
            { "t": "of death.", "nl": 0 },
            { "t": "And you’re strong,", "nl": 0 },
            { "t": "healthy,", "nl": 0 },
            { "t": "cheerful,", "nl": 0 },
            { "t": "and excited,", "nl": 0 },
            { "t": "and surrounded", "nl": 0 },
            { "t": "by people", "nl": 0 },
            { "t": "just as strong", "nl": 0 },
            { "t": "and excitedly animated.", "nl": 0 },
            { "t": "So, if he does not", "nl": 0 },
            { "t": "think it,", "nl": 0 },
            { "t": "every man", "nl": 0 },
            { "t": "who finds himself", "nl": 0 },
            { "t": "within sight", "nl": 0 },
            { "t": "of an enemy,", "nl": 0 },
            { "t": "and this feeling", "nl": 0 },
            { "t": "gives a particular", "nl": 0 },
            { "t": "brilliance", "nl": 0 },
            { "t": "and joyous sharpness", "nl": 0 },
            { "t": "of impression", "nl": 0 },
            { "t": "to everything", "nl": 0 },
            { "t": "that happens in those moments.", "nl": 0 },
        ],
    },
    {
        "id": 2,
        "author": "Charles Dickens",
        "author_img": "charles_dickens.jpg",
        "title": "A Tale of Two Cities",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/98",
        "lines": [
            { "t": "I see", "nl": 0 },
            { "t": "that child", "nl": 0 },
            { "t": "who lay upon", "nl": 0 },
            { "t": "her bosom", "nl": 0 },
            { "t": "and who bore", "nl": 0 },
            { "t": "my name,", "nl": 0 },
            { "t": "a man", "nl": 0 },
            { "t": "winning his way up ", "nl": 0 },
            { "t": "in that path of life", "nl": 0 },
            { "t": "which once was mine.", "nl": 0 },
            { "t": "I see him", "nl": 0 },
            { "t": "winning it", "nl": 0 },
            { "t": "so well,", "nl": 0 },
            { "t": "that my name", "nl": 0 },
            { "t": "is made illustrious", "nl": 0 },
            { "t": "there by the light", "nl": 0 },
            { "t": "of his.", "nl": 0 },
            { "t": "I see the", "nl": 0 },
            { "t": "blots I threw", "nl": 0 },
            { "t": "upon it,", "nl": 0 },
            { "t": "faded away.", "nl": 0 },
            { "t": "I see him,", "nl": 0 },
            { "t": "fore-most of just judges", "nl": 0 },
            { "t": "and honoured men,", "nl": 0 },
            { "t": "bringing", "nl": 0 },
            { "t": "a boy of my name,", "nl": 0 },
            { "t": "with a forehead", "nl": 0 },
            { "t": "that I know", "nl": 0 },
            { "t": "and golden hair,", "nl": 0 },
            { "t": "to this place—", "nl": 0 },
            { "t": "then", "nl": 0 },
            { "t": "fair to look upon,", "nl": 0 },
            { "t": "with not a trace", "nl": 0 },
            { "t": "of this day's", "nl": 0 },
            { "t": "disfigurement—", "nl": 0 },
            { "t": "and I hear him", "nl": 0 },
            { "t": "tell the child", "nl": 0 },
            { "t": "my story,", "nl": 0 },
            { "t": "with a tender", "nl": 0 },
            { "t": "and a", "nl": 0 },
            { "t": "faltering voice.", "nl": 2 },
            { "t": "It is a ", "nl": 0 },
            { "t": "far, far better", "nl": 0 },
            { "t": "thing that I do,", "nl": 0 },
            { "t": "than I have ever", "nl": 0 },
            { "t": "done;", "nl": 0 },
            { "t": "it is a", "nl": 0 },
            { "t": "far, far", "nl": 0 },
            { "t": "better rest", "nl": 0 },
            { "t": "that I go to", "nl": 0 },
            { "t": "than I", "nl": 0 },
            { "t": "have ever known.", "nl": 0 },
        ],
    },
    {
        "id": 3,
        "author": "Louisa May Alcott",
        "author_img": "louisa_may_alcott.jpg",
        "title": "Little Women",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/514",
        "lines": [
            { "t": "I don't know", "nl": 0 },
            { "t": "how to", "nl": 0 },
            { "t": "express myself,", "nl": 0 },
            { "t": "and shouldn't try", "nl": 0 },
            { "t": "to anyone", "nl": 0 },
            { "t": "but you,", "nl": 0 },
            { "t": "because I can't", "nl": 0 },
            { "t": "speak out", "nl": 0 },
            { "t": "except to", "nl": 0 },
            { "t": "my Jo.", "nl": 0 },
            { "t": "I only", "nl": 0 },
            { "t": "mean to say", "nl": 0 },
            { "t": "that I have", "nl": 0 },
            { "t": "a feeling", "nl": 0 },
            { "t": "that", "nl": 0 },
            { "t": "it never was", "nl": 0 },
            { "t": "intended", "nl": 0 },
            { "t": "I should live long.", "nl": 0 },
            { "t": "I'm not like", "nl": 0 },
            { "t": "the rest of you.", "nl": 0 },
            { "t": "I never made", "nl": 0 },
            { "t": "any plans", "nl": 0 },
            { "t": "about what", "nl": 0 },
            { "t": "I'd do", "nl": 0 },
            { "t": "when I grew up.", "nl": 0 },
            { "t": "I never", "nl": 0 },
            { "t": "thought of", "nl": 0 },
            { "t": "being married,", "nl": 0 },
            { "t": "as you all did.", "nl": 0 },
            { "t": "I couldn't", "nl": 0 },
            { "t": "seem to", "nl": 0 },
            { "t": "imagine myself", "nl": 0 },
            { "t": "anything but", "nl": 0 },
            { "t": "stupid little", "nl": 0 },
            { "t": "Beth,", "nl": 0 },
            { "t": "trotting about", "nl": 0 },
            { "t": "at home,", "nl": 0 },
            { "t": "of no use", "nl": 0 },
            { "t": "anywhere but there.", "nl": 0 },
            { "t": "I never wanted", "nl": 0 },
            { "t": "to go away,", "nl": 0 },
            { "t": "and the hard part", "nl": 0 },
            { "t": "now is", "nl": 0 },
            { "t": "the leaving", "nl": 0 },
            { "t": "you all.", "nl": 0 },
            { "t": "I'm not afraid,", "nl": 0 },
            { "t": "but it", "nl": 0 },
            { "t": "seems as if", "nl": 0 },
            { "t": "I should be", "nl": 0 },
            { "t": "homesick", "nl": 0 },
            { "t": "for you", "nl": 0 },
            { "t": "even in heaven.", "nl": 0 },
        ],
    },
    {
        "id": 4,
        "author": "James Matthew Barrie",
        "author_img": "james_matthew_barrie.jpg",
        "title": "Peter Pan",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/16",
        "lines": [
            { "t": "All children,", "nl": 0 },
            { "t": "except one,", "nl": 0 },
            { "t": "grow up.", "nl": 0 },
            { "t": "They soon know", "nl": 0 },
            { "t": "that they will", "nl": 0 },
            { "t": "grow up,", "nl": 0 },
            { "t": "and the way", "nl": 0 },
            { "t": "Wendy", "nl": 0 },
            { "t": "knew was this.", "nl": 0 },
            { "t": "One day", "nl": 0 },
            { "t": "when she was", "nl": 0 },
            { "t": "two years old", "nl": 0 },
            { "t": "she was", "nl": 0 },
            { "t": "playing in a garden,", "nl": 0 },
            { "t": "and she plucked", "nl": 0 },
            { "t": "another flower", "nl": 0 },
            { "t": "and ran with it", "nl": 0 },
            { "t": "to her mother.", "nl": 0 },
            { "t": "I suppose", "nl": 0 },
            { "t": "she must have", "nl": 0 },
            { "t": "looked rather delightful,", "nl": 0 },
            { "t": "for Mrs. Darling", "nl": 0 },
            { "t": "put her hand", "nl": 0 },
            { "t": "to her heart", "nl": 0 },
            { "t": "and cried,", "nl": 0 },
            { "t": "“Oh, why can't you", "nl": 0 },
            { "t": "remain like this", "nl": 0 },
            { "t": "for ever!”", "nl": 0 },
            { "t": "This was all", "nl": 0 },
            { "t": "that passed between them", "nl": 0 },
            { "t": "on the subject,", "nl": 0 },
            { "t": "but henceforth", "nl": 0 },
            { "t": "Wendy", "nl": 0 },
            { "t": "knew that", "nl": 0 },
            { "t": "she must", "nl": 0 },
            { "t": "grow up.", "nl": 0 },
            { "t": "You always know", "nl": 0 },
            { "t": "after you are two.", "nl": 0 },
            { "t": "Two is", "nl": 0 },
            { "t": "the beginning of the end.", "nl": 2 },
            { "t": "Of course", "nl": 0 },
            { "t": "they lived at 14", "nl": 0 },
            { "t": "[their house number", "nl": 0 },
            { "t": "on their street],", "nl": 0 },
            { "t": "and until", "nl": 0 },
            { "t": "Wendy came", "nl": 0 },
            { "t": "her mother was", "nl": 0 },
            { "t": "the chief one.", "nl": 0 },
            { "t": "She was", "nl": 0 },
            { "t": "a lovely lady,", "nl": 0 },
            { "t": "with a romantic mind", "nl": 0 },
            { "t": "and such a sweet", "nl": 0 },
            { "t": "mocking mouth.", "nl": 0 },
            { "t": "Her romantic mind", "nl": 0 },
            { "t": "was like", "nl": 0 },
            { "t": "the tiny boxes,", "nl": 0 },
            { "t": "one within the other,", "nl": 0 },
            { "t": "that come from", "nl": 0 },
            { "t": "the puzzling East,", "nl": 0 },
            { "t": "however many", "nl": 0 },
            { "t": "you discover", "nl": 0 },
            { "t": "there is always", "nl": 0 },
            { "t": "one more;", "nl": 0 },
            { "t": "and her sweet", "nl": 0 },
            { "t": "mocking mouth", "nl": 0 },
            { "t": "had one kiss", "nl": 0 },
            { "t": "on it", "nl": 0 },
            { "t": "that Wendy", "nl": 0 },
            { "t": "could never get,", "nl": 0 },
            { "t": "though there it was,", "nl": 0 },
            { "t": "perfectly conspicuous", "nl": 0 },
            { "t": "in the right-hand corner.", "nl": 0 },
        ],
    },
    {
        "id": 5,
        "author": "L. M. Montgomery",
        "author_img": "l_m_montgomery.jpg",
        "title": "Anne of Green Gables",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/45",
        "lines": [
            { "t": "“I’ll win", "nl": 0 },
            { "t": "that scholarship", "nl": 0 },
            { "t": "if hard work", "nl": 0 },
            { "t": "can do it,”", "nl": 0 },
            { "t": "she resolved.", "nl": 0 },
            { "t": "“Wouldn’t Matthew", "nl": 0 },
            { "t": "be proud", "nl": 0 },
            { "t": "if I got", "nl": 0 },
            { "t": "to be a", "nl": 0 },
            { "t": "B.A.?", "nl": 0 },
            { "t": "Oh,", "nl": 0 },
            { "t": "it’s delightful", "nl": 0 },
            { "t": "to have ambitions.", "nl": 0 },
            { "t": "I’m so glad", "nl": 0 },
            { "t": "I have such a lot.", "nl": 0 },
            { "t": "And there never seems", "nl": 0 },
            { "t": "to be", "nl": 0 },
            { "t": "any end to them—", "nl": 0 },
            { "t": "that’s the best of it.", "nl": 0 },
            { "t": "Just as soon", "nl": 0 },
            { "t": "as you attain", "nl": 0 },
            { "t": "to one ambition", "nl": 0 },
            { "t": "you see", "nl": 0 },
            { "t": "another one", "nl": 0 },
            { "t": "glittering", "nl": 0 },
            { "t": "higher up still.", "nl": 0 },
            { "t": "It does", "nl": 0 },
            { "t": "make life", "nl": 0 },
            { "t": "so interesting.”", "nl": 0 },
        ],
    },
    {
        "id": 6,
        "author": "Fyodor Dostoyevsky",
        "author_img": "fyodor_dostoyevsky.jpg",
        "title": "The Brothers Karamazov",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/28054",
        "lines": [
            { "t": "Let me", "nl": 0 },
            { "t": "make it plain.", "nl": 0 },
            { "t": "I believe", "nl": 0 },
            { "t": "like a child", "nl": 0 },
            { "t": "that suffering", "nl": 0 },
            { "t": "will be healed", "nl": 0 },
            { "t": "and made up for,", "nl": 0 },
            { "t": "that all the humiliating absurdity", "nl": 0 },
            { "t": "of human contradictions", "nl": 0 },
            { "t": "will vanish", "nl": 0 },
            { "t": "like a pitiful mirage,", "nl": 0 },
            { "t": "like the", "nl": 0 },
            { "t": "despicable fabrication", "nl": 0 },
            { "t": "of the impotent", "nl": 0 },
            { "t": "and infinitely small", "nl": 0 },
            { "t": "Euclidian mind of man,", "nl": 0 },
            { "t": "that in the world's finale,", "nl": 0 },
            { "t": "at the moment", "nl": 0 },
            { "t": "of eternal harmony,", "nl": 0 },
            { "t": "something so precious", "nl": 0 },
            { "t": "will come to pass", "nl": 0 },
            { "t": "that it will suffice", "nl": 0 },
            { "t": "for all hearts,", "nl": 0 },
            { "t": "for the comforting", "nl": 0 },
            { "t": "of all resentments,", "nl": 0 },
            { "t": "for the atonement", "nl": 0 },
            { "t": "of all the crimes", "nl": 0 },
            { "t": "of humanity,", "nl": 0 },
            { "t": "of all the blood", "nl": 0 },
            { "t": "they've shed;", "nl": 0 },
            { "t": "that it will make it", "nl": 0 },
            { "t": "not only possible", "nl": 0 },
            { "t": "to forgive", "nl": 0 },
            { "t": "but to justify", "nl": 0 },
            { "t": "all that has happened", "nl": 0 },
            { "t": "with men—", "nl": 0 },
            { "t": "but though all that", "nl": 0 },
            { "t": "may come to pass,", "nl": 0 },
            { "t": "I don't accept it.", "nl": 0 },
            { "t": "I won't accept it.", "nl": 0 },
            { "t": "Even if parallel lines", "nl": 0 },
            { "t": "do meet", "nl": 0 },
            { "t": "and I see it myself,", "nl": 0 },
            { "t": "I shall see it", "nl": 0 },
            { "t": "and say that", "nl": 0 },
            { "t": "they've met,", "nl": 0 },
            { "t": "but still", "nl": 0 },
            { "t": "I won't accept it.", "nl": 0 },
            { "t": "That's what's", "nl": 0 },
            { "t": "at the root of me,", "nl": 0 },
            { "t": "Alyosha;", "nl": 0 },
            { "t": "that's my creed.", "nl": 0 },
            { "t": "I am in earnest", "nl": 0 },
            { "t": "in what I say.", "nl": 0 },
            { "t": "I began", "nl": 0 },
            { "t": "our talk", "nl": 0 },
            { "t": "as stupidly", "nl": 0 },
            { "t": "as I could", "nl": 0 },
            { "t": "on purpose,", "nl": 0 },
            { "t": "but I've led up", "nl": 0 },
            { "t": "to my confession,", "nl": 0 },
            { "t": "for that's all you want.", "nl": 0 },
            { "t": "You didn't want", "nl": 0 },
            { "t": "to hear about God,", "nl": 0 },
            { "t": "but only to know", "nl": 0 },
            { "t": "what the brother", "nl": 0 },
            { "t": "you love", "nl": 0 },
            { "t": "lives by.", "nl": 0 },
            { "t": "And so I've told you.”", "nl": 0 },
        ],
    },
    {
        "id": 7,
        "author": "Emily Brontë",
        "author_img": "emily_bronte.jpg",
        "title": "Wuthering Heights",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/768",
        "lines": [
            { "t": "That was", "nl": 0 },
            { "t": "his most perfect idea", "nl": 0 },
            { "t": "of heaven’s happiness:", "nl": 0 },
            { "t": "mine was rocking", "nl": 0 },
            { "t": "in a rustling green tree,", "nl": 0 },
            { "t": "with a west wind blowing,", "nl": 0 },
            { "t": "and bright white clouds", "nl": 0 },
            { "t": "flitting rapidly above;", "nl": 0 },
            { "t": "and not only larks,", "nl": 0 },
            { "t": "but throstles,", "nl": 0 },
            { "t": "and blackbirds,", "nl": 0 },
            { "t": "and linnets,", "nl": 0 },
            { "t": "and cuckoos", "nl": 0 },
            { "t": "pouring out music", "nl": 0 },
            { "t": "on every side,", "nl": 0 },
            { "t": "and the moors", "nl": 0 },
            { "t": "seen at a distance,", "nl": 0 },
            { "t": "broken into", "nl": 0 },
            { "t": "cool dusky dells;", "nl": 0 },
            { "t": "but close by", "nl": 0 },
            { "t": "great swells", "nl": 0 },
            { "t": "of long grass", "nl": 0 },
            { "t": "undulating in waves", "nl": 0 },
            { "t": "to the breeze;", "nl": 0 },
            { "t": "and woods", "nl": 0 },
            { "t": "and sounding water,", "nl": 0 },
            { "t": "and the whole world", "nl": 0 },
            { "t": "awake and wild with joy.", "nl": 0 },
            { "t": "He wanted all", "nl": 0 },
            { "t": "to lie", "nl": 0 },
            { "t": "in an ecstasy", "nl": 0 },
            { "t": "of peace;", "nl": 0 },
            { "t": "I wanted all", "nl": 0 },
            { "t": "to sparkle", "nl": 0 },
            { "t": "and dance", "nl": 0 },
            { "t": "in a glorious jubilee.", "nl": 0 },
            { "t": "I said", "nl": 0 },
            { "t": "his heaven", "nl": 0 },
            { "t": "would be", "nl": 0 },
            { "t": "only half alive;", "nl": 0 },
            { "t": "and he said", "nl": 0 },
            { "t": "mine would be drunk:", "nl": 0 },
            { "t": "I said", "nl": 0 },
            { "t": "I should fall asleep", "nl": 0 },
            { "t": "in his;", "nl": 0 },
            { "t": "and he said", "nl": 0 },
            { "t": "he could not breathe", "nl": 0 },
            { "t": "in mine,", "nl": 0 },
            { "t": "and began", "nl": 0 },
            { "t": "to grow", "nl": 0 },
            { "t": "very snappish.", "nl": 0 },
            { "t": "At last,", "nl": 0 },
            { "t": "we agreed", "nl": 0 },
            { "t": "to try both,", "nl": 0 },
            { "t": "as soon as", "nl": 0 },
            { "t": "the right weather came;", "nl": 0 },
            { "t": "and then", "nl": 0 },
            { "t": "we kissed each other", "nl": 0 },
            { "t": "and were friends.", "nl": 0 },
        ],
    },
    {
        "id": 8,
        "author": "Jack London",
        "author_img": "jack_london.jpg",
        "title": "Call of the Wild",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/215",
        "lines": [
            { "t": "an ecstasy", "nl": 0 },
            { "t": "that marks", "nl": 0 },
            { "t": "the summit of life,", "nl": 0 },
            { "t": "and beyond which", "nl": 0 },
            { "t": "life cannot rise.", "nl": 0 },
            { "t": "And such is", "nl": 0 },
            { "t": "the paradox of living,", "nl": 0 },
            { "t": "this ecstasy comes", "nl": 0 },
            { "t": "when one", "nl": 0 },
            { "t": "is most alive,", "nl": 0 },
            { "t": "and it comes", "nl": 0 },
            { "t": "as a complete forgetfulness", "nl": 0 },
            { "t": "that one is alive.", "nl": 0 },
            { "t": "This ecstasy,", "nl": 0 },
            { "t": "this forgetfulness", "nl": 0 },
            { "t": "of living,", "nl": 0 },
            { "t": "comes to the artist,", "nl": 0 },
            { "t": "caught up", "nl": 0 },
            { "t": "and out of himself", "nl": 0 },
            { "t": "in a sheet of flame;", "nl": 0 },
            { "t": "it comes to the soldier,", "nl": 0 },
            { "t": "war-mad", "nl": 0 },
            { "t": "on a stricken field", "nl": 0 },
            { "t": "and refusing quarter;", "nl": 0 },
            { "t": "and it came to Buck,", "nl": 0 },
            { "t": "leading the pack,", "nl": 0 },
            { "t": "sounding the old wolf-cry,", "nl": 0 },
            { "t": "straining after the food", "nl": 0 },
            { "t": "that was alive", "nl": 0 },
            { "t": "and that fled", "nl": 0 },
            { "t": "swiftly before him", "nl": 0 },
            { "t": "through the moonlight.", "nl": 0 },
            { "t": "He was sounding", "nl": 0 },
            { "t": "the deeps of his nature,", "nl": 0 },
            { "t": "and of the parts", "nl": 0 },
            { "t": "of his nature", "nl": 0 },
            { "t": "that were deeper than he,", "nl": 0 },
            { "t": "going back", "nl": 0 },
            { "t": "into the womb of Time.", "nl": 0 },
            { "t": "He was mastered", "nl": 0 },
            { "t": "by the sheer surging of life,", "nl": 0 },
            { "t": "the tidal wave of being,", "nl": 0 },
            { "t": "the perfect joy", "nl": 0 },
            { "t": "of each separate", "nl": 0 },
            { "t": "muscle, joint, and sinew", "nl": 0 },
            { "t": "in that it was", "nl": 0 },
            { "t": "everything that was not death,", "nl": 0 },
            { "t": "that it was aglow", "nl": 0 },
            { "t": "and rampant,", "nl": 0 },
            { "t": "expressing itself", "nl": 0 },
            { "t": "in movement,", "nl": 0 },
            { "t": "flying exultantly", "nl": 0 },
            { "t": "under the stars", "nl": 0 },
            { "t": "and over the face", "nl": 0 },
            { "t": "of dead matter", "nl": 0 },
            { "t": "that did not move.", "nl": 0 },
        ],
    },
    {
        "id": 9,
        "author": "James Joyce",
        "author_img": "james_joyce.jpg",
        "title": "A Portrait of the Artist as a Young Man",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/4217",
        "lines": [
            { "t": "You have", "nl": 0 },
            { "t": "asked me", "nl": 0 },
            { "t": "what I would do", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "what I", "nl": 0 },
            { "t": "would not do.", "nl": 0 },
            { "t": "I will tell you", "nl": 0 },
            { "t": "what I will do", "nl": 0 },
            { "t": "and what", "nl": 0 },
            { "t": "I will not do.", "nl": 0 },
            { "t": "I will not", "nl": 0 },
            { "t": "serve that", "nl": 0 },
            { "t": "in which", "nl": 0 },
            { "t": "I no longer", "nl": 0 },
            { "t": "believe,", "nl": 0 },
            { "t": "whether", "nl": 0 },
            { "t": "it call itself", "nl": 0 },
            { "t": "my home,", "nl": 0 },
            { "t": "my fatherland,", "nl": 0 },
            { "t": "or my church:", "nl": 0 },
            { "t": "and I will try", "nl": 0 },
            { "t": "to express myself", "nl": 0 },
            { "t": "in some mode of life", "nl": 0 },
            { "t": "or art", "nl": 0 },
            { "t": "as freely", "nl": 0 },
            { "t": "as I can", "nl": 0 },
            { "t": "and as wholly", "nl": 0 },
            { "t": "as I can,", "nl": 0 },
            { "t": "using for my defence", "nl": 0 },
            { "t": "the only arms", "nl": 0 },
            { "t": "I allow myself", "nl": 0 },
            { "t": "to use—", "nl": 0 },
            { "t": "silence, exile", "nl": 0 },
            { "t": "and cunning.", "nl": 0 },
        ],
    },
    {
        "id": 10,
        "author": "Mary Wollstonecraft Shelley",
        "author_img": "mary_shelly.jpg",
        "title": "Frankenstein; Or, The Modern Prometheus",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/42324",
        "lines": [
            { "t": "Even broken", "nl": 0 },
            { "t": "in spirit", "nl": 0 },
            { "t": "as he is,", "nl": 0 },
            { "t": "no one", "nl": 0 },
            { "t": "can feel", "nl": 0 },
            { "t": "more deeply", "nl": 0 },
            { "t": "than he does", "nl": 0 },
            { "t": "the beauties of nature.", "nl": 0 },
            { "t": "The starry sky,", "nl": 0 },
            { "t": "the sea,", "nl": 0 },
            { "t": "and every sight", "nl": 0 },
            { "t": "afforded by these", "nl": 0 },
            { "t": "wonderful regions,", "nl": 0 },
            { "t": "seems still", "nl": 0 },
            { "t": "to have the power", "nl": 0 },
            { "t": "of elevating his soul", "nl": 0 },
            { "t": "from earth.", "nl": 0 },
            { "t": "Such a man", "nl": 0 },
            { "t": "has a double existence:", "nl": 0 },
            { "t": "he may suffer", "nl": 0 },
            { "t": "misery,", "nl": 0 },
            { "t": "and be overwhelmed", "nl": 0 },
            { "t": "by disappointments;", "nl": 0 },
            { "t": "yet,", "nl": 0 },
            { "t": "when he has retired", "nl": 0 },
            { "t": "into himself,", "nl": 0 },
            { "t": "he will be", "nl": 0 },
            { "t": "like a celestial spirit,", "nl": 0 },
            { "t": "that has a halo", "nl": 0 },
            { "t": "around him,", "nl": 0 },
            { "t": "within whose circle", "nl": 0 },
            { "t": "no grief", "nl": 0 },
            { "t": "or folly", "nl": 0 },
            { "t": "ventures.", "nl": 0 },
        ],
    },
    {
        "id": 11,
        "author": "Homer (Translated by Alexander Pope)",
        "author_img": "homer.jpg",
        "title": "The Odyssey",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/3160",
        "lines": [
            { "t": "The man", "nl": 0 },
            { "t": "for wisdom’s", "nl": 0 },
            { "t": "various arts", "nl": 0 },
            { "t": "renown’d,", "nl": 0 },
            { "t": "Long exercised", "nl": 0 },
            { "t": "in woes,", "nl": 0 },
            { "t": "O Muse!", "nl": 0 },
            { "t": "resound;", "nl": 0 },
            { "t": "Who,", "nl": 0 },
            { "t": "when his arms", "nl": 0 },
            { "t": "had wrought", "nl": 0 },
            { "t": "the destined fall", "nl": 0 },
            { "t": "Of sacred Troy,", "nl": 0 },
            { "t": "and razed", "nl": 0 },
            { "t": "her heaven-built wall,", "nl": 0 },
            { "t": "Wandering", "nl": 0 },
            { "t": "from clime to clime,", "nl": 0 },
            { "t": "observant stray’d,", "nl": 0 },
            { "t": "Their manners noted,", "nl": 0 },
            { "t": "and their states survey’d,", "nl": 0 },
            { "t": "On stormy seas", "nl": 0 },
            { "t": "unnumber’d toils", "nl": 0 },
            { "t": "he bore,", "nl": 0 },
            { "t": "Safe with his friends", "nl": 0 },
            { "t": "to gain", "nl": 0 },
            { "t": "his natal shore:", "nl": 0 },
            { "t": "Vain toils!", "nl": 0 },
            { "t": "Their impious folly", "nl": 0 },
            { "t": "dared to prey", "nl": 0 },
            { "t": "On herds", "nl": 0 },
            { "t": "devoted to the god of day;", "nl": 0 },
            { "t": "The god vindictive", "nl": 0 },
            { "t": "doom’d them never more", "nl": 0 },
            { "t": "(Ah, men unbless’d!)", "nl": 0 },
            { "t": "to touch", "nl": 0 },
            { "t": "that natal shore.", "nl": 0 },
            { "t": "Oh, snatch some portion", "nl": 0 },
            { "t": "of these acts", "nl": 0 },
            { "t": "from fate,", "nl": 0 },
            { "t": "Celestial Muse!", "nl": 0 },
            { "t": "And to our world", "nl": 0 },
            { "t": "relate.", "nl": 2 },
            { "t": "Now at", "nl": 0 },
            { "t": "their native realms", "nl": 0 },
            { "t": "the Greeks arrived;", "nl": 0 },
            { "t": "All", "nl": 0 },
            { "t": "who the wars", "nl": 0 },
            { "t": "of ten long years", "nl": 0 },
            { "t": "survived;", "nl": 0 },
            { "t": "And ’scaped the perils", "nl": 0 },
            { "t": "of the gulfy main.", "nl": 0 },
            { "t": "Ulysses,", "nl": 0 },
            { "t": "sole of", "nl": 0 },
            { "t": "all the victor train,", "nl": 0 },
            { "t": "An exile", "nl": 0 },
            { "t": "from his dear paternal coast,", "nl": 0 },
            { "t": "Deplored", "nl": 0 },
            { "t": "his absent queen", "nl": 0 },
            { "t": "and empire lost.", "nl": 0 },
            { "t": "Calypso", "nl": 0 },
            { "t": "in her caves", "nl": 0 },
            { "t": "constrain’d his stay,", "nl": 0 },
            { "t": "With sweet,", "nl": 0 },
            { "t": "reluctant,", "nl": 0 },
            { "t": "amorous delay;", "nl": 0 },
            { "t": "In vain-", "nl": 0 },
            { "t": "for now", "nl": 0 },
            { "t": "the circling years", "nl": 0 },
            { "t": "disclose", "nl": 0 },
            { "t": "The day", "nl": 0 },
            { "t": "predestined", "nl": 0 },
            { "t": "to reward", "nl": 0 },
            { "t": "his woes.", "nl": 0 },
            { "t": "At length", "nl": 0 },
            { "t": "his Ithaca", "nl": 0 },
            { "t": "is given by fate,", "nl": 0 },
            { "t": "Where yet", "nl": 0 },
            { "t": "new labours", "nl": 0 },
            { "t": "his arrival wait;", "nl": 0 },
            { "t": "At length", "nl": 0 },
            { "t": "their rage", "nl": 0 },
            { "t": "the hostile powers", "nl": 0 },
            { "t": "restrain,", "nl": 0 },
            { "t": "All but the ruthless", "nl": 0 },
            { "t": "monarch of the main.", "nl": 0 },
            { "t": "But now the god,", "nl": 0 },
            { "t": "remote,", "nl": 0 },
            { "t": "a heavenly guest,", "nl": 0 },
            { "t": "In Ethiopia", "nl": 0 },
            { "t": "graced the genial feast", "nl": 0 },
            { "t": "(A race divided,", "nl": 0 },
            { "t": "whom with sloping rays", "nl": 0 },
            { "t": "The rising", "nl": 0 },
            { "t": "and descending", "nl": 0 },
            { "t": "sun surveys);", "nl": 0 },
            { "t": "There on", "nl": 0 },
            { "t": "the world’s extremest verge", "nl": 0 },
            { "t": "revered", "nl": 0 },
            { "t": "With hecatombs", "nl": 0 },
            { "t": "and prayer", "nl": 0 },
            { "t": "in pomp preferr’d,", "nl": 0 },
            { "t": "Distant he lay:", "nl": 0 },
            { "t": "while in", "nl": 0 },
            { "t": "the bright abodes", "nl": 0 },
            { "t": "Of high Olympus,", "nl": 0 },
            { "t": "Jove", "nl": 0 },
            { "t": "convened the gods:", "nl": 0 },
            { "t": "The assembly", "nl": 0 },
            { "t": "thus the sire supreme", "nl": 0 },
            { "t": "address’d,", "nl": 0 },
            { "t": "AEgysthus’ fate revolving", "nl": 0 },
            { "t": "in his breast,", "nl": 0 },
            { "t": "Whom young Orestes", "nl": 0 },
            { "t": "to the dreary coast", "nl": 0 },
            { "t": "Of Pluto sent,", "nl": 0 },
            { "t": "a blood-polluted ghost.", "nl": 0 },
        ],
    },
    {
        "id": 12,
        "author": "Thomas Hardy",
        "author_img": "thomas_hardy.jpg",
        "title": "Tess of the d'Urbervilles",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/110",
        "lines": [
            { "t": "And probably", "nl": 0 },
            { "t": "the half-unconscious rhapsody", "nl": 0 },
            { "t": "was a", "nl": 0 },
            { "t": "fetishistic utterance", "nl": 0 },
            { "t": "in a monotheistic setting;", "nl": 0 },
            { "t": "women", "nl": 0 },
            { "t": "whose chief companions", "nl": 0 },
            { "t": "are the forms", "nl": 0 },
            { "t": "and forces", "nl": 0 },
            { "t": "of outdoor Nature", "nl": 0 },
            { "t": "retain in their souls", "nl": 0 },
            { "t": "far more", "nl": 0 },
            { "t": "of the Pagan fantasy", "nl": 0 },
            { "t": "of their remote forefathers", "nl": 0 },
            { "t": "than of", "nl": 0 },
            { "t": "the systematized religion", "nl": 0 },
            { "t": "taught their race", "nl": 0 },
            { "t": "at later date.", "nl": 0 },
            { "t": "However,", "nl": 0 },
            { "t": "Tess", "nl": 0 },
            { "t": "found at least", "nl": 0 },
            { "t": "approximate expression", "nl": 0 },
            { "t": "for her feelings", "nl": 0 },
            { "t": "in the old Benedicite", "nl": 0 },
            { "t": "that she", "nl": 0 },
            { "t": "had lisped", "nl": 0 },
            { "t": "from infancy;", "nl": 0 },
            { "t": "and it was enough.", "nl": 0 },
            { "t": "Such high contentment", "nl": 0 },
            { "t": "with such", "nl": 0 },
            { "t": "a slight initial performance", "nl": 0 },
            { "t": "as that of having started", "nl": 0 },
            { "t": "towards a means", "nl": 0 },
            { "t": "of independent living", "nl": 0 },
            { "t": "was a part of", "nl": 0 },
            { "t": "the Durbeyfield temperament.", "nl": 0 },
            { "t": "Tess", "nl": 0 },
            { "t": "really wished", "nl": 0 },
            { "t": "to walk uprightly,", "nl": 0 },
            { "t": "while her father", "nl": 0 },
            { "t": "did nothing of the kind;", "nl": 0 },
            { "t": "but she", "nl": 0 },
            { "t": "resembled him", "nl": 0 },
            { "t": "in being content", "nl": 0 },
            { "t": "with immediate", "nl": 0 },
            { "t": "and small achievements,", "nl": 0 },
            { "t": "and in having", "nl": 0 },
            { "t": "no mind", "nl": 0 },
            { "t": "for laborious effort", "nl": 0 },
            { "t": "towards such petty", "nl": 0 },
            { "t": "social advancement", "nl": 0 },
            { "t": "as could alone", "nl": 0 },
            { "t": "be effected", "nl": 0 },
            { "t": "by a family", "nl": 0 },
            { "t": "so heavily handicapped", "nl": 0 },
            { "t": "as the once powerful", "nl": 0 },
            { "t": "d’Urbervilles", "nl": 0 },
            { "t": "were now.", "nl": 2 },
            { "t": "There was,", "nl": 0 },
            { "t": "it might be said,", "nl": 0 },
            { "t": "the energy", "nl": 0 },
            { "t": "of her mother’s", "nl": 0 },
            { "t": "unexpended family,", "nl": 0 },
            { "t": "as well as", "nl": 0 },
            { "t": "the natural energy", "nl": 0 },
            { "t": "of Tess’s years,", "nl": 0 },
            { "t": "rekindled after", "nl": 0 },
            { "t": "the experience", "nl": 0 },
            { "t": "which had", "nl": 0 },
            { "t": "so overwhelmed", "nl": 0 },
            { "t": "her", "nl": 0 },
            { "t": "for the time.", "nl": 0 },
            { "t": "Let the truth be told—", "nl": 0 },
            { "t": "women do", "nl": 0 },
            { "t": "as a rule", "nl": 0 },
            { "t": "live through", "nl": 0 },
            { "t": "such humiliations,", "nl": 0 },
            { "t": "and regain", "nl": 0 },
            { "t": "their spirits,", "nl": 0 },
            { "t": "and again", "nl": 0 },
            { "t": "look about them", "nl": 0 },
            { "t": "with an interested eye.", "nl": 0 },
            { "t": "While there’s life", "nl": 0 },
            { "t": "there’s hope", "nl": 0 },
            { "t": "is a conviction", "nl": 0 },
            { "t": "not so entirely unknown", "nl": 0 },
            { "t": "to the “betrayed”", "nl": 0 },
            { "t": "as some amiable theorists", "nl": 0 },
            { "t": "would have us believe.", "nl": 0 },
        ],
    },
    {
        "id": 13,
        "author": "Beatrix Potter",
        "author_img": "beatrix_potter.jpg",
        "title": "The Tale of Peter Rabbit",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/14838",
        "lines": [
            { "t": "He was", "nl": 0 },
            { "t": "so tired", "nl": 0 },
            { "t": "that he", "nl": 0 },
            { "t": "flopped down", "nl": 0 },
            { "t": "upon the nice soft sand", "nl": 0 },
            { "t": "on the floor", "nl": 0 },
            { "t": "of the rabbit-hole", "nl": 0 },
            { "t": "and shut his eyes.", "nl": 0 },
            { "t": "His mother", "nl": 0 },
            { "t": "was busy cooking;", "nl": 0 },
            { "t": "she wondered", "nl": 0 },
            { "t": "what he had done", "nl": 0 },
            { "t": "with his clothes.", "nl": 0 },
            { "t": "It was", "nl": 0 },
            { "t": "the second", "nl": 0 },
            { "t": "little jacket", "nl": 0 },
            { "t": "and pair of shoes", "nl": 0 },
            { "t": "that Peter", "nl": 0 },
            { "t": "had lost", "nl": 0 },
            { "t": "in a fortnight!", "nl": 2 },
            { "t": "I am sorry to say", "nl": 0 },
            { "t": "that Peter", "nl": 0 },
            { "t": "was not very well", "nl": 0 },
            { "t": "during the evening.", "nl": 2 },
            { "t": "His mother", "nl": 0 },
            { "t": "put him to bed,", "nl": 0 },
            { "t": "and made", "nl": 0 },
            { "t": "some camomile tea;", "nl": 0 },
            { "t": "and she gave", "nl": 0 },
            { "t": "a dose of it", "nl": 0 },
            { "t": "to Peter!", "nl": 2 },
            { "t": "'One table-spoonful", "nl": 0 },
            { "t": "to be taken", "nl": 0 },
            { "t": "at bed-time.'", "nl": 2 },
            { "t": "But", "nl": 0 },
            { "t": "Flopsy, Mopsy, and Cotton-tail", "nl": 0 },
            { "t": "had bread", "nl": 0 },
            { "t": "and milk", "nl": 0 },
            { "t": "and blackberries", "nl": 0 },
            { "t": "for supper.", "nl": 0 },
        ],
    },
    {
        "id": 14,
        "author": "Edith Wharton",
        "author_img": "edith_wharton.jpg",
        "title": "The Age of Innocence",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/541",
        "lines": [
            { "t": "Don't be", "nl": 0 },
            { "t": "afraid of me:", "nl": 0 },
            { "t": "you needn't", "nl": 0 },
            { "t": "squeeze yourself", "nl": 0 },
            { "t": "back into your corner", "nl": 0 },
            { "t": "like that.", "nl": 0 },
            { "t": "A stolen kiss", "nl": 0 },
            { "t": "isn't what I want.", "nl": 0 },
            { "t": "Look:", "nl": 0 },
            { "t": "I'm not even trying", "nl": 0 },
            { "t": "to touch the sleeve", "nl": 0 },
            { "t": "of your jacket.", "nl": 0 },
            { "t": "Don't suppose", "nl": 0 },
            { "t": "that I don't understand", "nl": 0 },
            { "t": "your reasons", "nl": 0 },
            { "t": "for not wanting", "nl": 0 },
            { "t": "to let", "nl": 0 },
            { "t": "this feeling between us", "nl": 0 },
            { "t": "dwindle into", "nl": 0 },
            { "t": "an ordinary", "nl": 0 },
            { "t": "hole-and-corner", "nl": 0 },
            { "t": "love-affair.", "nl": 0 },
            { "t": "I couldn't", "nl": 0 },
            { "t": "have spoken like this", "nl": 0 },
            { "t": "yesterday,", "nl": 0 },
            { "t": "because", "nl": 0 },
            { "t": "when we've been apart,", "nl": 0 },
            { "t": "and I'm looking forward", "nl": 0 },
            { "t": "to seeing you,", "nl": 0 },
            { "t": "every thought", "nl": 0 },
            { "t": "is burnt up", "nl": 0 },
            { "t": "in a great flame.", "nl": 0 },
            { "t": "But then you come;", "nl": 0 },
            { "t": "and you're", "nl": 0 },
            { "t": "so much more", "nl": 0 },
            { "t": "than I remembered,", "nl": 0 },
            { "t": "and what I want of you", "nl": 0 },
            { "t": "is so much more", "nl": 0 },
            { "t": "than", "nl": 0 },
            { "t": "an hour or two", "nl": 0 },
            { "t": "every now and then,", "nl": 0 },
            { "t": "with wastes", "nl": 0 },
            { "t": "of thirsty waiting", "nl": 0 },
            { "t": "between,", "nl": 0 },
            { "t": "that", "nl": 0 },
            { "t": "I can sit", "nl": 0 },
            { "t": "perfectly still", "nl": 0 },
            { "t": "beside you, like this,", "nl": 0 },
            { "t": "with that other vision", "nl": 0 },
            { "t": "in my mind,", "nl": 0 },
            { "t": "just", "nl": 0 },
            { "t": "quietly trusting", "nl": 0 },
            { "t": "to it", "nl": 0 },
            { "t": "to come true.", "nl": 0 },
        ],
    },
    {
        "id": 15,
        "author": "Agatha Christie",
        "author_img": "agatha_christie.jpg",
        "title": "The Mysterious Affair at Styles",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/863",
        "lines": [
            { "t": "The intense interest", "nl": 0 },
            { "t": "aroused in the public", "nl": 0 },
            { "t": "by what was known", "nl": 0 },
            { "t": "at the time", "nl": 0 },
            { "t": "as “The Styles Case”", "nl": 0 },
            { "t": "has now", "nl": 0 },
            { "t": "somewhat subsided.", "nl": 0 },
            { "t": "Nevertheless,", "nl": 0 },
            { "t": "in view of", "nl": 0 },
            { "t": "the world-wide notoriety", "nl": 0 },
            { "t": "which attended it,", "nl": 0 },
            { "t": "I have been asked,", "nl": 0 },
            { "t": "both by my friend", "nl": 0 },
            { "t": "Poirot", "nl": 0 },
            { "t": "and the family themselves,", "nl": 0 },
            { "t": "to write", "nl": 0 },
            { "t": "an account", "nl": 0 },
            { "t": "of the whole story.", "nl": 0 },
            { "t": "This,", "nl": 0 },
            { "t": "we trust,", "nl": 0 },
            { "t": "will effectually", "nl": 0 },
            { "t": "silence", "nl": 0 },
            { "t": "the sensational rumours", "nl": 0 },
            { "t": "which still persist.", "nl": 2 },
            { "t": "I will therefore", "nl": 0 },
            { "t": "briefly set down", "nl": 0 },
            { "t": "the circumstances", "nl": 0 },
            { "t": "which led to my being", "nl": 0 },
            { "t": "connected with the affair.", "nl": 2 },
            { "t": "I had been invalided home", "nl": 0 },
            { "t": "from the Front;", "nl": 0 },
            { "t": "and, after spending", "nl": 0 },
            { "t": "some months", "nl": 0 },
            { "t": "in a rather depressing", "nl": 0 },
            { "t": "Convalescent Home,", "nl": 0 },
            { "t": "was given", "nl": 0 },
            { "t": "a month’s sick leave.", "nl": 0 },
            { "t": "Having no", "nl": 0 },
            { "t": "near relations", "nl": 0 },
            { "t": "or friends,", "nl": 0 },
            { "t": "I was trying", "nl": 0 },
            { "t": "to make up my mind", "nl": 0 },
            { "t": "what to do,", "nl": 0 },
            { "t": "when I ran across", "nl": 0 },
            { "t": "John Cavendish.", "nl": 0 },
            { "t": "I had seen", "nl": 0 },
            { "t": "very little", "nl": 0 },
            { "t": "of him", "nl": 0 },
            { "t": "for some years.", "nl": 0 },
            { "t": "Indeed,", "nl": 0 },
            { "t": "I had never", "nl": 0 },
            { "t": "known him", "nl": 0 },
            { "t": "particularly well.", "nl": 0 },
            { "t": "He was a good", "nl": 0 },
            { "t": "fifteen years my senior,", "nl": 0 },
            { "t": "for one thing,", "nl": 0 },
            { "t": "though he", "nl": 0 },
            { "t": "hardly looked", "nl": 0 },
            { "t": "his forty-five years.", "nl": 0 },
            { "t": "As a boy,", "nl": 0 },
            { "t": "though,", "nl": 0 },
            { "t": "I had often", "nl": 0 },
            { "t": "stayed at Styles,", "nl": 0 },
            { "t": "his mother’s place", "nl": 0 },
            { "t": "in Essex.", "nl": 0 },
        ],
    },
    {
        "id": 16,
        "author": "Edith Nesbit",
        "author_img": "edith_nesbit.jpg",
        "title": "The Railway Children",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/1874",
        "lines": [
            { "t": "“Well then,", "nl": 0 },
            { "t": "you see.", "nl": 0 },
            { "t": "Boys and girls", "nl": 0 },
            { "t": "are only", "nl": 0 },
            { "t": "little men", "nl": 0 },
            { "t": "and women.", "nl": 0 },
            { "t": "And WE are", "nl": 0 },
            { "t": "much harder", "nl": 0 },
            { "t": "and hardier", "nl": 0 },
            { "t": "than they are—”", "nl": 0 },
            { "t": "(Peter liked", "nl": 0 },
            { "t": "the “we.”", "nl": 0 },
            { "t": "Perhaps", "nl": 0 },
            { "t": "the Doctor", "nl": 0 },
            { "t": "had known he would.)—", "nl": 0 },
            { "t": "“and", "nl": 0 },
            { "t": "much stronger,", "nl": 0 },
            { "t": "and things", "nl": 0 },
            { "t": "that hurt THEM", "nl": 0 },
            { "t": "don't hurt US.", "nl": 0 },
            { "t": "You know", "nl": 0 },
            { "t": "you mustn't", "nl": 0 },
            { "t": "hit a girl—”", "nl": 2 },
            { "t": "“I should", "nl": 0 },
            { "t": "think not,", "nl": 0 },
            { "t": "indeed,”", "nl": 0 },
            { "t": "muttered Peter,", "nl": 0 },
            { "t": "indignantly.", "nl": 0 },
        ],
    },
    {
        "id": 17,
        "author": "Daniel DeFoe",
        "author_img": "daniel_defoe.jpg",
        "title": "The Life and Adventures of Robinson Crusoe",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/521",
        "lines": [
            { "t": "I had now", "nl": 0 },
            { "t": "brought", "nl": 0 },
            { "t": "my state of life", "nl": 0 },
            { "t": "to be much easier", "nl": 0 },
            { "t": "in itself", "nl": 0 },
            { "t": "than it was", "nl": 0 },
            { "t": "at first,", "nl": 0 },
            { "t": "and much easier", "nl": 0 },
            { "t": "to my mind,", "nl": 0 },
            { "t": "as well as", "nl": 0 },
            { "t": "to my body.", "nl": 0 },
            { "t": "I frequently", "nl": 0 },
            { "t": "sat down", "nl": 0 },
            { "t": "to meat", "nl": 0 },
            { "t": "with thankfulness,", "nl": 0 },
            { "t": "and admired", "nl": 0 },
            { "t": "the hand of", "nl": 0 },
            { "t": "God’s providence,", "nl": 0 },
            { "t": "which had", "nl": 0 },
            { "t": "thus spread", "nl": 0 },
            { "t": "my table", "nl": 0 },
            { "t": "in the wilderness.", "nl": 0 },
            { "t": "I learned", "nl": 0 },
            { "t": "to look", "nl": 0 },
            { "t": "more upon", "nl": 0 },
            { "t": "the bright side", "nl": 0 },
            { "t": "of my condition,", "nl": 0 },
            { "t": "and less upon", "nl": 0 },
            { "t": "the dark side,", "nl": 0 },
            { "t": "and to consider", "nl": 0 },
            { "t": "what I enjoyed", "nl": 0 },
            { "t": "rather than", "nl": 0 },
            { "t": "what I wanted;", "nl": 0 },
            { "t": "and this", "nl": 0 },
            { "t": "gave me", "nl": 0 },
            { "t": "sometimes such secret comforts,", "nl": 0 },
            { "t": "that I cannot", "nl": 0 },
            { "t": "express them;", "nl": 0 },
            { "t": "and which", "nl": 0 },
            { "t": "I take notice", "nl": 0 },
            { "t": "of here,", "nl": 0 },
            { "t": "to put", "nl": 0 },
            { "t": "those discontented people", "nl": 0 },
            { "t": "in mind of it,", "nl": 0 },
            { "t": "who cannot", "nl": 0 },
            { "t": "enjoy comfortably", "nl": 0 },
            { "t": "what God", "nl": 0 },
            { "t": "has given them,", "nl": 0 },
            { "t": "because", "nl": 0 },
            { "t": "they see", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "covet something", "nl": 0 },
            { "t": "that He", "nl": 0 },
            { "t": "has not given them.", "nl": 0 },
            { "t": "All our", "nl": 0 },
            { "t": "discontents", "nl": 0 },
            { "t": "about what", "nl": 0 },
            { "t": "we want", "nl": 0 },
            { "t": "appeared to me", "nl": 0 },
            { "t": "to spring", "nl": 0 },
            { "t": "from the want of", "nl": 0 },
            { "t": "thankfulness", "nl": 0 },
            { "t": "for what we have.", "nl": 0 },
        ],
    },
    {
        "id": 18,
        "author": "Charlotte Bronte",
        "author_img": "charlotte_bronte.jpg",
        "title": "Jane Eyre",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/1260",
        "lines": [
            { "t": "Do you think", "nl": 0 },
            { "t": "I can stay", "nl": 0 },
            { "t": "to become", "nl": 0 },
            { "t": "nothing to you?", "nl": 0 },
            { "t": "Do you think", "nl": 0 },
            { "t": "I am", "nl": 0 },
            { "t": "an automaton?—", "nl": 0 },
            { "t": "a machine", "nl": 0 },
            { "t": "without feelings?", "nl": 0 },
            { "t": "and can", "nl": 0 },
            { "t": "bear", "nl": 0 },
            { "t": "to have", "nl": 0 },
            { "t": "my morsel of bread", "nl": 0 },
            { "t": "snatched", "nl": 0 },
            { "t": "from my lips,", "nl": 0 },
            { "t": "and my", "nl": 0 },
            { "t": "drop of living water", "nl": 0 },
            { "t": "dashed", "nl": 0 },
            { "t": "from my cup?", "nl": 0 },
            { "t": "Do you think,", "nl": 0 },
            { "t": "because", "nl": 0 },
            { "t": "I am poor,", "nl": 0 },
            { "t": "obscure,", "nl": 0 },
            { "t": "plain,", "nl": 0 },
            { "t": "and little,", "nl": 0 },
            { "t": "I am soulless", "nl": 0 },
            { "t": "and heartless?", "nl": 0 },
            { "t": "You think wrong!—", "nl": 0 },
            { "t": "I have", "nl": 0 },
            { "t": "as much soul", "nl": 0 },
            { "t": "as you,—", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "full as much", "nl": 0 },
            { "t": "heart!", "nl": 0 },
            { "t": "And", "nl": 0 },
            { "t": "if God", "nl": 0 },
            { "t": "had gifted", "nl": 0 },
            { "t": "me", "nl": 0 },
            { "t": "with some beauty", "nl": 0 },
            { "t": "and much wealth,", "nl": 0 },
            { "t": "I should have", "nl": 0 },
            { "t": "made it", "nl": 0 },
            { "t": "as hard", "nl": 0 },
            { "t": "for you", "nl": 0 },
            { "t": "to leave me,", "nl": 0 },
            { "t": "as it is now", "nl": 0 },
            { "t": "for me", "nl": 0 },
            { "t": "to leave you.", "nl": 0 },
            { "t": "I am not", "nl": 0 },
            { "t": "talking", "nl": 0 },
            { "t": "to you now", "nl": 0 },
            { "t": "through the medium", "nl": 0 },
            { "t": "of custom,", "nl": 0 },
            { "t": "conventionalities,", "nl": 0 },
            { "t": "nor even", "nl": 0 },
            { "t": "of mortal flesh;—", "nl": 0 },
            { "t": "it is", "nl": 0 },
            { "t": "my spirit", "nl": 0 },
            { "t": "that addresses", "nl": 0 },
            { "t": "your spirit;", "nl": 0 },
            { "t": "just as if", "nl": 0 },
            { "t": "both had", "nl": 0 },
            { "t": "passed through", "nl": 0 },
            { "t": "the grave,", "nl": 0 },
            { "t": "and we stood", "nl": 0 },
            { "t": "at God’s feet,", "nl": 0 },
            { "t": "equal,—", "nl": 0 },
            { "t": "as we are!", "nl": 0 },
        ],
    },
    {
        "id": 19,
        "author": "Rudyard Kipling",
        "author_img": "rudyard_kipling.jpg",
        "title": "The Jungle Book",
        "section_title": "Rikki-Tikki-Tavi",
        "link": "https://www.gutenberg.org/ebooks/236",
        "lines": [
            { "t": "At the hole", "nl": 0 },
            { "t": "where he", "nl": 0 },
            { "t": "went in", "nl": 1 },
            { "t": "Red-Eye", "nl": 0 },
            { "t": "called to", "nl": 0 },
            { "t": "Wrinkle-Skin.", "nl": 1 },
            { "t": "Hear what", "nl": 0 },
            { "t": "little Red-Eye", "nl": 0 },
            { "t": "saith:", "nl": 1 },
            { "t": "“Nag,", "nl": 0 },
            { "t": "come up", "nl": 0 },
            { "t": "and dance", "nl": 0 },
            { "t": "with death!”", "nl": 2 },
            { "t": "Eye to eye", "nl": 0 },
            { "t": "and head to head,", "nl": 1 },
            { "t": "(Keep", "nl": 0 },
            { "t": "the measure,", "nl": 0 },
            { "t": "Nag.)", "nl": 1 },
            { "t": "This shall end", "nl": 0 },
            { "t": "when one", "nl": 0 },
            { "t": "is dead;", "nl": 1 },
            { "t": "(At thy pleasure,", "nl": 0 },
            { "t": "Nag.)", "nl": 1 },
            { "t": "Turn for turn", "nl": 0 },
            { "t": "and twist for twist—", "nl": 0 },
            { "t": "(Run and hide", "nl": 0 },
            { "t": "thee, Nag.)", "nl": 1 },
            { "t": "Hah!", "nl": 0 },
            { "t": "The hooded Death", "nl": 0 },
            { "t": "has missed!", "nl": 1 },
            { "t": "(Woe", "nl": 0 },
            { "t": "betide thee,", "nl": 0 },
            { "t": "Nag!)", "nl": 2 },
        ],
    },
    {
        "id": 20,
        "author": "Thomas Paine",
        "author_img": "thomas_paine.jpg",
        "title": "Common Sense",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/??",
        "lines": [
            { "t": "Mankind", "nl": 0 },
            { "t": "being originally", "nl": 0 },
            { "t": "equals", "nl": 0 },
            { "t": "in the order", "nl": 0 },
            { "t": "of creation,", "nl": 0 },
            { "t": "the equality", "nl": 0 },
            { "t": "could only be", "nl": 0 },
            { "t": "destroyed", "nl": 0 },
            { "t": "by some", "nl": 0 },
            { "t": "subsequent circumstance;", "nl": 0 },
            { "t": "the distinctions", "nl": 0 },
            { "t": "of rich,", "nl": 0 },
            { "t": "and poor,", "nl": 0 },
            { "t": "may in", "nl": 0 },
            { "t": "a great measure", "nl": 0 },
            { "t": "be accounted for,", "nl": 0 },
            { "t": "and that", "nl": 0 },
            { "t": "without having recourse", "nl": 0 },
            { "t": "to the", "nl": 0 },
            { "t": "harsh ill", "nl": 0 },
            { "t": "sounding names", "nl": 0 },
            { "t": "of oppression", "nl": 0 },
            { "t": "and avarice.", "nl": 0 },
            { "t": "Oppression", "nl": 0 },
            { "t": "is often", "nl": 0 },
            { "t": "the consequence,", "nl": 0 },
            { "t": "but seldom", "nl": 0 },
            { "t": "or never", "nl": 0 },
            { "t": "the means of riches;", "nl": 0 },
            { "t": "and though avarice", "nl": 0 },
            { "t": "will preserve", "nl": 0 },
            { "t": "a man", "nl": 0 },
            { "t": "from being", "nl": 0 },
            { "t": "necessitously poor,", "nl": 0 },
            { "t": "it generally", "nl": 0 },
            { "t": "makes him", "nl": 0 },
            { "t": "too timorous", "nl": 0 },
            { "t": "to be wealthy.", "nl": 2 },
            { "t": "But there is", "nl": 0 },
            { "t": "another and", "nl": 0 },
            { "t": "greater distinction", "nl": 0 },
            { "t": "for which", "nl": 0 },
            { "t": "no truly natural", "nl": 0 },
            { "t": "or religious", "nl": 0 },
            { "t": "reason", "nl": 0 },
            { "t": "can be assigned,", "nl": 0 },
            { "t": "and that is,", "nl": 0 },
            { "t": "the distinction", "nl": 0 },
            { "t": "of men", "nl": 0 },
            { "t": "into kings", "nl": 0 },
            { "t": "and subjects.", "nl": 0 },
            { "t": "Male and female", "nl": 0 },
            { "t": "are the", "nl": 0 },
            { "t": "distinctions of nature,", "nl": 0 },
            { "t": "good and bad", "nl": 0 },
            { "t": "the distinctions", "nl": 0 },
            { "t": "of heaven;", "nl": 0 },
            { "t": "but how", "nl": 0 },
            { "t": "a race of men", "nl": 0 },
            { "t": "came", "nl": 0 },
            { "t": "into the world", "nl": 0 },
            { "t": "so exalted", "nl": 0 },
            { "t": "above the rest,", "nl": 0 },
            { "t": "and distinguished", "nl": 0 },
            { "t": "like some new species,", "nl": 0 },
            { "t": "is worth", "nl": 0 },
            { "t": "enquiring into,", "nl": 0 },
            { "t": "and whether", "nl": 0 },
            { "t": "they are", "nl": 0 },
            { "t": "the means of happiness", "nl": 0 },
            { "t": "or of misery", "nl": 0 },
            { "t": "to mankind.", "nl": 0 },
        ],
    },
    {
        "id": 21,
        "author": "Bram Stoker",
        "author_img": "bram_stoker.jpg",
        "title": "Dracula",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/345",
        "lines": [
            { "t": "I was afraid", "nl": 0 },
            { "t": "to raise", "nl": 0 },
            { "t": "my eyelids,", "nl": 0 },
            { "t": "but looked out", "nl": 0 },
            { "t": "and saw perfectly", "nl": 0 },
            { "t": "under the lashes.", "nl": 0 },
            { "t": "The girl", "nl": 0 },
            { "t": "went on", "nl": 0 },
            { "t": "her knees,", "nl": 0 },
            { "t": "and bent over me,", "nl": 0 },
            { "t": "simply gloating.", "nl": 0 },
            { "t": "There was", "nl": 0 },
            { "t": "a deliberate", "nl": 0 },
            { "t": "voluptuousness", "nl": 0 },
            { "t": "which was both", "nl": 0 },
            { "t": "thrilling", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "repulsive,", "nl": 0 },
            { "t": "and as she", "nl": 0 },
            { "t": "arched her neck", "nl": 0 },
            { "t": "she actually", "nl": 0 },
            { "t": "licked her lips", "nl": 0 },
            { "t": "like an animal,", "nl": 0 },
            { "t": "till I could see", "nl": 0 },
            { "t": "in the moonlight", "nl": 0 },
            { "t": "the moisture shining", "nl": 0 },
            { "t": "on the", "nl": 0 },
            { "t": "scarlet lips", "nl": 0 },
            { "t": "and on", "nl": 0 },
            { "t": "the red tongue", "nl": 0 },
            { "t": "as it lapped", "nl": 0 },
            { "t": "the white", "nl": 0 },
            { "t": "sharp teeth.", "nl": 0 },
            { "t": "Lower and lower", "nl": 0 },
            { "t": "went", "nl": 0 },
            { "t": "her head", "nl": 0 },
            { "t": "as the lips", "nl": 0 },
            { "t": "went below", "nl": 0 },
            { "t": "the range of", "nl": 0 },
            { "t": "my mouth", "nl": 0 },
            { "t": "and chin", "nl": 0 },
            { "t": "and seemed", "nl": 0 },
            { "t": "about to", "nl": 0 },
            { "t": "fasten on", "nl": 0 },
            { "t": "my throat.", "nl": 0 },
            { "t": "Then she paused,", "nl": 0 },
            { "t": "and I", "nl": 0 },
            { "t": "could hear", "nl": 0 },
            { "t": "the churning sound", "nl": 0 },
            { "t": "of her tongue", "nl": 0 },
            { "t": "as it licked", "nl": 0 },
            { "t": "her teeth", "nl": 0 },
            { "t": "and lips,", "nl": 0 },
            { "t": "and could feel", "nl": 0 },
            { "t": "the hot breath", "nl": 0 },
            { "t": "on my neck.", "nl": 0 },
            { "t": "Then the skin", "nl": 0 },
            { "t": "of my throat", "nl": 0 },
            { "t": "began to tingle", "nl": 0 },
            { "t": "as one’s", "nl": 0 },
            { "t": "flesh", "nl": 0 },
            { "t": "does", "nl": 0 },
            { "t": "when the hand", "nl": 0 },
            { "t": "that is", "nl": 0 },
            { "t": "to tickle it", "nl": 0 },
            { "t": "approaches", "nl": 0 },
            { "t": "nearer—nearer.", "nl": 0 },
            { "t": "I could feel", "nl": 0 },
            { "t": "the soft,", "nl": 0 },
            { "t": "shivering touch", "nl": 0 },
            { "t": "of the lips", "nl": 0 },
            { "t": "on the", "nl": 0 },
            { "t": "super-sensitive", "nl": 0 },
            { "t": "skin", "nl": 0 },
            { "t": "of my throat,", "nl": 0 },
            { "t": "and the", "nl": 0 },
            { "t": "hard dents", "nl": 0 },
            { "t": "of two sharp teeth,", "nl": 0 },
            { "t": "just touching", "nl": 0 },
            { "t": "and pausing there.", "nl": 0 },
            { "t": "I closed my eyes", "nl": 0 },
            { "t": "in a", "nl": 0 },
            { "t": "languorous ecstasy", "nl": 0 },
            { "t": "and waited—waited", "nl": 0 },
            { "t": "with beating heart.", "nl": 0 },
        ],
    },
    {
        "id": 22,
        "author": "Robert Louis Stevenson",
        "author_img": "robert_louis_stevenson.jpg",
        "title": "Treasure Island",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/120",
        "lines": [
            { "t": "His stories", "nl": 0 },
            { "t": "were what", "nl": 0 },
            { "t": "frightened people", "nl": 0 },
            { "t": "worst of all.", "nl": 0 },
            { "t": "Dreadful stories", "nl": 0 },
            { "t": "they were—", "nl": 0 },
            { "t": "about", "nl": 0 },
            { "t": "hanging,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "walking the plank,", "nl": 0 },
            { "t": "and storms at sea,", "nl": 0 },
            { "t": "and the Dry Tortugas,", "nl": 0 },
            { "t": "and wild deeds", "nl": 0 },
            { "t": "and places", "nl": 0 },
            { "t": "on the Spanish Main.", "nl": 0 },
            { "t": "By his own account", "nl": 0 },
            { "t": "he must have", "nl": 0 },
            { "t": "lived his life", "nl": 0 },
            { "t": "among some of", "nl": 0 },
            { "t": "the wickedest men", "nl": 0 },
            { "t": "that God", "nl": 0 },
            { "t": "ever allowed", "nl": 0 },
            { "t": "upon the sea,", "nl": 0 },
            { "t": "and the language", "nl": 0 },
            { "t": "in which", "nl": 0 },
            { "t": "he told these stories", "nl": 0 },
            { "t": "shocked", "nl": 0 },
            { "t": "our plain country people", "nl": 0 },
            { "t": "almost as much", "nl": 0 },
            { "t": "as the crimes", "nl": 0 },
            { "t": "that he described.", "nl": 0 },
            { "t": "My father", "nl": 0 },
            { "t": "was always", "nl": 0 },
            { "t": "saying", "nl": 0 },
            { "t": "the inn", "nl": 0 },
            { "t": "would be ruined,", "nl": 0 },
            { "t": "for people", "nl": 0 },
            { "t": "would soon", "nl": 0 },
            { "t": "cease coming there", "nl": 0 },
            { "t": "to be tyrannized over", "nl": 0 },
            { "t": "and put down,", "nl": 0 },
            { "t": "and sent", "nl": 0 },
            { "t": "shivering", "nl": 0 },
            { "t": "to their beds;", "nl": 0 },
            { "t": "but I really believe", "nl": 0 },
            { "t": "his presence", "nl": 0 },
            { "t": "did us good.", "nl": 0 },
            { "t": "People were", "nl": 0 },
            { "t": "frightened", "nl": 0 },
            { "t": "at the time,", "nl": 0 },
            { "t": "but on", "nl": 0 },
            { "t": "looking back", "nl": 0 },
            { "t": "they rather liked it;", "nl": 0 },
            { "t": "it was a", "nl": 0 },
            { "t": "fine excitement", "nl": 0 },
            { "t": "in a quiet", "nl": 0 },
            { "t": "country life,", "nl": 0 },
            { "t": "and there was", "nl": 0 },
            { "t": "even a", "nl": 0 },
            { "t": "party of the younger men", "nl": 0 },
            { "t": "who pretended", "nl": 0 },
            { "t": "to admire him,", "nl": 0 },
            { "t": "calling him", "nl": 0 },
            { "t": "a “true sea-dog”", "nl": 0 },
            { "t": "and a “real old salt”", "nl": 0 },
            { "t": "and such like names,", "nl": 0 },
            { "t": "and saying", "nl": 0 },
            { "t": "there was", "nl": 0 },
            { "t": "the sort of man", "nl": 0 },
            { "t": "that made England", "nl": 0 },
            { "t": "terrible at sea.", "nl": 2 },
            { "t": "In one way,", "nl": 0 },
            { "t": "indeed,", "nl": 0 },
            { "t": "he bade fair", "nl": 0 },
            { "t": "to ruin us,", "nl": 0 },
            { "t": "for he kept on", "nl": 0 },
            { "t": "staying", "nl": 0 },
            { "t": "week after week,", "nl": 0 },
            { "t": "and at last", "nl": 0 },
            { "t": "month after month,", "nl": 0 },
            { "t": "so that", "nl": 0 },
            { "t": "all the money", "nl": 0 },
            { "t": "had been", "nl": 0 },
            { "t": "long exhausted,", "nl": 0 },
            { "t": "and still", "nl": 0 },
            { "t": "my father", "nl": 0 },
            { "t": "never plucked up", "nl": 0 },
            { "t": "the heart", "nl": 0 },
            { "t": "to insist", "nl": 0 },
            { "t": "on having more.", "nl": 0 },
            { "t": "If ever", "nl": 0 },
            { "t": "he mentioned it,", "nl": 0 },
            { "t": "the captain", "nl": 0 },
            { "t": "blew through his nose", "nl": 0 },
            { "t": "so loudly", "nl": 0 },
            { "t": "that you might say", "nl": 0 },
            { "t": "he roared,", "nl": 0 },
            { "t": "and stared", "nl": 0 },
            { "t": "my poor father", "nl": 0 },
            { "t": "out of the room.", "nl": 0 },
            { "t": "I have seen him", "nl": 0 },
            { "t": "wringing his hands", "nl": 0 },
            { "t": "after such a rebuff,", "nl": 0 },
            { "t": "and I am sure", "nl": 0 },
            { "t": "the annoyance", "nl": 0 },
            { "t": "and the terror", "nl": 0 },
            { "t": "he lived in", "nl": 0 },
            { "t": "must have", "nl": 0 },
            { "t": "greatly hastened", "nl": 0 },
            { "t": "his early", "nl": 0 },
            { "t": "and unhappy", "nl": 0 },
            { "t": "death.", "nl": 0 },
        ],
    },
    {
        "id": 23,
        "author": "Herman Melville",
        "author_img": "herman_melville.jpg",
        "title": "Moby Dick",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/2701",
        "lines": [
            { "t": "But", "nl": 0 },
            { "t": "not only", "nl": 0 },
            { "t": "is the sea", "nl": 0 },
            { "t": "such a foe", "nl": 0 },
            { "t": "to man", "nl": 0 },
            { "t": "who is", "nl": 0 },
            { "t": "an alien to it,", "nl": 0 },
            { "t": "but it is also", "nl": 0 },
            { "t": "a fiend to", "nl": 0 },
            { "t": "its own off-spring;", "nl": 0 },
            { "t": "worse than", "nl": 0 },
            { "t": "the Persian", "nl": 0 },
            { "t": "host", "nl": 0 },
            { "t": "who murdered", "nl": 0 },
            { "t": "his own guests;", "nl": 0 },
            { "t": "sparing not", "nl": 0 },
            { "t": "the creatures", "nl": 0 },
            { "t": "which itself hath spawned.", "nl": 0 },
            { "t": "Like a savage", "nl": 0 },
            { "t": "tigress", "nl": 0 },
            { "t": "that tossing", "nl": 0 },
            { "t": "in the jungle", "nl": 0 },
            { "t": "overlays", "nl": 0 },
            { "t": "her own cubs,", "nl": 0 },
            { "t": "so the sea", "nl": 0 },
            { "t": "dashes", "nl": 0 },
            { "t": "even the", "nl": 0 },
            { "t": "mightiest whales", "nl": 0 },
            { "t": "against the rocks,", "nl": 0 },
            { "t": "and leaves them there", "nl": 0 },
            { "t": "side by side", "nl": 0 },
            { "t": "with the", "nl": 0 },
            { "t": "split wrecks", "nl": 0 },
            { "t": "of ships.", "nl": 0 },
            { "t": "No mercy,", "nl": 0 },
            { "t": "no power but its own", "nl": 0 },
            { "t": "controls it.", "nl": 0 },
            { "t": "Panting and snorting", "nl": 0 },
            { "t": "like a mad", "nl": 0 },
            { "t": "battle steed", "nl": 0 },
            { "t": "that has lost its rider,", "nl": 0 },
            { "t": "the masterless ocean", "nl": 0 },
            { "t": "overruns the globe.", "nl": 2 },
            { "t": "Consider the subtleness", "nl": 0 },
            { "t": "of the sea;", "nl": 0 },
            { "t": "how its", "nl": 0 },
            { "t": "most dreaded", "nl": 0 },
            { "t": "creatures", "nl": 0 },
            { "t": "glide under water,", "nl": 0 },
            { "t": "unapparent", "nl": 0 },
            { "t": "for the most part,", "nl": 0 },
            { "t": "and treacherously hidden", "nl": 0 },
            { "t": "beneath the loveliest", "nl": 0 },
            { "t": "tints of azure.", "nl": 0 },
            { "t": "Consider also", "nl": 0 },
            { "t": "the devilish brilliance", "nl": 0 },
            { "t": "and beauty", "nl": 0 },
            { "t": "of many", "nl": 0 },
            { "t": "of its", "nl": 0 },
            { "t": "most remorseless tribes,", "nl": 0 },
            { "t": "as the dainty", "nl": 0 },
            { "t": "embellished shape", "nl": 0 },
            { "t": "of many species", "nl": 0 },
            { "t": "of sharks.", "nl": 0 },
            { "t": "Consider,", "nl": 0 },
            { "t": "once more,", "nl": 0 },
            { "t": "the universal cannibalism", "nl": 0 },
            { "t": "of the sea;", "nl": 0 },
            { "t": "all whose", "nl": 0 },
            { "t": "creatures", "nl": 0 },
            { "t": "prey upon each other,", "nl": 0 },
            { "t": "carrying on", "nl": 0 },
            { "t": "eternal war", "nl": 0 },
            { "t": "since the world began.", "nl": 2 },
            { "t": "Consider all this;", "nl": 0 },
            { "t": "and then", "nl": 0 },
            { "t": "turn to this green,", "nl": 0 },
            { "t": "gentle,", "nl": 0 },
            { "t": "and most docile", "nl": 0 },
            { "t": "earth;", "nl": 0 },
            { "t": "consider them both,", "nl": 0 },
            { "t": "the sea and the land;", "nl": 0 },
            { "t": "and do you not find", "nl": 0 },
            { "t": "a strange analogy", "nl": 0 },
            { "t": "to something", "nl": 0 },
            { "t": "in yourself?", "nl": 0 },
            { "t": "For as this", "nl": 0 },
            { "t": "appalling ocean", "nl": 0 },
            { "t": "surrounds", "nl": 0 },
            { "t": "the verdant land,", "nl": 0 },
            { "t": "so", "nl": 0 },
            { "t": "in the soul of man", "nl": 0 },
            { "t": "there lies", "nl": 0 },
            { "t": "one insular Tahiti,", "nl": 0 },
            { "t": "full of peace and joy,", "nl": 0 },
            { "t": "but encompassed", "nl": 0 },
            { "t": "by all the horrors", "nl": 0 },
            { "t": "of the", "nl": 0 },
            { "t": "half known life.", "nl": 0 },
            { "t": "God keep thee!", "nl": 0 },
            { "t": "Push not off", "nl": 0 },
            { "t": "from that isle,", "nl": 0 },
            { "t": "thou canst never return!", "nl": 0 },
        ],
    },
    {
        "id": 24,
        "author": "Samuel Pepys",
        "author_img": "samuel_pepys.jpg",
        "title": "Diary of Samuel Pepys",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/4200",
        "lines": [
            { "t": "To church", "nl": 0 },
            { "t": "in the morning,", "nl": 0 },
            { "t": "and there", "nl": 0 },
            { "t": "saw a wedding", "nl": 0 },
            { "t": "in the church,", "nl": 0 },
            { "t": "which I have not seen", "nl": 0 },
            { "t": "many a day;", "nl": 0 },
            { "t": "and the", "nl": 0 },
            { "t": "young people", "nl": 0 },
            { "t": "so merry", "nl": 0 },
            { "t": "one with another,", "nl": 0 },
            { "t": "and strange to see", "nl": 0 },
            { "t": "what delight", "nl": 0 },
            { "t": "we married people", "nl": 0 },
            { "t": "have to see these poor fools", "nl": 0 },
            { "t": "decoyed into our condition,", "nl": 0 },
            { "t": "every man and woman", "nl": 0 },
            { "t": "gazing and smiling at them.", "nl": 0 },
            { "t": "Here I saw again", "nl": 0 },
            { "t": "my beauty Lethulier.", "nl": 0 },
            { "t": "Thence", "nl": 0 },
            { "t": "to my Lord Bruncker’s", "nl": 0 },
            { "t": "by invitation", "nl": 0 },
            { "t": "and dined there,", "nl": 0 },
            { "t": "and so home", "nl": 0 },
            { "t": "to look over", "nl": 0 },
            { "t": "and settle my papers,", "nl": 0 },
            { "t": "both of", "nl": 0 },
            { "t": "my accounts private,", "nl": 0 },
            { "t": "and those of Tangier,", "nl": 0 },
            { "t": "which I have", "nl": 0 },
            { "t": "let go", "nl": 0 },
            { "t": "so long", "nl": 0 },
            { "t": "that it were impossible", "nl": 0 },
            { "t": "for any soul,", "nl": 0 },
            { "t": "had I died,", "nl": 0 },
            { "t": "to understand them,", "nl": 0 },
            { "t": "or ever come to any good", "nl": 0 },
            { "t": "end in them.", "nl": 0 },
            { "t": "I hope God", "nl": 0 },
            { "t": "will never", "nl": 0 },
            { "t": "suffer me", "nl": 0 },
            { "t": "to come to", "nl": 0 },
            { "t": "that disorder again.", "nl": 2 },
            { "t": "Up, and to", "nl": 0 },
            { "t": "the office,", "nl": 0 },
            { "t": "where Sir J. Minnes", "nl": 0 },
            { "t": "and my Lord Bruncker", "nl": 0 },
            { "t": "and I met,", "nl": 0 },
            { "t": "to give our directions", "nl": 0 },
            { "t": "to the Commanders", "nl": 0 },
            { "t": "of all the ships", "nl": 0 },
            { "t": "in the river", "nl": 0 },
            { "t": "to bring in", "nl": 0 },
            { "t": "lists of their ships’", "nl": 0 },
            { "t": "companies,", "nl": 0 },
            { "t": "with entries,", "nl": 0 },
            { "t": "discharges, &c.,", "nl": 0 },
            { "t": "all the last voyage,", "nl": 0 },
            { "t": "where young Seymour,", "nl": 0 },
            { "t": "among 20 that stood bare,", "nl": 0 },
            { "t": "stood with his hat on,", "nl": 0 },
            { "t": "a proud,", "nl": 0 },
            { "t": "saucy young man.", "nl": 0 },
            { "t": "Thence with them", "nl": 0 },
            { "t": "to Mr. Cuttle’s,", "nl": 0 },
            { "t": "being invited,", "nl": 0 },
            { "t": "and dined", "nl": 0 },
            { "t": "nobly and neatly;", "nl": 0 },
            { "t": "with a", "nl": 0 },
            { "t": "very pretty house", "nl": 0 },
            { "t": "and a fine turret", "nl": 0 },
            { "t": "at top,", "nl": 0 },
            { "t": "with winding stairs", "nl": 0 },
            { "t": "and the finest prospect", "nl": 0 },
            { "t": "I know about", "nl": 0 },
            { "t": "all Greenwich,", "nl": 0 },
            { "t": "save the top", "nl": 0 },
            { "t": "of the hill,", "nl": 0 },
            { "t": "and yet", "nl": 0 },
            { "t": "in some respects", "nl": 0 },
            { "t": "better than that.", "nl": 0 },
            { "t": "Here I also saw", "nl": 0 },
            { "t": "some fine writing worke", "nl": 0 },
            { "t": "and flourishing", "nl": 0 },
            { "t": "of Mr. Hore,", "nl": 0 },
            { "t": "he one that I knew", "nl": 0 },
            { "t": "long ago,", "nl": 0 },
            { "t": "an acquaintance of", "nl": 0 },
            { "t": "Mr. Tomson’s", "nl": 0 },
            { "t": "at Westminster,", "nl": 0 },
            { "t": "that is", "nl": 0 },
            { "t": "this man’s clerk.", "nl": 0 },
            { "t": "It is the story", "nl": 0 },
            { "t": "of the several", "nl": 0 },
            { "t": "Archbishops of Canterbury,", "nl": 0 },
            { "t": "engrossed in vellum,", "nl": 0 },
            { "t": "to hang up", "nl": 0 },
            { "t": "in Canterbury Cathedrall", "nl": 0 },
            { "t": "in tables,", "nl": 0 },
            { "t": "in lieu of the old ones,", "nl": 0 },
            { "t": "which are almost worn out.", "nl": 0 },
            { "t": "Thence to the office", "nl": 0 },
            { "t": "a while,", "nl": 0 },
            { "t": "and so to", "nl": 0 },
            { "t": "Captain Cocke’s", "nl": 0 },
            { "t": "and there talked,", "nl": 0 },
            { "t": "and home", "nl": 0 },
            { "t": "to look over my papers,", "nl": 0 },
            { "t": "and so to bed.", "nl": 1 },
        ],
    },
    {
        "id": 25,
        "author": "Charlotte Perkins Gilman",
        "author_img": "charlotte_perkins_gilman.jpg",
        "title": "The Yellow Wallpaper",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/1952",
        "lines": [
            { "t": "I quite enjoy", "nl": 0 },
            { "t": "the room,", "nl": 0 },
            { "t": "now it is", "nl": 0 },
            { "t": "bare again.", "nl": 2 },
            { "t": "How those children", "nl": 0 },
            { "t": "did tear about", "nl": 0 },
            { "t": "here!", "nl": 2 },
            { "t": "This bedstead", "nl": 0 },
            { "t": "is fairly gnawed!", "nl": 2 },
            { "t": "But I must", "nl": 0 },
            { "t": "get to work.", "nl": 2 },
            { "t": "I have locked", "nl": 0 },
            { "t": "the door", "nl": 0 },
            { "t": "and thrown the key", "nl": 0 },
            { "t": "down into", "nl": 0 },
            { "t": "the front path.", "nl": 2 },
            { "t": "I don’t", "nl": 0 },
            { "t": "want to go out,", "nl": 0 },
            { "t": "and I don’t", "nl": 0 },
            { "t": "want to have", "nl": 0 },
            { "t": "anybody come in,", "nl": 0 },
            { "t": "till John comes.", "nl": 2 },
            { "t": "I want to", "nl": 0 },
            { "t": "astonish him.", "nl": 2 },
            { "t": "I’ve got a rope", "nl": 0 },
            { "t": "up here", "nl": 0 },
            { "t": "that even Jennie", "nl": 0 },
            { "t": "did not find.", "nl": 0 },
            { "t": "If that woman does", "nl": 0 },
            { "t": "get out,", "nl": 0 },
            { "t": "and tries", "nl": 0 },
            { "t": "to get away,", "nl": 0 },
            { "t": "I can tie her!", "nl": 2 },
            { "t": "But I forgot", "nl": 0 },
            { "t": "I could not", "nl": 0 },
            { "t": "reach far", "nl": 0 },
            { "t": "without anything", "nl": 0 },
            { "t": "to stand on!", "nl": 2 },
            { "t": "This bed", "nl": 0 },
            { "t": "will not move!", "nl": 2 },
            { "t": "I tried to lift", "nl": 0 },
            { "t": "and push it", "nl": 0 },
            { "t": "until I was lame,", "nl": 0 },
            { "t": "and then", "nl": 0 },
            { "t": "I got so angry", "nl": 0 },
            { "t": "I bit off", "nl": 0 },
            { "t": "a little piece", "nl": 0 },
            { "t": "at one corner—", "nl": 0 },
            { "t": "but it hurt my teeth.", "nl": 2 },
            { "t": "Then I peeled off", "nl": 0 },
            { "t": "all the paper", "nl": 0 },
            { "t": "I could reach", "nl": 0 },
            { "t": "standing on the floor.", "nl": 0 },
            { "t": "It sticks horribly", "nl": 0 },
            { "t": "and the pattern", "nl": 0 },
            { "t": "just enjoys it!", "nl": 0 },
            { "t": "All those", "nl": 0 },
            { "t": "strangled heads", "nl": 0 },
            { "t": "and bulbous eyes", "nl": 0 },
            { "t": "and waddling", "nl": 0 },
            { "t": "fungus growths", "nl": 0 },
            { "t": "just shriek with", "nl": 0 },
            { "t": "derision!", "nl": 2 },
            { "t": "I am getting", "nl": 0 },
            { "t": "angry enough", "nl": 0 },
            { "t": "to do something desperate.", "nl": 0 },
            { "t": "To jump", "nl": 0 },
            { "t": "out of the window", "nl": 0 },
            { "t": "would be", "nl": 0 },
            { "t": "admirable exercise,", "nl": 0 },
            { "t": "but the bars are", "nl": 0 },
            { "t": "too strong", "nl": 0 },
            { "t": "even to try.", "nl": 2 },
            { "t": "Besides", "nl": 0 },
            { "t": "I wouldn’t do it.", "nl": 0 },
            { "t": "Of course not.", "nl": 0 },
            { "t": "I know well enough", "nl": 0 },
            { "t": "that", "nl": 0 },
            { "t": "a step like that", "nl": 0 },
            { "t": "is improper", "nl": 0 },
            { "t": "and might be misconstrued.", "nl": 2 },
            { "t": "I don’t like", "nl": 0 },
            { "t": "to look out", "nl": 0 },
            { "t": "of the windows even—", "nl": 0 },
            { "t": "there are", "nl": 0 },
            { "t": "so many of", "nl": 0 },
            { "t": "those", "nl": 0 },
            { "t": "creeping women,", "nl": 0 },
            { "t": "and they creep so fast.", "nl": 2 },
            { "t": "I wonder if", "nl": 0 },
            { "t": "they all come out of", "nl": 0 },
            { "t": "that wallpaper", "nl": 0 },
            { "t": "as I did?", "nl": 2 },
            { "t": "But I am securely fastened", "nl": 0 },
            { "t": "now", "nl": 0 },
            { "t": "by my well-hidden rope—", "nl": 0 },
            { "t": "you don’t", "nl": 0 },
            { "t": "get me out", "nl": 0 },
            { "t": "in the road there!", "nl": 2 },
            { "t": "I suppose", "nl": 0 },
            { "t": "I shall have to get", "nl": 0 },
            { "t": "back behind", "nl": 0 },
            { "t": "the pattern", "nl": 0 },
            { "t": "when it comes night,", "nl": 0 },
            { "t": "and that is hard!", "nl": 2 },
            { "t": "It is so pleasant", "nl": 0 },
            { "t": "to be out", "nl": 0 },
            { "t": "in this great room", "nl": 0 },
            { "t": "and creep around", "nl": 0 },
            { "t": "as I please!", "nl": 2 },
            { "t": "I don’t want", "nl": 0 },
            { "t": "to go outside.", "nl": 0 },
            { "t": "I won’t,", "nl": 0 },
            { "t": "even if Jennie", "nl": 0 },
            { "t": "asks me to.", "nl": 2 },
            { "t": "For outside", "nl": 0 },
            { "t": "you have to creep", "nl": 0 },
            { "t": "on the ground,", "nl": 0 },
            { "t": "and everything", "nl": 0 },
            { "t": "is green", "nl": 0 },
            { "t": "instead of yellow.", "nl": 2 },
            { "t": "But here", "nl": 0 },
            { "t": "I can creep", "nl": 0 },
            { "t": "smoothly on the floor,", "nl": 0 },
            { "t": "and my shoulder", "nl": 0 },
            { "t": "just fits", "nl": 0 },
            { "t": "in that long smooch", "nl": 0 },
            { "t": "around the wall,", "nl": 0 },
            { "t": "so I cannot lose my way.", "nl": 0 },
        ],
    },
    {
        "id": 26,
        "author": "W.E.B. Du Bois",
        "author_img": "w_e_n_du_bois.jpg",
        "title": "The Quest for the Silver Fleece",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/15265",
        "lines": [
            { "t": "Night fell.", "nl": 0 },
            { "t": "The red waters", "nl": 0 },
            { "t": "of the swamp", "nl": 0 },
            { "t": "grew sinister", "nl": 0 },
            { "t": "and sullen.", "nl": 0 },
            { "t": "The tall pines", "nl": 0 },
            { "t": "lost their slimness", "nl": 0 },
            { "t": "and stood", "nl": 0 },
            { "t": "in wide", "nl": 0 },
            { "t": "blurred blotches", "nl": 0 },
            { "t": "all across the way,", "nl": 0 },
            { "t": "and a great", "nl": 0 },
            { "t": "shadowy bird", "nl": 0 },
            { "t": "arose,", "nl": 0 },
            { "t": "wheeled and melted,", "nl": 0 },
            { "t": "murmuring,", "nl": 0 },
            { "t": "into the black-green sky.", "nl": 2 },
            { "t": "The boy", "nl": 0 },
            { "t": "wearily dropped", "nl": 0 },
            { "t": "his heavy bundle", "nl": 0 },
            { "t": "and stood still,", "nl": 0 },
            { "t": "listening", "nl": 0 },
            { "t": "as the voice of crickets", "nl": 0 },
            { "t": "split the shadows", "nl": 0 },
            { "t": "and made the silence", "nl": 0 },
            { "t": "audible.", "nl": 0 },
            { "t": "A tear", "nl": 0 },
            { "t": "wandered down", "nl": 0 },
            { "t": "his brown cheek.", "nl": 0 },
            { "t": "They were", "nl": 0 },
            { "t": "at supper now,", "nl": 0 },
            { "t": "he whispered—", "nl": 0 },
            { "t": "the father and old mother,", "nl": 0 },
            { "t": "away back yonder", "nl": 0 },
            { "t": "beyond the night.", "nl": 0 },
            { "t": "They were far away;", "nl": 0 },
            { "t": "they would never", "nl": 0 },
            { "t": "be as near", "nl": 0 },
            { "t": "as once they had been,", "nl": 0 },
            { "t": "for he had", "nl": 0 },
            { "t": "stepped into the world.", "nl": 0 },
            { "t": "And the cat", "nl": 0 },
            { "t": "and Old Billy—ah,", "nl": 0 },
            { "t": "but the world", "nl": 0 },
            { "t": "was a lonely thing,", "nl": 0 },
            { "t": "so wide and tall", "nl": 0 },
            { "t": "and empty!", "nl": 0 },
            { "t": "And so bare,", "nl": 0 },
            { "t": "so bitter bare!", "nl": 0 },
            { "t": "Somehow", "nl": 0 },
            { "t": "he had never", "nl": 0 },
            { "t": "dreamed of the world", "nl": 0 },
            { "t": "as lonely before;", "nl": 0 },
            { "t": "he had", "nl": 0 },
            { "t": "fared forth", "nl": 0 },
            { "t": "to beckoning", "nl": 0 },
            { "t": "hands", "nl": 0 },
            { "t": "and luring,", "nl": 0 },
            { "t": "and to the eager hum", "nl": 0 },
            { "t": "of human voices,", "nl": 0 },
            { "t": "as of some great,", "nl": 0 },
            { "t": "swelling music.", "nl": 0 },
        ],
    },
    {
        "id": 27,
        "author": "Lewis Carroll",
        "author_img": "lewis_carrol.jpg",
        "title": "Alice's Adventures in Wonderland",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/11",
        "lines": [
            { "t": "How doth", "nl": 0 },
            { "t": "the little crocodile", "nl": 0 },
            { "t": "Improve", "nl": 0 },
            { "t": "his shining tail,", "nl": 0 },
            { "t": "And pour", "nl": 0 },
            { "t": "the waters", "nl": 0 },
            { "t": "of the Nile", "nl": 0 },
            { "t": "On every", "nl": 0 },
            { "t": "golden scale!", "nl": 2 },

            { "t": "How cheerfully", "nl": 0 },
            { "t": "he seems to grin,", "nl": 0 },
            { "t": "How neatly", "nl": 0 },
            { "t": "spread his claws,", "nl": 0 },
            { "t": "And welcome", "nl": 0 },
            { "t": "little fishes in", "nl": 0 },
            { "t": "With gently smiling", "nl": 0 },
            { "t": "jaws!", "nl": 0 },
        ],
    },
    {
        "id": 28,
        "author": "Jane Austen",
        "author_img": "jane_austen.jpg",
        "title": "Pride and Prejudice",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/1342",
        "lines": [
            { "t": "That is exactly", "nl": 0 },
            { "t": "the question", "nl": 0 },
            { "t": "which I expected you to ask.", "nl": 0 },
            { "t": "A lady's imagination", "nl": 0 },
            { "t": "is very rapid;", "nl": 0 },
            { "t": "it jumps", "nl": 0 },
            { "t": "from admiration to love,", "nl": 0 },
            { "t": "from love to matrimony", "nl": 0 },
            { "t": "in a moment.", "nl": 0 },
            { "t": "I knew you would be", "nl": 0 },
            { "t": "wishing me joy.", "nl": 2 },
            { "t": "Nay,", "nl": 0 },
            { "t": "if you are serious", "nl": 0 },
            { "t": "about it,", "nl": 0 },
            { "t": "I shall consider", "nl": 0 },
            { "t": "the matter", "nl": 0 },
            { "t": "is absolutely settled.", "nl": 0 },
            { "t": "You will be having", "nl": 0 },
            { "t": "a charming", "nl": 0 },
            { "t": "mother-in-law,", "nl": 0 },
            { "t": "indeed;", "nl": 0 },
            { "t": "and, of course,", "nl": 0 },
            { "t": "she will always", "nl": 0 },
            { "t": "be at", "nl": 0 },
            { "t": "Pemberley", "nl": 0 },
            { "t": "with you.", "nl": 2 },
            { "t": "He listened", "nl": 0 },
            { "t": "to her", "nl": 0 },
            { "t": "with perfect indifference", "nl": 0 },
            { "t": "while she chose", "nl": 0 },
            { "t": "to entertain herself", "nl": 0 },
            { "t": "in this manner;", "nl": 0 },
            { "t": "and as his composure", "nl": 0 },
            { "t": "convinced her", "nl": 0 },
            { "t": "that all was safe,", "nl": 0 },
            { "t": "her wit flowed long.", "nl": 0 },
        ],
    },
    {
        "id": 29,
        "author": "William Shakespeare",
        "author_img": "william_shakespeare.jpg",
        "title": "Sonnet 130",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/1105",
        "lines": [
            { "t": "My mistress' eyes", "nl": 0 },
            { "t": "are nothing like the sun;", "nl": 1 },
            { "t": "Coral is far more red,", "nl": 0 },
            { "t": "than her lips red:", "nl": 1 },
            { "t": "If snow be white,", "nl": 0 },
            { "t": "why then her breasts are dun;", "nl": 1 },
            { "t": "If hairs be wires,", "nl": 0 },
            { "t": "black wires grow on her head.", "nl": 1 },
            { "t": "I have seen roses damask'd,", "nl": 0 },
            { "t": "red and white,", "nl": 1 },
            { "t": "But no such roses", "nl": 0 },
            { "t": "see I in her cheeks;", "nl": 1 },
            { "t": "And in some perfumes", "nl": 0 },
            { "t": "is there more delight", "nl": 1 },
            { "t": "Than in the breath", "nl": 0 },
            { "t": "that from my mistress reeks.", "nl": 1 },
            { "t": "I love to hear her speak,", "nl": 0 },
            { "t": "yet well I know", "nl": 1 },
            { "t": "That music hath", "nl": 0 },
            { "t": "a far more pleasing sound:", "nl": 1 },
            { "t": "I grant", "nl": 0 },
            { "t": "I never saw a goddess go,--", "nl": 1 },
            { "t": "My mistress,", "nl": 0 },
            { "t": "when she walks,", "nl": 0 },
            { "t": "treads on the ground:", "nl": 1 },
            { "t": "And yet by heaven,", "nl": 0 },
            { "t": "I think my love as rare,", "nl": 1 },
            { "t": "As any she belied", "nl": 0 },
            { "t": "with false compare.", "nl": 1 },
        ],
    },
    {
        "id": 30,
        "author": "William Shakespeare",
        "author_img": "william_shakespeare.jpg",
        "title": "Sonnet 73",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/1105",
        "lines": [
            { "t": "That time", "nl": 0 },
            { "t": "of year", "nl": 0 },
            { "t": "thou mayst", "nl": 0 },
            { "t": "in me behold", "nl": 1 },
            { "t": "When yellow leaves,", "nl": 0 },
            { "t": "or few,", "nl": 0 },
            { "t": "or few,", "nl": 0 },
            { "t": "do hang", "nl": 1 },
            { "t": "Upon", "nl": 0 },
            { "t": "those boughs,", "nl": 0 },
            { "t": "which shake", "nl": 0 },
            { "t": "against the cold,", "nl": 1 },
            { "t": "Bare", "nl": 0 },
            { "t": "ruin'd choirs,", "nl": 0 },
            { "t": "where late", "nl": 0 },
            { "t": "the sweet", "nl": 0 },
            { "t": "birds sang.", "nl": 1 },
            { "t": "In me", "nl": 0 },
            { "t": "thou see'st", "nl": 0 },
            { "t": "the twilight", "nl": 0 },
            { "t": "of such day", "nl": 1 },
            { "t": "As after sunset", "nl": 0 },
            { "t": "fadeth", "nl": 0 },
            { "t": "in the west;", "nl": 1 },
            { "t": "Which", "nl": 0 },
            { "t": "by and by", "nl": 0 },
            { "t": "black night", "nl": 0 },
            { "t": "doth", "nl": 0 },
            { "t": "take away,", "nl": 1 },
            { "t": "Death's second self,", "nl": 0 },
            { "t": "that seals up", "nl": 0 },
            { "t": "all in rest.", "nl": 1 },
            { "t": "In me", "nl": 0 },
            { "t": "thou see'st", "nl": 0 },
            { "t": "the glowing", "nl": 0 },
            { "t": "of such fire,", "nl": 1 },
            { "t": "That", "nl": 0 },
            { "t": "on the ashes", "nl": 0 },
            { "t": "of his youth", "nl": 0 },
            { "t": "doth lie,", "nl": 1 },
            { "t": "As the", "nl": 0 },
            { "t": "death-bed,", "nl": 0 },
            { "t": "whereon it", "nl": 0 },
            { "t": "must expire,", "nl": 1 },
            { "t": "Consum'd", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "that which", "nl": 0 },
            { "t": "it was", "nl": 0 },
            { "t": "nourish'd by.", "nl": 1 },
            { "t": "This thou", "nl": 0 },
            { "t": "perceiv'st,", "nl": 0 },
            { "t": "which makes", "nl": 0 },
            { "t": "thy love", "nl": 0 },
            { "t": "more strong,", "nl": 1 },
            { "t": "To love", "nl": 0 },
            { "t": "that well,", "nl": 0 },
            { "t": "which thou", "nl": 0 },
            { "t": "must leave", "nl": 0 },
            { "t": "ere long.", "nl": 1 },
        ],
    },
    {
        "id": 31,
        "author": "Emily Dickinson",
        "author_img": "emily_dickinson.jpg",
        "title": "Success",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/12242",
        "lines": [
            { "t": "Success is", "nl": 1 },
            { "t": "counted sweetest", "nl": 1 },
            { "t": "By those who ", "nl": 1 },
            { "t": "ne'er succeed.", "nl": 1 },
            { "t": "To comprehend", "nl": 1 },
            { "t": "a nectar", "nl": 1 },
            { "t": "Requires", "nl": 1 },
            { "t": "sorest need.", "nl": 2 },

            { "t": "Not one", "nl": 1 },
            { "t": "of all", "nl": 1 },
            { "t": "the purple host", "nl": 1 },
            { "t": "Who took", "nl": 1 },
            { "t": "the flag to-day", "nl": 1 },
            { "t": "Can tell", "nl": 1 },
            { "t": "the definition,", "nl": 1 },
            { "t": "So clear,", "nl": 1},
            { "t": "of victory,", "nl": 2},

            { "t": "As he,", "nl": 1 },
            { "t": "defeated, dying,", "nl": 1 },
            { "t": "On whose", "nl": 1 },
            { "t": "forbidden ear", "nl": 1 },
            { "t": "The distant strains", "nl": 1 },
            { "t": "of triumph", "nl": 1 },
            { "t": "Break,", "nl": 1},
            { "t": "agonized", "nl": 1},
            { "t": "and clear!", "nl": 2},
        ],
    },
    {
        "id": 32,
        "author": "Laurence Sterne",
        "author_img": "lawrence_sterne.jpg",
        "title": "The Life and Opinions of Tristram Shandy, Gentleman",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/1079",
        "lines": [
            { "t": "You must", "nl": 0 },
            { "t": "have", "nl": 0 },
            { "t": "a little patience.", "nl": 0 },
            { "t": "I have,", "nl": 0 },
            { "t": "undertaken,", "nl": 0 },
            { "t": "you see,", "nl": 0 },
            { "t": "to write", "nl": 0 },
            { "t": "not only", "nl": 0 },
            { "t": "my life,", "nl": 0 },
            { "t": "but", "nl": 0 },
            { "t": "my opinions", "nl": 0 },
            { "t": "also;", "nl": 0 },
            { "t": "hoping and expecting", "nl": 0 },
            { "t": "that your,", "nl": 0 },
            { "t": "knowledge,", "nl": 0 },
            { "t": "of my,", "nl": 0 },
            { "t": "character,", "nl": 0 },
            { "t": "and of ,", "nl": 0 },
            { "t": "what kind of,", "nl": 0 },
            { "t": "a,", "nl": 0 },
            { "t": "mortal,", "nl": 0 },
            { "t": "I am,", "nl": 0 },
            { "t": "by the one,", "nl": 0 },
            { "t": "would give you", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "better relish", "nl": 0 },
            { "t": "for the other:", "nl": 0 },
            { "t": "As you proceed,", "nl": 0 },
            { "t": "farther with me,", "nl": 0 },
            { "t": "the,", "nl": 0 },
            { "t": "slight acquaintance,", "nl": 0 },
            { "t": "which is", "nl": 0 },
            { "t": "now", "nl": 0 },
            { "t": "beginning,", "nl": 0 },
            { "t": "betwixt us,", "nl": 0 },
            { "t": "will grow into;", "nl": 0 },
            { "t": "familiarity;", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "that unless", "nl": 0 },
            { "t": "one of us,", "nl": 0 },
            { "t": "is in fault,", "nl": 0 },
            { "t": "will terminate", "nl": 0 },
            { "t": "in friendship.", "nl": 0 },
            { "t": "O diem praeclarum!", "nl": 0 },
        ],
    },
    {
        "id": 34,
        "author": "Robert Louis Stevenson",
        "author_img": "robert_louis_stevenson.jpg",
        "title": "Escape at Bedtime",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/19722",
        "lines": [
            { "t": "The", "nl": 0 },
            { "t": "lights", "nl": 0 },
            { "t": "from the", "nl": 0 },
            { "t": "parlor", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "kitchen", "nl": 0 },
            { "t": "shone out", "nl": 1 },
            { "t": "Through", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "blinds", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "windows and bars;", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "high over head", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "all moving about,", "nl": 1 },
            { "t": "There", "nl": 0 },
            { "t": "were", "nl": 0 },
            { "t": "thousands of millions", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "stars.", "nl": 1 },
            { "t": "There", "nl": 0 },
            { "t": "ne'er were", "nl": 0 },
            { "t": "such", "nl": 0 },
            { "t": "thousands of leaves", "nl": 0 },
            { "t": "on a", "nl": 0 },
            { "t": "tree,", "nl": 1 },
            { "t": "Nor", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "people in church", "nl": 0 },
            { "t": "or", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "Park,", "nl": 1 },
            { "t": "As", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "crowds of the stars", "nl": 0 },
            { "t": "that", "nl": 0 },
            { "t": "looked", "nl": 0 },
            { "t": "down upon me,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "that", "nl": 0 },
            { "t": "glittered and winked", "nl": 0 },
            { "t": "in the", "nl": 0 },
            { "t": "dark.", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "Dog,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "Plough,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "Hunter", "nl": 0 },
            { "t": "and all,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "star", "nl": 0 },
            { "t": "of the", "nl": 0 },
            { "t": "sailor,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "Mars,", "nl": 1 },
            { "t": "These", "nl": 0 },
            { "t": "shone", "nl": 0 },
            { "t": "in the sky,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "pail", "nl": 0 },
            { "t": "by the wall", "nl": 0 },
            { "t": "Would be", "nl": 0 },
            { "t": "half full", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "water and stars.", "nl": 1 },
            { "t": "They", "nl": 0 },
            { "t": "saw me", "nl": 0 },
            { "t": "at last,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "they", "nl": 0 },
            { "t": "chased me", "nl": 0 },
            { "t": "with cries,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "they", "nl": 0 },
            { "t": "soon had me", "nl": 0 },
            { "t": "packed into bed;", "nl": 1 },
            { "t": "But", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "glory", "nl": 0 },
            { "t": "kept", "nl": 0 },
            { "t": "shining and bright", "nl": 0 },
            { "t": "in my eyes,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "stars", "nl": 0 },
            { "t": "going round", "nl": 0 },
            { "t": "in my head.", "nl": 1 },
        ],
    },

    {
        "id": 35,
        "author": "Walter Crane",
        "author_img": "walter_crane.jpg",
        "title": "Queen Summer",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/24449",
        "lines": [
            { "t": "When", "nl": 0 },
            { "t": "Summer", "nl": 0 },
            { "t": "on the earth", "nl": 0 },
            { "t": "was", "nl": 0 },
            { "t": "queen", "nl": 1 },
            { "t": "She", "nl": 0 },
            { "t": "held her court", "nl": 0 },
            { "t": "in", "nl": 0 },
            { "t": "gardens green", "nl": 1 },
            { "t": "Fair hung", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "tapestry of leaves,", "nl": 1 },
            { "t": "Where", "nl": 0 },
            { "t": "threads of gold", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "sun", "nl": 0 },
            { "t": "enweaves", "nl": 1 },
            { "t": "With", "nl": 0 },
            { "t": "checquered patterns", "nl": 0 },
            { "t": "on the floor", "nl": 1 },
            { "t": "Of", "nl": 0 },
            { "t": "velvet lawns", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "scythe", "nl": 0 },
            { "t": "smoothes o’er:", "nl": 1 },
            { "t": "Their", "nl": 0 },
            { "t": "waving fans", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "soft winds", "nl": 0 },
            { "t": "spread", "nl": 1 },
            { "t": "Each way", "nl": 0 },
            { "t": "to", "nl": 0 },
            { "t": "cool", "nl": 0 },
            { "t": "Queen Summer’s", "nl": 0 },
            { "t": "head:", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "woodland dove", "nl": 0 },
            { "t": "made", "nl": 0 },
            { "t": "music soft,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "Eros", "nl": 0 },
            { "t": "touched", "nl": 0 },
            { "t": "his lute", "nl": 0 },
            { "t": "full oft.", "nl": 1 },
            { "t": "Round Time’s", "nl": 0 },
            { "t": "dial", "nl": 0 },
            { "t": "thronged", "nl": 0 },
            { "t": "the hours,", "nl": 1 },
            { "t": "Masking", "nl": 0 },
            { "t": "in the", "nl": 0 },
            { "t": "Masque of Flowers", "nl": 1 },
            { "t": "Like", "nl": 0 },
            { "t": "knights and ladies", "nl": 0 },
            { "t": "fair be-dight", "nl": 1 },
            { "t": "In", "nl": 0 },
            { "t": "silk attire,", "nl": 0 },
            { "t": "both red and white.", "nl": 1 },
            { "t": "And as", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "winds", "nl": 0 },
            { "t": "about them", "nl": 0 },
            { "t": "played,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "shook", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "flowers", "nl": 0 },
            { "t": "or", "nl": 0 },
            { "t": "disarrayed,", "nl": 1 },
            { "t": "A", "nl": 0 },
            { "t": "whispered word", "nl": 0 },
            { "t": "among them", "nl": 0 },
            { "t": "goes", "nl": 1 },
            { "t": "Of", "nl": 0 },
            { "t": "how the", "nl": 0 },
            { "t": "Lily", "nl": 0 },
            { "t": "flouts", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "Rose,", "nl": 1 },
            { "t": "Suitors", "nl": 0 },
            { "t": "for", "nl": 0 },
            { "t": "Summer’s favor", "nl": 0 },
            { "t": "dear,", "nl": 1 },
            { "t": "To", "nl": 0 },
            { "t": "win the crown", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "all the year—", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "how", "nl": 0 },
            { "t": "each champion", "nl": 0 },
            { "t": "brave would fight,", "nl": 1 },
            { "t": "Queen Summer", "nl": 0 },
            { "t": "to", "nl": 0 },
            { "t": "decide the right.", "nl": 1 },
            { "t": "Then", "nl": 0 },
            { "t": "shrill", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "wind-winged heralds", "nl": 0 },
            { "t": "blew;", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "lists", "nl": 0 },
            { "t": "were set", "nl": 0 },
            { "t": "in", "nl": 0 },
            { "t": "Summer’s view,", "nl": 1 },
            { "t": "With", "nl": 0 },
            { "t": "blazoned shields,", "nl": 0 },
            { "t": "&", "nl": 0 },
            { "t": "pennons spruce", "nl": 1 },
            { "t": "Of", "nl": 0 },
            { "t": "fluttering flag", "nl": 0 },
            { "t": "&", "nl": 0 },
            { "t": "fleur-de-luce:", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "spread", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "’broidered hangings", "nl": 0 },
            { "t": "gay,", "nl": 1 },
            { "t": "Till", "nl": 0 },
            { "t": "all was ready", "nl": 0 },
            { "t": "for the", "nl": 0 },
            { "t": "fray.", "nl": 1 },
            { "t": "Between", "nl": 0 },
            { "t": "their", "nl": 0 },
            { "t": "banners", "nl": 0 },
            { "t": "white and red,", "nl": 1 },
            { "t": "Of", "nl": 0 },
            { "t": "Rose and Lily", "nl": 0 },
            { "t": "overhead,", "nl": 1 },
            { "t": "Queen Summer", "nl": 0 },
            { "t": "took", "nl": 0 },
            { "t": "her", "nl": 0 },
            { "t": "judgment seat,", "nl": 1 },
            { "t": "Whom", "nl": 0 },
            { "t": "all the crowd", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "flowers", "nl": 0 },
            { "t": "did greet.", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "silver", "nl": 0 },
            { "t": "arum-trumpet’s", "nl": 0 },
            { "t": "sound", "nl": 1 },
            { "t": "With", "nl": 0 },
            { "t": "tongues of gold,", "nl": 0 },
            { "t": "& to", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "ground", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "shining champions", "nl": 0 },
            { "t": "each", "nl": 0 },
            { "t": "did ride,", "nl": 1 },
            { "t": "Their", "nl": 0 },
            { "t": "party-colours", "nl": 0 },
            { "t": "flaunting wide.", "nl": 1 },
            { "t": "Came", "nl": 0 },
            { "t": "first", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "glowing Rose", "nl": 0 },
            { "t": "in view,", "nl": 1 },
            { "t": "With", "nl": 0 },
            { "t": "crimson", "nl": 0 },
            { "t": "pennon", "nl": 0 },
            { "t": "fluttering new;", "nl": 1 },
            { "t": "With", "nl": 0 },
            { "t": "glittering spines", "nl": 0 },
            { "t": "all", "nl": 0 },
            { "t": "armed", "nl": 0 },
            { "t": "he came,", "nl": 1 },
            { "t": "With", "nl": 0 },
            { "t": "lance and shield—", "nl": 0 },
            { "t": "a rose aflame;", "nl": 1 },
            { "t": "With", "nl": 0 },
            { "t": "tossing crest", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "mantling free,", "nl": 1 },
            { "t": "On", "nl": 0 },
            { "t": "fiery steed,—", "nl": 0 },
            { "t": "a sight to see!", "nl": 2 },
        ],
    },
    {
        "id": 36,
        "author": "John Donne",
        "author_img": "john_donne.jpg",
        "title": "Death Be Not Proud",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/48688",
        "lines": [
            { "t": "Death", "nl": 0 },
            { "t": "be not", "nl": 0 },
            { "t": "proud,", "nl": 0 },
            { "t": "though", "nl": 0 },
            { "t": "some have", "nl": 0 },
            { "t": "called", "nl": 0 },
            { "t": "thee", "nl": 1 },
            { "t": "Mighty", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "dreadfull,", "nl": 0 },
            { "t": "for,", "nl": 0 },
            { "t": "thou art", "nl": 0 },
            { "t": "not soe,", "nl": 1 },
            { "t": "For,", "nl": 0 },
            { "t": "those,", "nl": 0 },
            { "t": "whom thou think'st,", "nl": 0 },
            { "t": "thou dost", "nl": 0 },
            { "t": "overthrow,", "nl": 1 },
            { "t": "Die not,", "nl": 0 },
            { "t": "poore death,", "nl": 0 },
            { "t": "nor yet", "nl": 0 },
            { "t": "canst", "nl": 0 },
            { "t": "thou kill mee.", "nl": 1 },
            { "t": "From", "nl": 0 },
            { "t": "rest and sleepe,", "nl": 0 },
            { "t": "which", "nl": 0 },
            { "t": "but", "nl": 0 },
            { "t": "thy pictures bee,", "nl": 1 },
            { "t": "Much pleasure,", "nl": 0 },
            { "t": "then", "nl": 0 },
            { "t": "from thee,", "nl": 0 },
            { "t": "much more", "nl": 0 },
            { "t": "must flow,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "soonest", "nl": 0 },
            { "t": "our best men", "nl": 0 },
            { "t": "with thee", "nl": 0 },
            { "t": "doe goe,", "nl": 1 },
            { "t": "Rest of their bones,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "soules deliverie.", "nl": 1 },
            { "t": "Thou art", "nl": 0 },
            { "t": "slave to Fate,", "nl": 0 },
            { "t": "Chance,", "nl": 0 },
            { "t": "kings,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "desperate men,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "dost", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "poyson,", "nl": 0 },
            { "t": "warre,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "sicknesse", "nl": 0 },
            { "t": "dwell,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "poppie,", "nl": 0 },
            { "t": "or", "nl": 0 },
            { "t": "charmes", "nl": 0 },
            { "t": "can make us", "nl": 0 },
            { "t": "sleepe", "nl": 0 },
            { "t": "as well,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "better then", "nl": 0 },
            { "t": "thy stroake;", "nl": 0 },
            { "t": "why swell'st", "nl": 0 },
            { "t": "thou then?", "nl": 1 },
            { "t": "One short", "nl": 0 },
            { "t": "sleepe past,", "nl": 0 },
            { "t": "wee wake", "nl": 0 },
            { "t": "eternally,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "death", "nl": 0 },
            { "t": "shall be no more;", "nl": 0 },
            { "t": "death,", "nl": 0 },
            { "t": "thou shalt die.", "nl": 2 },
        ],
    },
    {
        "id": 37,
        "author": "John Keats",
        "author_img": "john_keats.jpg",
        "title": "Sleep and Poetry",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/8209",
        "lines": [
            { "t": "What is", "nl": 0 },
            { "t": "more gentle", "nl": 0 },
            { "t": "than a wind", "nl": 0 },
            { "t": "in summer?", "nl": 1 },
            { "t": "What", "nl": 0 },
            { "t": "is", "nl": 0 },
            { "t": "more soothing", "nl": 0 },
            { "t": "than", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "pretty hummer", "nl": 1 },
            { "t": "That stays", "nl": 0 },
            { "t": "one moment", "nl": 0 },
            { "t": "in", "nl": 0 },
            { "t": "an open flower,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "buzzes cheerily", "nl": 0 },
            { "t": "from bower to bower?", "nl": 1 },
            { "t": "What", "nl": 0 },
            { "t": "is", "nl": 0 },
            { "t": "more tranquil", "nl": 0 },
            { "t": "than", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "musk-rose blowing", "nl": 1 },
            { "t": "In", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "green island,", "nl": 0 },
            { "t": "far from", "nl": 0 },
            { "t": "all men's knowing?", "nl": 1 },
            { "t": "More healthful", "nl": 0 },
            { "t": "than", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "leafiness of dales?", "nl": 1 },
            { "t": "More secret", "nl": 0 },
            { "t": "than", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "nest of nightingales?", "nl": 1 },
            { "t": "More serene", "nl": 0 },
            { "t": "than", "nl": 0 },
            { "t": "Cordelia's", "nl": 0 },
            { "t": "countenance?", "nl": 1 },
            { "t": "More", "nl": 0 },
            { "t": "full of visions", "nl": 0 },
            { "t": "than", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "high romance?", "nl": 1 },
            { "t": "What,", "nl": 0 },
            { "t": "but", "nl": 0 },
            { "t": "thee Sleep?", "nl": 0 },
            { "t": "Soft closer", "nl": 0 },
            { "t": "of our", "nl": 0 },
            { "t": "eyes!", "nl": 1 },
            { "t": "Low murmurer", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "tender lullabies!", "nl": 1 },
            { "t": "Light hoverer", "nl": 0 },
            { "t": "around", "nl": 0 },
            { "t": "our", "nl": 0 },
            { "t": "happy pillows!", "nl": 1 },
            { "t": "Wreather", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "poppy", "nl": 0 },
            { "t": "buds,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "weeping willows!", "nl": 1 },
            { "t": "Silent entangler", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "beauty's tresses!", "nl": 0 },
            { "t": "Most", "nl": 0 },
            { "t": "happy listener!", "nl": 0 },
            { "t": "when", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "morning blesses", "nl": 1 },
            { "t": "Thee", "nl": 0 },
            { "t": "for", "nl": 0 },
            { "t": "enlivening", "nl": 0 },
            { "t": "all", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "cheerful eyes", "nl": 0 },
            { "t": "That", "nl": 0 },
            { "t": "glance", "nl": 0 },
            { "t": "so brightly", "nl": 0 },
            { "t": "at the", "nl": 0 },
            { "t": "new sun-rise.", "nl": 1 },
            { "t": "But", "nl": 0 },
            { "t": "what is", "nl": 0 },
            { "t": "higher beyond thought", "nl": 0 },
            { "t": "than thee?", "nl": 1 },
            { "t": "Fresher", "nl": 0 },
            { "t": "than", "nl": 0 },
            { "t": "berries of a mountain tree?", "nl": 1 },
            { "t": "More strange,", "nl": 0 },
            { "t": "more beautiful,", "nl": 0 },
            { "t": "more smooth,", "nl": 0 },
            { "t": "more regal,", "nl": 1 },
            { "t": "Than", "nl": 0 },
            { "t": "wings of swans,", "nl": 0 },
            { "t": "than doves,", "nl": 0 },
            { "t": "than", "nl": 0 },
            { "t": "dim-seen eagle?", "nl": 1 },
            { "t": "What is it?", "nl": 0 },
            { "t": "And", "nl": 0 },
            { "t": "to what", "nl": 0 },
            { "t": "shall I compare", "nl": 0 },
            { "t": "it?", "nl": 1 },
            { "t": "It has", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "glory,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "nought else", "nl": 0 },
            { "t": "can share it:", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "thought thereof", "nl": 0 },
            { "t": "is", "nl": 0 },
            { "t": "awful,", "nl": 0 },
            { "t": "sweet,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "holy,", "nl": 1 },
            { "t": "Chacing away", "nl": 0 },
            { "t": "all worldliness", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "folly;", "nl": 1 },
            { "t": "Coming", "nl": 0 },
            { "t": "sometimes", "nl": 0 },
            { "t": "like fearful", "nl": 0 },
            { "t": "claps of thunder,", "nl": 1 },
            { "t": "Or", "nl": 0 },
            { "t": "the low rumblings", "nl": 0 },
            { "t": "earth's regions under;", "nl": 1 },
            { "t": "And sometimes", "nl": 0 },
            { "t": "like", "nl": 0 },
            { "t": "a gentle whispering", "nl": 1 },
            { "t": "Of all", "nl": 0 },
            { "t": "the secrets", "nl": 0 },
            { "t": "of some", "nl": 0 },
            { "t": "wond'rous thing", "nl": 1 },
            { "t": "That", "nl": 0 },
            { "t": "breathes about us", "nl": 0 },
            { "t": "in the", "nl": 0 },
            { "t": "vacant air;", "nl": 1 },
            { "t": "So that", "nl": 0 },
            { "t": "we", "nl": 0 },
            { "t": "look around", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "prying stare,", "nl": 1 },
            { "t": "Perhaps", "nl": 0 },
            { "t": "to see", "nl": 0 },
            { "t": "shapes of light,", "nl": 0 },
            { "t": "aerial lymning,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "catch", "nl": 0 },
            { "t": "soft floatings", "nl": 0 },
            { "t": "from", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "faint-heard", "nl": 0 },
            { "t": "hymning;", "nl": 1 },
            { "t": "To see", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "laurel wreath,", "nl": 0 },
            { "t": "on high suspended,", "nl": 1 },
            { "t": "That", "nl": 0 },
            { "t": "is to", "nl": 0 },
            { "t": "crown our name", "nl": 0 },
            { "t": "when life is ended.", "nl": 1 },
            { "t": "Sometimes", "nl": 0 },
            { "t": "it gives", "nl": 0 },
            { "t": "a glory", "nl": 0 },
            { "t": "to the", "nl": 0 },
            { "t": "voice,", "nl": 1 },
            { "t": "And from", "nl": 0 },
            { "t": "the heart", "nl": 0 },
            { "t": "up-springs,", "nl": 0 },
            { "t": "rejoice! rejoice!", "nl": 1 },
            { "t": "Sounds", "nl": 0 },
            { "t": "which will", "nl": 0 },
            { "t": "reach", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "Framer", "nl": 0 },
            { "t": "of all things,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "die away", "nl": 0 },
            { "t": "in", "nl": 0 },
            { "t": "ardent mutterings.", "nl": 1 },
            { "t": "No one", "nl": 0 },
            { "t": "who", "nl": 0 },
            { "t": "once", "nl": 0 },
            { "t": "the glorious sun", "nl": 0 },
            { "t": "has seen,", "nl": 1 },
            { "t": "And all the clouds,", "nl": 0 },
            { "t": "and felt", "nl": 0 },
            { "t": "his bosom clean", "nl": 1 },
            { "t": "For", "nl": 0 },
            { "t": "his", "nl": 0 },
            { "t": "great Maker's", "nl": 0 },
            { "t": "presence,", "nl": 0 },
            { "t": "but must know", "nl": 1 },
            { "t": "What", "nl": 0 },
            { "t": "'tis I mean,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "feel", "nl": 0 },
            { "t": "his being", "nl": 0 },
            { "t": "glow:", "nl": 1 },
            { "t": "Therefore", "nl": 0 },
            { "t": "no insult", "nl": 0 },
            { "t": "will I give", "nl": 0 },
            { "t": "his", "nl": 0 },
            { "t": "spirit,", "nl": 1 },
            { "t": "By", "nl": 0 },
            { "t": "telling", "nl": 0 },
            { "t": "what he sees", "nl": 0 },
            { "t": "from", "nl": 0 },
            { "t": "native merit.", "nl": 1 },
            { "t": "O Poesy!", "nl": 0 },
            { "t": "for thee", "nl": 0 },
            { "t": "I hold my pen", "nl": 1 },
            { "t": "That", "nl": 0 },
            { "t": "am not yet", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "glorious denizen", "nl": 1 },
            { "t": "Of", "nl": 0 },
            { "t": "thy wide heaven—", "nl": 0 },
            { "t": "Should", "nl": 0 },
            { "t": "I rather kneel", "nl": 1 },
            { "t": "Upon", "nl": 0 },
            { "t": "some mountain-top", "nl": 0 },
            { "t": "until", "nl": 0 },
            { "t": "I feel", "nl": 1 },
            { "t": "A", "nl": 0 },
            { "t": "glowing splendour", "nl": 0 },
            { "t": "round about me", "nl": 0 },
            { "t": "hung,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "echo back", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "voice", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "thine own tongue?", "nl": 1 },
            { "t": "O Poesy!", "nl": 0 },
            { "t": "for thee", "nl": 0 },
            { "t": "I grasp", "nl": 0 },
            { "t": "my pen", "nl": 1 },
            { "t": "That", "nl": 0 },
            { "t": "am", "nl": 0 },
            { "t": "not yet", "nl": 0 },
            { "t": "a glorious denizen", "nl": 1 },
            { "t": "Of", "nl": 0 },
            { "t": "thy wide", "nl": 0 },
            { "t": "heaven;", "nl": 0 },
            { "t": "yet,", "nl": 0 },
            { "t": "to my", "nl": 0 },
            { "t": "ardent prayer,", "nl": 1 },
            { "t": "Yield", "nl": 0 },
            { "t": "from", "nl": 0 },
            { "t": "thy sanctuary", "nl": 0 },
            { "t": "some clear air,", "nl": 1 },
            { "t": "Smoothed", "nl": 0 },
            { "t": "for", "nl": 0 },
            { "t": "intoxication", "nl": 0 },
            { "t": "by the breath", "nl": 1 },
            { "t": "Of", "nl": 0 },
            { "t": "flowering bays,", "nl": 0 },
            { "t": "that", "nl": 0 },
            { "t": "I may die", "nl": 0 },
            { "t": "a death", "nl": 1 },
            { "t": "Of", "nl": 0 },
            { "t": "luxury,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "my young spirit", "nl": 0 },
            { "t": "follow", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "morning sun-beams", "nl": 0 },
            { "t": "to the", "nl": 0 },
            { "t": "great Apollo", "nl": 1 },
            { "t": "Like a", "nl": 0 },
            { "t": "fresh sacrifice;", "nl": 0 },
            { "t": "or,", "nl": 0 },
            { "t": "if I can", "nl": 0 },
            { "t": "bear", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "o'erwhelming", "nl": 0 },
            { "t": "sweets,", "nl": 0 },
            { "t": "'twill", "nl": 0 },
            { "t": "bring to me", "nl": 0 },
            { "t": "the fair", "nl": 1 },
            { "t": "Visions", "nl": 0 },
            { "t": "of all places:", "nl": 0 },
            { "t": "a bowery nook", "nl": 1 },
            { "t": "Will be", "nl": 0 },
            { "t": "elysium—", "nl": 0 },
            { "t": "an eternal book", "nl": 1 },
            { "t": "Whence", "nl": 0 },
            { "t": "I may copy", "nl": 0 },
            { "t": "many", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "lovely saying", "nl": 1 },
            { "t": "About", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "leaves, and flowers", "nl": 0 },
            { "t": "—about the playing", "nl": 1 },
            { "t": "Of", "nl": 0 },
            { "t": "nymphs in woods,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "fountains;", "nl": 0 },
            { "t": "and the", "nl": 0 },
            { "t": "shade", "nl": 1 },
            { "t": "Keeping", "nl": 0 },
            { "t": "a silence", "nl": 0 },
            { "t": "round", "nl": 0 },
            { "t": "a sleeping maid;", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "many a verse", "nl": 0 },
            { "t": "from", "nl": 0 },
            { "t": "so strange", "nl": 0 },
            { "t": "influence", "nl": 1 },
            { "t": "That", "nl": 0 },
            { "t": "we must ever", "nl": 0 },
            { "t": "wonder how,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "whence", "nl": 1 },
            { "t": "It came.", "nl": 0 },
            { "t": "Also", "nl": 0 },
            { "t": "imaginings will hover", "nl": 1 },
            { "t": "Round", "nl": 0 },
            { "t": "my fire-side,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "haply there discover", "nl": 1 },
            { "t": "Vistas", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "solemn beauty,", "nl": 0 },
            { "t": "where I'd wander", "nl": 1 },
            { "t": "In", "nl": 0 },
            { "t": "happy silence,", "nl": 0 },
            { "t": "like", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "clear meander", "nl": 1 },
            { "t": "Through", "nl": 0 },
            { "t": "its", "nl": 0 },
            { "t": "lone vales;", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "where I found a spot", "nl": 1 },
            { "t": "Of", "nl": 0 },
            { "t": "awfuller shade,", "nl": 0 },
            { "t": "or", "nl": 0 },
            { "t": "an", "nl": 0 },
            { "t": "enchanted grot,", "nl": 1 },
            { "t": "Or", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "green hill", "nl": 0 },
            { "t": "o'erspread", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "chequered dress", "nl": 1 },
            { "t": "Of flowers,", "nl": 0 },
            { "t": "and fearful", "nl": 0 },
            { "t": "from", "nl": 0 },
            { "t": "its loveliness,", "nl": 1 },
            { "t": "Write", "nl": 0 },
            { "t": "on my", "nl": 0 },
            { "t": "tablets", "nl": 0 },
            { "t": "all that was permitted,", "nl": 1 },
            { "t": "All that", "nl": 0 },
            { "t": "was", "nl": 0 },
            { "t": "for our human senses", "nl": 0 },
            { "t": "fitted.", "nl": 1 },
            { "t": "Then", "nl": 0 },
            { "t": "the events", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "this wide worl", "nl": 0 },
            { "t": "I'd seize", "nl": 1 },
            { "t": "Like a strong giant,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "my spirit teaze", "nl": 1 },
            { "t": "Till", "nl": 0 },
            { "t": "at its", "nl": 0 },
            { "t": "shoulders", "nl": 0 },
            { "t": "it should", "nl": 0 },
            { "t": "proudly see", "nl": 1 },
            { "t": "Wings", "nl": 0 },
            { "t": "to find out", "nl": 0 },
            { "t": "an", "nl": 0 },
            { "t": "immortality.", "nl": 2 },
        ],
    },
    {
        "id": 38,
        "author": "Baron George Gordon Byron Byron",
        "author_img": "lord_byron.jpg",
        "title": "Childe Harold's Pilgrimage",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/25340",
        "lines": [
            { "t": "Oh, thou!", "nl": 0 },
            { "t": "in", "nl": 0 },
            { "t": "Hellas", "nl": 0 },
            { "t": "deemed", "nl": 0 },
            { "t": "of heavenly birth,", "nl": 1 },
            { "t": "Muse!", "nl": 0 },
            { "t": "formed or fabled", "nl": 0 },
            { "t": "at", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "Minstrel's will!", "nl": 1 },
            { "t": "Since shamed", "nl": 0 },
            { "t": "full oft", "nl": 0 },
            { "t": "by", "nl": 0 },
            { "t": "later lyres", "nl": 0 },
            { "t": "on earth,", "nl": 1 },
            { "t": "Mine", "nl": 0 },
            { "t": "dares not", "nl": 0 },
            { "t": "call thee", "nl": 0 },
            { "t": "from", "nl": 0 },
            { "t": "thy sacred Hill:", "nl": 1 },
            { "t": "Yet", "nl": 0 },
            { "t": "there", "nl": 0 },
            { "t": "I've wandered", "nl": 0 },
            { "t": "by", "nl": 0 },
            { "t": "thy vaunted rill;", "nl": 1 },
            { "t": "Yes!", "nl": 0 },
            { "t": "sighed", "nl": 0 },
            { "t": "o'er Delphi's", "nl": 0 },
            { "t": "long deserted shrine,", "nl": 1 },
            { "t": "Where,", "nl": 0 },
            { "t": "save", "nl": 0 },
            { "t": "that", "nl": 0 },
            { "t": "feeble fountain,", "nl": 0 },
            { "t": "all is still;", "nl": 1 },
            { "t": "Nor", "nl": 0 },
            { "t": "mote", "nl": 0 },
            { "t": "my shell", "nl": 0 },
            { "t": "awake", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "weary Nine", "nl": 1 },
            { "t": "To", "nl": 0 },
            { "t": "grace", "nl": 0 },
            { "t": "so plain a tale—", "nl": 0 },
            { "t": "this", "nl": 0 },
            { "t": "lowly lay", "nl": 0 },
            { "t": "of mine.", "nl": 1 },
            { "t": "Whilome", "nl": 0 },
            { "t": "in Albion's isle", "nl": 0 },
            { "t": "there dwelt", "nl": 0 },
            { "t": "a youth,", "nl": 1 },
            { "t": "Who ne", "nl": 0 },
            { "t": "in Virtue's ways", "nl": 0 },
            { "t": "did take", "nl": 0 },
            { "t": "delight;", "nl": 1 },
            { "t": "But", "nl": 0 },
            { "t": "spent his days", "nl": 0 },
            { "t": "in riot", "nl": 0 },
            { "t": "most uncouth,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "vexed with mirth", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "drowsy ear of Night.", "nl": 1 },
            { "t": "Ah me!", "nl": 0 },
            { "t": "in sooth", "nl": 0 },
            { "t": "he was", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "shameless wight,", "nl": 1 },
            { "t": "Sore", "nl": 0 },
            { "t": "given to revel", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "ungodly glee;", "nl": 1 },
            { "t": "Few", "nl": 0 },
            { "t": "earthly things", "nl": 0 },
            { "t": "found favour", "nl": 0 },
            { "t": "in his sight", "nl": 1 },
            { "t": "Save", "nl": 0 },
            { "t": "concubines", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "carnal companie,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "flaunting wassailers", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "high and low", "nl": 0 },
            { "t": "degree.", "nl": 1 },
            { "t": "Childe", "nl": 0 },
            { "t": "Harold", "nl": 0 },
            { "t": "was he hight:", "nl": 0 },
            { "t": "—but whence", "nl": 0 },
            { "t": "his name", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "lineage long,", "nl": 0 },
            { "t": "it suits me not", "nl": 0 },
            { "t": "to say;", "nl": 1 },
            { "t": "Suffice it,", "nl": 0 },
            { "t": "that", "nl": 0 },
            { "t": "perchance", "nl": 0 },
            { "t": "they were", "nl": 0 },
            { "t": "of fame,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "had been", "nl": 0 },
            { "t": "glorious in another day:", "nl": 1 },
            { "t": "But", "nl": 0 },
            { "t": "one sad losel", "nl": 0 },
            { "t": "soils a name", "nl": 0 },
            { "t": "for ay,", "nl": 1 },
            { "t": "However mighty", "nl": 0 },
            { "t": "in the olden time;", "nl": 1 },
            { "t": "Nor", "nl": 0 },
            { "t": "all that heralds", "nl": 0 },
            { "t": "rake", "nl": 0 },
            { "t": "from", "nl": 0 },
            { "t": "coffined clay,", "nl": 1 },
            { "t": "Nor", "nl": 0 },
            { "t": "florid prose,", "nl": 0 },
            { "t": "nor", "nl": 0 },
            { "t": "honied", "nl": 0 },
            { "t": "lies of rhyme,", "nl": 1 },
            { "t": "Can", "nl": 0 },
            { "t": "blazon", "nl": 0 },
            { "t": "evil deeds,", "nl": 0 },
            { "t": "or", "nl": 0 },
            { "t": "consecrate a crime.", "nl": 1 },
            { "t": "Childe", "nl": 0 },
            { "t": "Harold", "nl": 0 },
            { "t": "basked", "nl": 0 },
            { "t": "him", "nl": 0 },
            { "t": "in the", "nl": 0 },
            { "t": "Noontide sun,", "nl": 1 },
            { "t": "Disporting", "nl": 0 },
            { "t": "there", "nl": 0 },
            { "t": "like any other", "nl": 0 },
            { "t": "fly;", "nl": 1 },
            { "t": "Nor", "nl": 0 },
            { "t": "deemed", "nl": 0 },
            { "t": "before", "nl": 0 },
            { "t": "his little day", "nl": 0 },
            { "t": "was done", "nl": 1 },
            { "t": "One blast", "nl": 0 },
            { "t": "might chill", "nl": 0 },
            { "t": "him", "nl": 0 },
            { "t": "into misery.", "nl": 1 },
            { "t": "But", "nl": 0 },
            { "t": "long ere scarce", "nl": 0 },
            { "t": "a third", "nl": 0 },
            { "t": "of his", "nl": 0 },
            { "t": "passed by,", "nl": 1 },
            { "t": "Worse than Adversity", "nl": 0 },
            { "t": "the Childe befell;", "nl": 1 },
            { "t": "He felt", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "fulness of Satiety:", "nl": 1 },
            { "t": "Then", "nl": 0 },
            { "t": "loathed", "nl": 0 },
            { "t": "he", "nl": 0 },
            { "t": "in his native land", "nl": 0 },
            { "t": "to dwell,", "nl": 1 },
            { "t": "Which seemed", "nl": 0 },
            { "t": "to him", "nl": 0 },
            { "t": "more lone", "nl": 0 },
            { "t": "than", "nl": 0 },
            { "t": "Eremite's sad cell.", "nl": 1 },
            { "t": "For", "nl": 0 },
            { "t": "he", "nl": 0 },
            { "t": "through Sin's long labyrinth", "nl": 0 },
            { "t": "had run,", "nl": 1 },
            { "t": "Nor", "nl": 0 },
            { "t": "made atonement", "nl": 0 },
            { "t": "when", "nl": 0 },
            { "t": "he did amiss,", "nl": 1 },
            { "t": "Had sighed", "nl": 0 },
            { "t": "to many", "nl": 0 },
            { "t": "though", "nl": 0 },
            { "t": "he loved", "nl": 0 },
            { "t": "but one,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "that", "nl": 0 },
            { "t": "loved one,", "nl": 0 },
            { "t": "alas!", "nl": 0 },
            { "t": "could ne'er", "nl": 0 },
            { "t": "be his.", "nl": 1 },
            { "t": "Ah,", "nl": 0 },
            { "t": "happy she!", "nl": 0 },
            { "t": "to", "nl": 0 },
            { "t": "'scape", "nl": 0 },
            { "t": "from him", "nl": 0 },
            { "t": "whose kiss", "nl": 1 },
            { "t": "Had been", "nl": 0 },
            { "t": "pollution", "nl": 0 },
            { "t": "unto aught", "nl": 0 },
            { "t": "so chaste;", "nl": 1 },
            { "t": "Who", "nl": 0 },
            { "t": "soon", "nl": 0 },
            { "t": "had left", "nl": 0 },
            { "t": "her charms", "nl": 0 },
            { "t": "for", "nl": 0 },
            { "t": "vulgar bliss,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "spoiled her goodly lands", "nl": 0 },
            { "t": "to", "nl": 0 },
            { "t": "gild his waste,", "nl": 1 },
            { "t": "Nor", "nl": 0 },
            { "t": "calm domestic peace", "nl": 0 },
            { "t": "had ever", "nl": 0 },
            { "t": "deigned to taste.", "nl": 2 },
        ],
    },
    {
        "id": 39,
        "author": "Walt Whitman",
        "author_img": "walt_whitman.jpg",
        "title": "I Hear America Signing",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/1322",
        "lines": [
            { "t": "I hear", "nl": 0 },
            { "t": "America", "nl": 0 },
            { "t": "singing,", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "varied", "nl": 0 },
            { "t": "carols", "nl": 0 },
            { "t": "I hear,", "nl": 1 },
            { "t": "Those", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "mechanics,", "nl": 0 },
            { "t": "each one singing", "nl": 0 },
            { "t": "his", "nl": 0 },
            { "t": "as it should be", "nl": 0 },
            { "t": "blithe and strong,", "nl": 1 },
            { "t": "The carpenter", "nl": 0 },
            { "t": "singing", "nl": 0 },
            { "t": "his", "nl": 0 },
            { "t": "as he measures", "nl": 0 },
            { "t": "his", "nl": 0 },
            { "t": "plank or beam,", "nl": 1 },
            { "t": "The mason", "nl": 0 },
            { "t": "singing", "nl": 0 },
            { "t": "his", "nl": 0 },
            { "t": "as he makes ready", "nl": 0 },
            { "t": "for work,", "nl": 0 },
            { "t": "or leaves off", "nl": 0 },
            { "t": "work,", "nl": 1 },
            { "t": "The boatman", "nl": 0 },
            { "t": "singing", "nl": 0 },
            { "t": "what belongs to him", "nl": 0 },
            { "t": "in his boat,", "nl": 0 },
            { "t": "the deckhand", "nl": 0 },
            { "t": "singing", "nl": 0 },
            { "t": "on", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "steamboat deck,", "nl": 1 },
            { "t": "The shoemaker", "nl": 0 },
            { "t": "singing", "nl": 0 },
            { "t": "as he sits", "nl": 0 },
            { "t": "on", "nl": 0 },
            { "t": "his", "nl": 0 },
            { "t": "bench,", "nl": 0 },
            { "t": "the hatter", "nl": 0 },
            { "t": "singing", "nl": 0 },
            { "t": "as he stands,", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "wood-cutter’s song,", "nl": 0 },
            { "t": "the ploughboy’s", "nl": 0 },
            { "t": "on his way", "nl": 0 },
            { "t": "in the morning,", "nl": 0 },
            { "t": "or", "nl": 0 },
            { "t": "at noon intermission", "nl": 0 },
            { "t": "or", "nl": 0 },
            { "t": "at", "nl": 0 },
            { "t": "sundown,", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "delicious singing", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "mother,", "nl": 0 },
            { "t": "or", "nl": 0 },
            { "t": "of the young", "nl": 0 },
            { "t": "wife", "nl": 0 },
            { "t": "at work,", "nl": 0 },
            { "t": "or", "nl": 0 },
            { "t": "of the", "nl": 0 },
            { "t": "girl", "nl": 0 },
            { "t": "sewing or washing,", "nl": 1 },
            { "t": "Each", "nl": 0 },
            { "t": "singing", "nl": 0 },
            { "t": "what belongs", "nl": 0 },
            { "t": "to", "nl": 0 },
            { "t": "him or her", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "to", "nl": 0 },
            { "t": "none else,", "nl": 1 },
            { "t": "The day", "nl": 0 },
            { "t": "what", "nl": 0 },
            { "t": "belongs to the day—", "nl": 0 },
            { "t": "at night", "nl": 0 },
            { "t": "the party", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "young fellows,", "nl": 0 },
            { "t": "robust,", "nl": 0 },
            { "t": "friendly,", "nl": 1 },
            { "t": "Singing", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "open mouths", "nl": 0 },
            { "t": "their", "nl": 0 },
            { "t": "strong melodious songs.", "nl": 2 },
        ],
    },
    {
        "id": 40,
        "author": "Walt Whitman",
        "author_img": "walt_whitman.jpg",
        "title": "One Hour to Madness and Joy",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/1322",
        "lines": [
            { "t": "One hour", "nl": 0 },
            { "t": "to", "nl": 0 },
            { "t": "madness and joy!", "nl": 0 },
            { "t": "O furious!", "nl": 0 },
            { "t": "O", "nl": 0 },
            { "t": "confine me not!", "nl": 1 },
            { "t": "(What is", "nl": 0 },
            { "t": "this", "nl": 0 },
            { "t": "that", "nl": 0 },
            { "t": "frees me so", "nl": 0 },
            { "t": "in storms?", "nl": 1 },
            { "t": "What", "nl": 0 },
            { "t": "do", "nl": 0 },
            { "t": "my shouts", "nl": 0 },
            { "t": "amid lightnings and raging winds", "nl": 0 },
            { "t": "mean?)", "nl": 1 },
            { "t": "O", "nl": 0 },
            { "t": "to drink", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "mystic deliria", "nl": 0 },
            { "t": "deeper", "nl": 0 },
            { "t": "than", "nl": 0 },
            { "t": "any other man!", "nl": 1 },
            { "t": "O", "nl": 0 },
            { "t": "savage", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "tender achings!", "nl": 0 },
            { "t": "(I bequeath them", "nl": 0 },
            { "t": "to you", "nl": 0 },
            { "t": "my children,", "nl": 1 },
            { "t": "I", "nl": 0 },
            { "t": "tell", "nl": 0 },
            { "t": "them", "nl": 0 },
            { "t": "to you,", "nl": 0 },
            { "t": "for reasons,", "nl": 0 },
            { "t": "O", "nl": 0 },
            { "t": "bridegroom and bride.)", "nl": 2 },
            { "t": "O", "nl": 0 },
            { "t": "to be yielded", "nl": 0 },
            { "t": "to you", "nl": 0 },
            { "t": "whoever you are,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "you", "nl": 0 },
            { "t": "to be yielded to me", "nl": 1 },
            { "t": "in defiance", "nl": 0 },
            { "t": "of the world!", "nl": 1 },
            { "t": "O", "nl": 0 },
            { "t": "to return to Paradise!", "nl": 0 },
            { "t": "O bashful", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "feminine!", "nl": 1 },
            { "t": "O", "nl": 0 },
            { "t": "to", "nl": 0 },
            { "t": "draw you to me,", "nl": 0 },
            { "t": "to", "nl": 0 },
            { "t": "plant on you", "nl": 0 },
            { "t": "for the first time", "nl": 0 },
            { "t": "the lips of", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "determin’d man.", "nl": 2 },
            { "t": "O", "nl": 0 },
            { "t": "the puzzle,", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "thrice-tied knot,", "nl": 0 },
            { "t": "the deep and dark pool,", "nl": 0 },
            { "t": "all", "nl": 0 },
            { "t": "untied", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "illumin’d!", "nl": 1 },
            { "t": "O", "nl": 0 },
            { "t": "to speed", "nl": 0 },
            { "t": "where", "nl": 0 },
            { "t": "there is space enough", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "air enough", "nl": 0 },
            { "t": "at last!", "nl": 1 },
            { "t": "To be", "nl": 0 },
            { "t": "absolv’d", "nl": 0 },
            { "t": "from", "nl": 0 },
            { "t": "previous ties", "nl": 0 },
            { "t": "and conventions,", "nl": 0 },
            { "t": "I", "nl": 0 },
            { "t": "from mine", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "you", "nl": 0 },
            { "t": "from yours!", "nl": 1 },
            { "t": "To", "nl": 0 },
            { "t": "find", "nl": 0 },
            { "t": "a new unthought-", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "nonchalance", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "the best of Nature!", "nl": 1 },
            { "t": "To have the gag remov’d", "nl": 0 },
            { "t": "from one’s mouth!", "nl": 1 },
            { "t": "To have", "nl": 0 },
            { "t": "the feeling", "nl": 0 },
            { "t": "to-day", "nl": 0 },
            { "t": "or any day", "nl": 0 },
            { "t": "I am", "nl": 0 },
            { "t": "sufficient", "nl": 0 },
            { "t": "as I am.", "nl": 2 },
            { "t": "O", "nl": 0 },
            { "t": "something unprov’d!", "nl": 0 },
            { "t": "something", "nl": 0 },
            { "t": "in a trance!", "nl": 1 },
            { "t": "To", "nl": 0 },
            { "t": "escape utterly", "nl": 0 },
            { "t": "from", "nl": 0 },
            { "t": "others’ anchors and holds!", "nl": 1 },
            { "t": "To", "nl": 0 },
            { "t": "drive free!", "nl": 0 },
            { "t": "to", "nl": 0 },
            { "t": "love free!", "nl": 0 },
            { "t": "to", "nl": 0 },
            { "t": "dash reckless", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "dangerous!", "nl": 1 },
            { "t": "To", "nl": 0 },
            { "t": "court destruction", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "taunts,", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "invitations!", "nl": 1 },
            { "t": "To", "nl": 0 },
            { "t": "ascend,", "nl": 0 },
            { "t": "to", "nl": 0 },
            { "t": "leap to the heavens", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "the love", "nl": 0 },
            { "t": "indicated to me!", "nl": 1 },
            { "t": "To", "nl": 0 },
            { "t": "rise thither", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "my", "nl": 0 },
            { "t": "inebriate soul!", "nl": 1 },
            { "t": "To", "nl": 0 },
            { "t": "be lost", "nl": 0 },
            { "t": "if it must be so!", "nl": 1 },
            { "t": "To", "nl": 0 },
            { "t": "feed", "nl": 0 },
            { "t": "the remainder of life", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "one hour", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "fulness and freedom!", "nl": 1 },
            { "t": "With", "nl": 0 },
            { "t": "one brief hour", "nl": 0 },
            { "t": "of madness", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "joy.", "nl": 2 },
        ],
    },
    {
        "id": 41,
        "author": "Walt Whitman",
        "author_img": "walt_whitman.jpg",
        "title": "Vigil Strange I Kept on the Field One Night",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/1322",
        "lines": [
            { "t": "Vigil", "nl": 0 },
            { "t": "strange", "nl": 0 },
            { "t": "I kept", "nl": 0 },
            { "t": "on the field", "nl": 0 },
            { "t": "one night;", "nl": 1 },
            { "t": "When you", "nl": 0 },
            { "t": "my son", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "my comrade", "nl": 0 },
            { "t": "dropt", "nl": 0 },
            { "t": "at my side", "nl": 0 },
            { "t": "that day,", "nl": 1 },
            { "t": "One look", "nl": 0 },
            { "t": "I but gave", "nl": 0 },
            { "t": "which", "nl": 0 },
            { "t": "your dear eyes", "nl": 0 },
            { "t": "return’d", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "a look", "nl": 0 },
            { "t": "I shall never forget,", "nl": 2 },
            { "t": "One touch", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "your hand", "nl": 0 },
            { "t": "to mine", "nl": 0 },
            { "t": "O boy,", "nl": 0 },
            { "t": "reach’d up", "nl": 0 },
            { "t": "as you lay", "nl": 0 },
            { "t": "on the ground,", "nl": 1 },
            { "t": "Then onward", "nl": 0 },
            { "t": "I sped", "nl": 0 },
            { "t": "in the battle,", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "even-contested battle,", "nl": 1 },
            { "t": "Till late in the night", "nl": 0 },
            { "t": "reliev’d", "nl": 0 },
            { "t": "to the place", "nl": 0 },
            { "t": "at last again", "nl": 0 },
            { "t": "I made my way,", "nl": 1 },
            { "t": "Found you", "nl": 0 },
            { "t": "in death", "nl": 0 },
            { "t": "so cold", "nl": 0 },
            { "t": "dear comrade,", "nl": 0 },
            { "t": "found your body", "nl": 0 },
            { "t": "son", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "responding kisses,", "nl": 0 },
            { "t": "(never again", "nl": 0 },
            { "t": "on earth", "nl": 0 },
            { "t": "responding,)", "nl": 2 },
            { "t": "Bared your face", "nl": 0 },
            { "t": "in the starlight,", "nl": 0 },
            { "t": "curious the scene,", "nl": 0 },
            { "t": "cool blew", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "moderate night-wind,", "nl": 2 },
            { "t": "Long", "nl": 0 },
            { "t": "there and then", "nl": 0 },
            { "t": "in vigil I stood,", "nl": 0 },
            { "t": "dimly around me", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "battlefield spreading,", "nl": 2 },
            { "t": "Vigil wondrous", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "vigil sweet", "nl": 0 },
            { "t": "there", "nl": 0 },
            { "t": "in the fragrant silent night,", "nl": 1 },
            { "t": "But", "nl": 0 },
            { "t": "not a tear fell,", "nl": 0 },
            { "t": "not even", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "long-drawn sigh,", "nl": 0 },
            { "t": "long, long I gazed,", "nl": 1 },
            { "t": "Then", "nl": 0 },
            { "t": "on the earth", "nl": 0 },
            { "t": "partially reclining", "nl": 0 },
            { "t": "sat by your side", "nl": 0 },
            { "t": "leaning", "nl": 0 },
            { "t": "my chin in my hands,", "nl": 2 },
            { "t": "Passing sweet hours,", "nl": 0 },
            { "t": "immortal and mystic hours", "nl": 0 },
            { "t": "with you", "nl": 0 },
            { "t": "dearest comrade—", "nl": 0 },
            { "t": "not a tear,", "nl": 0 },
            { "t": "not a word,", "nl": 2 },
            { "t": "Vigil of silence,", "nl": 0 },
            { "t": "love and death,", "nl": 0 },
            { "t": "vigil", "nl": 0 },
            { "t": "for you", "nl": 0 },
            { "t": "my son", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "my soldier,", "nl": 1 },
            { "t": "As onward", "nl": 0 },
            { "t": "silently stars aloft,", "nl": 0 },
            { "t": "eastward new ones", "nl": 0 },
            { "t": "upward stole,", "nl": 1 },
            { "t": "Vigil final", "nl": 0 },
            { "t": "for you", "nl": 0 },
            { "t": "brave boy,", "nl": 0 },
            { "t": "(I could not", "nl": 0 },
            { "t": "save you,", "nl": 0 },
            { "t": "swift was your death,", "nl": 1 },
            { "t": "I", "nl": 0 },
            { "t": "faithfully loved you", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "cared for you", "nl": 0 },
            { "t": "living,", "nl": 0 },
            { "t": "I think", "nl": 0 },
            { "t": "we shall surely meet again,)", "nl": 2 },
            { "t": "Till", "nl": 0 },
            { "t": "at latest lingering", "nl": 0 },
            { "t": "of the night,", "nl": 0 },
            { "t": "indeed", "nl": 0 },
            { "t": "just as", "nl": 0 },
            { "t": "the dawn appear’d,", "nl": 1 },
            { "t": "My comrade", "nl": 0 },
            { "t": "I wrapt", "nl": 0 },
            { "t": "in his blanket,", "nl": 0 },
            { "t": "envelop’d well", "nl": 0 },
            { "t": "his form,", "nl": 1 },
            { "t": "Folded", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "blanket well,", "nl": 0 },
            { "t": "tucking it", "nl": 0 },
            { "t": "carefully over head", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "carefully under feet,", "nl": 2 },
            { "t": "And", "nl": 0 },
            { "t": "there and then", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "bathed by the rising sun,", "nl": 0 },
            { "t": "my son", "nl": 0 },
            { "t": "in his grave,", "nl": 0 },
            { "t": "in his", "nl": 0 },
            { "t": "rude-dug grave", "nl": 0 },
            { "t": "I deposited,", "nl": 1 },
            { "t": "Ending", "nl": 0 },
            { "t": "my vigil strange", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "that,", "nl": 0 },
            { "t": "vigil of night", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "battle-field dim,", "nl": 1 },
            { "t": "Vigil for boy", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "responding kisses,", "nl": 0 },
            { "t": "(never again", "nl": 0 },
            { "t": "on earth", "nl": 0 },
            { "t": "responding,)", "nl": 1 },
            { "t": "Vigil", "nl": 0 },
            { "t": "for", "nl": 0 },
            { "t": "comrade swiftly slain,", "nl": 0 },
            { "t": "vigil", "nl": 0 },
            { "t": "I never forget,", "nl": 0 },
            { "t": "how as daybrighten’d,", "nl": 2 },
            { "t": "I rose", "nl": 0 },
            { "t": "from", "nl": 0 },
            { "t": "the chill ground", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "folded", "nl": 0 },
            { "t": "my soldier", "nl": 0 },
            { "t": "well", "nl": 0 },
            { "t": "in his blanket,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "buried him", "nl": 0 },
            { "t": "where he fell.", "nl": 2 },
        ],
    },
    {
        "id": 42,
        "author": "T.S. Eliot",
        "author_img": "t_s_elliot.jpg",
        "title": "The Waste Land",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/1321",
        "lines": [
            { "t": "April", "nl": 0 },
            { "t": "is", "nl": 0 },
            { "t": "the cruellest month,", "nl": 0 },
            { "t": "breeding", "nl": 1 },
            { "t": "Lilacs", "nl": 0 },
            { "t": "out of the dead land,", "nl": 0 },
            { "t": "mixing", "nl": 1 },
            { "t": "Memory and desire,", "nl": 0 },
            { "t": "stirring", "nl": 1 },
            { "t": "Dull roots", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "spring rain.", "nl": 1 },
            { "t": "Winter", "nl": 0 },
            { "t": "kept us warm,", "nl": 0 },
            { "t": "covering", "nl": 1 },
            { "t": "Earth", "nl": 0 },
            { "t": "in", "nl": 0 },
            { "t": "forgetful snow,", "nl": 0 },
            { "t": "feeding", "nl": 1 },
            { "t": "A little life", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "dried tubers.", "nl": 1 },
            { "t": "Summer surprised us,", "nl": 0 },
            { "t": "coming over", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "Starnbergersee", "nl": 1 },
            { "t": "With", "nl": 0 },
            { "t": "a shower of rain;", "nl": 0 },
            { "t": "we stopped", "nl": 0 },
            { "t": "in the colonnade,", "nl": 1 },
            { "t": "And went on in sunlight,", "nl": 0 },
            { "t": "into the Hofgarten,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "drank coffee,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "talked for an hour.", "nl": 1 },
            { "t": "Bin gar keine Russin,", "nl": 0 },
            { "t": "stamm’", "nl": 0 },
            { "t": "aus Litauen,", "nl": 0 },
            { "t": "echt deutsch.", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "when we were children,", "nl": 0 },
            { "t": "staying", "nl": 0 },
            { "t": "at", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "archduke’s,", "nl": 1 },
            { "t": "My cousin’s,", "nl": 0 },
            { "t": "he", "nl": 0 },
            { "t": "took me out", "nl": 0 },
            { "t": "on a sled,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "I was frightened.", "nl": 0 },
            { "t": "He said,", "nl": 0 },
            { "t": "Marie,", "nl": 1 },
            { "t": "Marie,", "nl": 0 },
            { "t": "hold on tight.", "nl": 0 },
            { "t": "And", "nl": 0 },
            { "t": "down we went.", "nl": 1 },
            { "t": "In the mountains,", "nl": 0 },
            { "t": "there", "nl": 0 },
            { "t": "you feel free.", "nl": 1 },
            { "t": "I read,", "nl": 0 },
            { "t": "much of the night,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "go south in the winter.", "nl": 2 },
            { "t": "What are", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "roots", "nl": 0 },
            { "t": "that clutch,", "nl": 0 },
            { "t": "what", "nl": 0 },
            { "t": "branches grow", "nl": 1 },
            { "t": "Out of", "nl": 0 },
            { "t": "this stony rubbish?", "nl": 0 },
            { "t": "Son of man,", "nl": 1 },
            { "t": "You cannot say,", "nl": 0 },
            { "t": "or guess,", "nl": 0 },
            { "t": "for", "nl": 0 },
            { "t": "you know only", "nl": 1 },
            { "t": "A heap", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "broken images,", "nl": 0 },
            { "t": "where", "nl": 0 },
            { "t": "the sun", "nl": 0 },
            { "t": "beats,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "the dead tree", "nl": 0 },
            { "t": "gives no shelter,", "nl": 0 },
            { "t": "the cricket", "nl": 0 },
            { "t": "no relief,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "the dry stone", "nl": 0 },
            { "t": "no sound of water.", "nl": 0 },
            { "t": "Only", "nl": 1 },
            { "t": "There is shadow", "nl": 0 },
            { "t": "under this red rock,", "nl": 1 },
            { "t": "(Come in", "nl": 0 },
            { "t": "under the shadow", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "this", "nl": 0 },
            { "t": "red rock),", "nl": 1 },
            { "t": "And I", "nl": 0 },
            { "t": "will show you", "nl": 0 },
            { "t": "something different", "nl": 0 },
            { "t": "from either", "nl": 0 },
            { "t": "Your shadow", "nl": 0 },
            { "t": "at morning", "nl": 0 },
            { "t": "striding behind you", "nl": 1 },
            { "t": "Or", "nl": 0 },
            { "t": "your shadow", "nl": 0 },
            { "t": "at evening", "nl": 0 },
            { "t": "rising to meet you;", "nl": 1 },
            { "t": "I will show you", "nl": 0 },
            { "t": "fear", "nl": 0 },
            { "t": "in", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "handful of dust.", "nl": 1 },
            { "t": "Frisch weht der Wind", "nl": 1 },
            { "t": "Der Heimat zu", "nl": 1 },
            { "t": "Mein Irisch Kind,", "nl": 1 },
            { "t": "Wo weilest du?", "nl": 1 },
            { "t": "“You gave me", "nl": 0 },
            { "t": "hyacinths", "nl": 0 },
            { "t": "first", "nl": 0 },
            { "t": "a year ago;", "nl": 1 },
            { "t": "“They called me", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "hyacinth", "nl": 0 },
            { "t": "girl.”", "nl": 1 },
            { "t": "—Yet", "nl": 0 },
            { "t": "when we came back,", "nl": 0 },
            { "t": "late,", "nl": 0 },
            { "t": "from", "nl": 0 },
            { "t": "the Hyacinth garden,", "nl": 1 },
            { "t": "Your arms full,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "your hair wet,", "nl": 0 },
            { "t": "I could not", "nl": 1 },
            { "t": "Speak,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "my eyes failed,", "nl": 0 },
            { "t": "I was neither", "nl": 1 },
            { "t": "Living", "nl": 0 },
            { "t": "nor", "nl": 0 },
            { "t": "dead,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "I knew nothing,", "nl": 1 },
            { "t": "Looking", "nl": 0 },
            { "t": "into the heart of light,", "nl": 0 },
            { "t": "the silence.", "nl": 1 },
            { "t": "Oed’", "nl": 0 },
            { "t": "und leer", "nl": 0 },
            { "t": "das Meer.", "nl": 2 },
            { "t": "Madame Sosostris,", "nl": 0 },
            { "t": "famous clairvoyante,", "nl": 1 },
            { "t": "Had", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "bad cold,", "nl": 0 },
            { "t": "nevertheless", "nl": 1 },
            { "t": "Is known to be", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "wisest woman", "nl": 0 },
            { "t": "in", "nl": 0 },
            { "t": "Europe,", "nl": 1 },
            { "t": "With", "nl": 0 },
            { "t": "a wicked pack of cards.", "nl": 0 },
            { "t": "Here,", "nl": 0 },
            { "t": "said she,", "nl": 1 },
            { "t": "Is", "nl": 0 },
            { "t": "your card,", "nl": 0 },
            { "t": "the drowned Phoenician Sailor,", "nl": 1 },
            { "t": "(Those are pearls", "nl": 0 },
            { "t": "that were", "nl": 0 },
            { "t": "his eyes.", "nl": 0 },
            { "t": "Look!)", "nl": 1 },
            { "t": "Here is Belladonna,", "nl": 0 },
            { "t": "the Lady of the Rocks,", "nl": 1 },
            { "t": "The lady", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "situations.", "nl": 1 },
            { "t": "Here is", "nl": 0 },
            { "t": "the man", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "three staves,", "nl": 0 },
            { "t": "and here", "nl": 0 },
            { "t": "the Wheel,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "here is", "nl": 0 },
            { "t": "the one-eyed merchant,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "this card,", "nl": 1 },
            { "t": "Which", "nl": 0 },
            { "t": "is", "nl": 0 },
            { "t": "blank,", "nl": 0 },
            { "t": "is something", "nl": 0 },
            { "t": "he", "nl": 0 },
            { "t": "carries on his back,", "nl": 1 },
            { "t": "Which", "nl": 0 },
            { "t": "I am", "nl": 0 },
            { "t": "forbidden to see.", "nl": 0 },
            { "t": "I do not find", "nl": 1 },
            { "t": "The Hanged Man.", "nl": 0 },
            { "t": "Fear death", "nl": 0 },
            { "t": "by", "nl": 0 },
            { "t": "water.", "nl": 1 },
            { "t": "I see crowds of people,", "nl": 0 },
            { "t": "walking round", "nl": 0 },
            { "t": "in", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "ring.", "nl": 1 },
            { "t": "Thank you.", "nl": 0 },
            { "t": "If you see", "nl": 0 },
            { "t": "dear Mrs. Equitone,", "nl": 1 },
            { "t": "Tell", "nl": 0 },
            { "t": "her", "nl": 0 },
            { "t": "I bring", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "horoscope myself:", "nl": 1 },
            { "t": "One must be", "nl": 0 },
            { "t": "so careful", "nl": 0 },
            { "t": "these days.", "nl": 2 },
            { "t": "Unreal City,", "nl": 1 },
            { "t": "Under", "nl": 0 },
            { "t": "the brown fog", "nl": 0 },
            { "t": "of a winter dawn,", "nl": 1 },
            { "t": "A crowd flowed", "nl": 0 },
            { "t": "over London Bridge,", "nl": 0 },
            { "t": "so many,", "nl": 1 },
            { "t": "I", "nl": 0 },
            { "t": "had not thought", "nl": 0 },
            { "t": "death", "nl": 0 },
            { "t": "had undone", "nl": 0 },
            { "t": "so many.", "nl": 1 },
            { "t": "Sighs,", "nl": 0 },
            { "t": "short and infrequent,", "nl": 0 },
            { "t": "were exhaled,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "each man", "nl": 0 },
            { "t": "fixed his eyes", "nl": 0 },
            { "t": "before his feet.", "nl": 1 },
            { "t": "Flowed", "nl": 0 },
            { "t": "up the hill", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "down", "nl": 0 },
            { "t": "King William Street,", "nl": 1 },
            { "t": "To", "nl": 0 },
            { "t": "where", "nl": 0 },
            { "t": "Saint Mary Woolnoth", "nl": 0 },
            { "t": "kept the hours", "nl": 1 },
            { "t": "With", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "dead sound", "nl": 0 },
            { "t": "on the", "nl": 0 },
            { "t": "final stroke of nine.", "nl": 1 },
            { "t": "There", "nl": 0 },
            { "t": "I saw one I knew,", "nl": 0 },
            { "t": "and stopped him,", "nl": 0 },
            { "t": "crying “Stetson!", "nl": 1 },
            { "t": "“You", "nl": 0 },
            { "t": "who were with me", "nl": 0 },
            { "t": "in the ships", "nl": 0 },
            { "t": "at Mylae!", "nl": 1 },
            { "t": "“That corpse", "nl": 0 },
            { "t": "you", "nl": 0 },
            { "t": "planted", "nl": 0 },
            { "t": "last year", "nl": 0 },
            { "t": "in your garden,", "nl": 1 },
            { "t": "“Has it begun", "nl": 0 },
            { "t": "to", "nl": 0 },
            { "t": "sprout?", "nl": 0 },
            { "t": "Will it bloom", "nl": 0 },
            { "t": "this year?", "nl": 1 },
            { "t": "“Or", "nl": 0 },
            { "t": "has", "nl": 0 },
            { "t": "the sudden frost", "nl": 0 },
            { "t": "disturbed", "nl": 0 },
            { "t": "its bed?", "nl": 1 },
            { "t": "“Oh", "nl": 0 },
            { "t": "keep the Dog far", "nl": 0 },
            { "t": "hence,", "nl": 0 },
            { "t": "that’s", "nl": 0 },
            { "t": "friend to men,", "nl": 1 },
            { "t": "“Or", "nl": 0 },
            { "t": "with his nails", "nl": 0 },
            { "t": "he’ll", "nl": 0 },
            { "t": "dig it up again!", "nl": 1 },
            { "t": "“You!", "nl": 0 },
            { "t": "hypocrite", "nl": 0 },
            { "t": "lecteur!—mon", "nl": 0 },
            { "t": "semblable,—mon", "nl": 0 },
            { "t": "frère!”", "nl": 2 },
        ],
    },
    {
        "id": 43,
        "author": "Anonymous",
        "author_img": "anonymous.jpg",
        "title": "Sir Gawayne and the Green Knight",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/14568",
        "lines": [
            { "t": "This king", "nl": 0 },
            { "t": "lay", "nl": 0 },
            { "t": "at", "nl": 0 },
            { "t": "Camelot", "nl": 0 },
            { "t": "nigh on Christmas", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "many lovely lords,", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "leaders", "nl": 0 },
            { "t": "the best,", "nl": 0 },
            { "t": "reckoning", "nl": 0 },
            { "t": "of the", "nl": 0 },
            { "t": "Round Table", "nl": 0 },
            { "t": "all the rich brethren,", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "right ripe revel", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "reckless mirth.", "nl": 2 },
            { "t": "There", "nl": 0 },
            { "t": "tourneyed tykes", "nl": 0 },
            { "t": "by times", "nl": 0 },
            { "t": "full many,", "nl": 0 },
            { "t": "jousted", "nl": 0 },
            { "t": "full jollily", "nl": 0 },
            { "t": "these", "nl": 0 },
            { "t": "gentle knights,", "nl": 0 },
            { "t": "then", "nl": 0 },
            { "t": "carried to court,", "nl": 0 },
            { "t": "their", "nl": 0 },
            { "t": "carols to make.", "nl": 2 },
            { "t": "For", "nl": 0 },
            { "t": "there", "nl": 0 },
            { "t": "the feast", "nl": 0 },
            { "t": "was alike", "nl": 0 },
            { "t": "full fifteen days,", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "all the meat", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "mirth", "nl": 0 },
            { "t": "men could devise:", "nl": 0 },
            { "t": "such clamour and glee", "nl": 0 },
            { "t": "glorious to hear,", "nl": 0 },
            { "t": "dear din", "nl": 0 },
            { "t": "in", "nl": 0 },
            { "t": "the daylight,", "nl": 0 },
            { "t": "dancing of nights;", "nl": 0 },
            { "t": "all was happiness", "nl": 0 },
            { "t": "high in halls", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "chambers", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "lords and ladies,", "nl": 0 },
            { "t": "as", "nl": 0 },
            { "t": "liked them all best.", "nl": 2 },
            { "t": "With", "nl": 0 },
            { "t": "all that’s well", "nl": 0 },
            { "t": "in the world", "nl": 0 },
            { "t": "were", "nl": 0 },
            { "t": "they together,", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "knights best known", "nl": 0 },
            { "t": "under", "nl": 0 },
            { "t": "the Christ", "nl": 0 },
            { "t": "Himself,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "the loveliest ladies", "nl": 0 },
            { "t": "that", "nl": 0 },
            { "t": "ever life honoured,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "he", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "comeliest king", "nl": 0 },
            { "t": "that", "nl": 0 },
            { "t": "the court", "nl": 0 },
            { "t": "rules.", "nl": 2 },
            { "t": "For", "nl": 0 },
            { "t": "all were", "nl": 0 },
            { "t": "fair folk", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "in their first age", "nl": 0 },
            { "t": "still,", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "happiest under heaven,", "nl": 0 },
            { "t": "king noblest", "nl": 0 },
            { "t": "in his will;", "nl": 0 },
            { "t": "that it", "nl": 0 },
            { "t": "were", "nl": 0 },
            { "t": "hard to reckon", "nl": 0 },
            { "t": "so", "nl": 0 },
            { "t": "hardy a host", "nl": 0 },
            { "t": "on hill.", "nl": 2 },
            { "t": "While", "nl": 0 },
            { "t": "New Year", "nl": 0 },
            { "t": "was", "nl": 0 },
            { "t": "so young", "nl": 0 },
            { "t": "it", "nl": 0 },
            { "t": "was", "nl": 0 },
            { "t": "new come in,", "nl": 0 },
            { "t": "that day", "nl": 0 },
            { "t": "double", "nl": 0 },
            { "t": "on the dais", "nl": 0 },
            { "t": "was the dole served,", "nl": 0 },
            { "t": "for", "nl": 0 },
            { "t": "the king", "nl": 0 },
            { "t": "was come", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "knights", "nl": 0 },
            { "t": "into the hall,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "chanting", "nl": 0 },
            { "t": "in the chapel", "nl": 0 },
            { "t": "had", "nl": 0 },
            { "t": "chimed to an end.", "nl": 2 },
            { "t": "Loud cry", "nl": 0 },
            { "t": "was there cast", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "clerics and others,", "nl": 0 },
            { "t": "Noel nurtured anew,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "named full oft;", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "see the rich", "nl": 0 },
            { "t": "run forth", "nl": 0 },
            { "t": "to", "nl": 0 },
            { "t": "render presents,", "nl": 0 },
            { "t": "yelled", "nl": 0 },
            { "t": "their gifts", "nl": 0 },
            { "t": "on high,", "nl": 0 },
            { "t": "yield them to hand,", "nl": 0 },
            { "t": "argued busily", "nl": 0 },
            { "t": "about", "nl": 0 },
            { "t": "those same gifts.", "nl": 2 },
            { "t": "Ladies laughed", "nl": 0 },
            { "t": "out loud,", "nl": 0 },
            { "t": "though", "nl": 0 },
            { "t": "they had lost,", "nl": 0 },
            { "t": "while", "nl": 0 },
            { "t": "he that won", "nl": 0 },
            { "t": "was", "nl": 0 },
            { "t": "not wrath,", "nl": 0 },
            { "t": "that you’ll know.", "nl": 2 },
            { "t": "All", "nl": 0 },
            { "t": "this mirth", "nl": 0 },
            { "t": "they made", "nl": 0 },
            { "t": "at the", "nl": 0 },
            { "t": "meal time.", "nl": 0 },
            { "t": "When", "nl": 0 },
            { "t": "they had", "nl": 0 },
            { "t": "washed well", "nl": 0 },
            { "t": "they went", "nl": 0 },
            { "t": "to", "nl": 0 },
            { "t": "be seated,", "nl": 0 },
            { "t": "the best", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "barons above,", "nl": 0 },
            { "t": "as", "nl": 0 },
            { "t": "it seemed best;", "nl": 0 },
            { "t": "with Guinevere,", "nl": 0 },
            { "t": "full gaily,", "nl": 0 },
            { "t": "gracing their midst,", "nl": 0 },
            { "t": "dressed", "nl": 0 },
            { "t": "on the dais there,", "nl": 0 },
            { "t": "adorned all about –", "nl": 0 },
            { "t": "splendid silk", "nl": 0 },
            { "t": "by", "nl": 0 },
            { "t": "her sides,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "sheer", "nl": 0 },
            { "t": "above of true Toulouse,", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "Tartar tapestries", "nl": 0 },
            { "t": "plenty,", "nl": 0 },
            { "t": "that were", "nl": 0 },
            { "t": "embroidered,", "nl": 0 },
            { "t": "bright with the best gems", "nl": 0 },
            { "t": "that", "nl": 0 },
            { "t": "might be", "nl": 0 },
            { "t": "price-proved", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "pennies", "nl": 0 },
            { "t": "any a day.", "nl": 0 },
            { "t": "The", "nl": 0 },
            { "t": "comeliest", "nl": 0 },
            { "t": "to", "nl": 0 },
            { "t": "descry", "nl": 0 },
            { "t": "glanced there", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "eyen grey;", "nl": 0 },
            { "t": "a seemlier", "nl": 0 },
            { "t": "ever to the sight,", "nl": 0 },
            { "t": "sooth", "nl": 0 },
            { "t": "might no man say.", "nl": 2 },
        ],
    },
    {
        "id": 44,
        "author": "Christina Rossetti",
        "author_img": "christina_rossetti.jpg",
        "title": "Spring",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/19188",
        "lines": [
            { "t": "Frost-locked", "nl": 0 },
            { "t": "all", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "winter,", "nl": 1 },
            { "t": "Seeds,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "roots,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "stones of fruits,", "nl": 1 },
            { "t": "What shall make", "nl": 0 },
            { "t": "their sap", "nl": 0 },
            { "t": "ascend", "nl": 1 },
            { "t": "That", "nl": 0 },
            { "t": "they may put forth", "nl": 0 },
            { "t": "shoots?", "nl": 1 },
            { "t": "Tips", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "tender green,", "nl": 1 },
            { "t": "Leaf,", "nl": 0 },
            { "t": "or", "nl": 0 },
            { "t": "blade,", "nl": 0 },
            { "t": "or", "nl": 0 },
            { "t": "sheath;", "nl": 1 },
            { "t": "Telling", "nl": 0 },
            { "t": "of the", "nl": 0 },
            { "t": "hidden life", "nl": 1 },
            { "t": "That", "nl": 0 },
            { "t": "breaks forth", "nl": 0 },
            { "t": "underneath,", "nl": 1 },
            { "t": "Life nursed", "nl": 0 },
            { "t": "in its grave", "nl": 0 },
            { "t": "by Death.", "nl": 1 },
            { "t": "Blows", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "thaw-wind", "nl": 0 },
            { "t": "pleasantly,", "nl": 1 },
            { "t": "Drips", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "soaking rain,", "nl": 1 },
            { "t": "By fits", "nl": 0 },
            { "t": "looks down", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "waking sun:", "nl": 1 },
            { "t": "Young grass", "nl": 0 },
            { "t": "springs", "nl": 0 },
            { "t": "on", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "plain;", "nl": 1 },
            { "t": "Young leaves", "nl": 0 },
            { "t": "clothe", "nl": 0 },
            { "t": "early hedgerow", "nl": 0 },
            { "t": "trees;", "nl": 1 },
            { "t": "Seeds,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "roots,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "stones of fruits,", "nl": 1 },
            { "t": "Swollen with sap,", "nl": 0 },
            { "t": "put forth", "nl": 0 },
            { "t": "their shoots;", "nl": 1 },
            { "t": "Curled-headed", "nl": 0 },
            { "t": "ferns sprout", "nl": 0 },
            { "t": "in the", "nl": 0 },
            { "t": "lane;", "nl": 1 },
            { "t": "Birds sing", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "pair again.", "nl": 2 },
            { "t": "There is", "nl": 0 },
            { "t": "no time", "nl": 0 },
            { "t": "like", "nl": 0 },
            { "t": "Spring,", "nl": 1 },
            { "t": "When", "nl": 0 },
            { "t": "life's alive", "nl": 0 },
            { "t": "in everything,", "nl": 1 },
            { "t": "Before", "nl": 0 },
            { "t": "new nestlings", "nl": 0 },
            { "t": "sing,", "nl": 1 },
            { "t": "Before", "nl": 0 },
            { "t": "cleft swallows", "nl": 0 },
            { "t": "speed", "nl": 0 },
            { "t": "their journey back", "nl": 1 },
            { "t": "Along", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "trackless track,--", "nl": 1 },
            { "t": "God", "nl": 0 },
            { "t": "guides", "nl": 0 },
            { "t": "their wing,", "nl": 1 },
            { "t": "He spreads", "nl": 0 },
            { "t": "their table", "nl": 0 },
            { "t": "that", "nl": 0 },
            { "t": "they", "nl": 0 },
            { "t": "nothing lack,--", "nl": 1 },
            { "t": "Before", "nl": 0 },
            { "t": "the daisy", "nl": 0 },
            { "t": "grows", "nl": 0 },
            { "t": "a common flower,", "nl": 1 },
            { "t": "Before", "nl": 0 },
            { "t": "the sun", "nl": 0 },
            { "t": "has power", "nl": 1 },
            { "t": "To scorch", "nl": 0 },
            { "t": "the world", "nl": 0 },
            { "t": "up", "nl": 0 },
            { "t": "in", "nl": 0 },
            { "t": "his", "nl": 0 },
            { "t": "noontide hour.", "nl": 2 },
            { "t": "There is", "nl": 0 },
            { "t": "no time", "nl": 0 },
            { "t": "like Spring,", "nl": 1 },
            { "t": "Like Spring", "nl": 0 },
            { "t": "that passes by;", "nl": 1 },
            { "t": "There is", "nl": 0 },
            { "t": "no life", "nl": 0 },
            { "t": "like", "nl": 0 },
            { "t": "Spring-life", "nl": 0 },
            { "t": "born to die,--", "nl": 1 },
            { "t": "Piercing", "nl": 0 },
            { "t": "the sod,", "nl": 1 },
            { "t": "Clothing", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "uncouth clod,", "nl": 1 },
            { "t": "Hatched", "nl": 0 },
            { "t": "in the nest,", "nl": 1 },
            { "t": "Fledged", "nl": 0 },
            { "t": "on the", "nl": 0 },
            { "t": "windy bough,", "nl": 1 },
            { "t": "Strong", "nl": 0 },
            { "t": "on the wing:", "nl": 1 },
            { "t": "There is", "nl": 0 },
            { "t": "no time", "nl": 0 },
            { "t": "like", "nl": 1 },
            { "t": "Spring", "nl": 0 },
            { "t": "that passes by,", "nl": 1 },
            { "t": "Now", "nl": 0 },
            { "t": "newly born,", "nl": 0 },
            { "t": "and now", "nl": 0 },
            { "t": "Hastening to die.", "nl": 2 },
        ],
    },
    {
        "id": 45,
        "author": "Christina Rossetti",
        "author_img": "christina_rossetti.jpg",
        "title": "Twilight's Calm",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/21189",
        "lines": [
            { "t": "O", "nl": 0 },
            { "t": "pleasant", "nl": 0 },
            { "t": "eventide!", "nl": 1 },
            { "t": "Clouds", "nl": 0 },
            { "t": "on the", "nl": 0 },
            { "t": "western side", "nl": 1 },
            { "t": "Grow gray", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "grayer,", "nl": 0 },
            { "t": "hiding", "nl": 0 },
            { "t": "the warm sun:", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "bees and birds,", "nl": 0 },
            { "t": "their", "nl": 0 },
            { "t": "happy labors", "nl": 0 },
            { "t": "done,", "nl": 0 },
            { "t": "Seek", "nl": 0 },
            { "t": "their close nests", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "bide.", "nl": 1 },
            { "t": "Screened", "nl": 0 },
            { "t": "in the leafy wood", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "stock-doves", "nl": 0 },
            { "t": "sit and brood:", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "very squirrel", "nl": 0 },
            { "t": "leaps", "nl": 0 },
            { "t": "from bough to bough", "nl": 1 },
            { "t": "But lazily;", "nl": 0 },
            { "t": "pauses;", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "settles now", "nl": 1 },
            { "t": "Where", "nl": 0 },
            { "t": "once", "nl": 0 },
            { "t": "he stored", "nl": 0 },
            { "t": "his food.", "nl": 2 },
            { "t": "One by one", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "flowers close,", "nl": 1 },
            { "t": "Lily", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "dewy rose", "nl": 1 },
            { "t": "Shutting", "nl": 0 },
            { "t": "their tender petals", "nl": 0 },
            { "t": "from", "nl": 0 },
            { "t": "the moon:", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "grasshoppers", "nl": 0 },
            { "t": "are still;", "nl": 0 },
            { "t": "but not", "nl": 0 },
            { "t": "so soon", "nl": 1 },
            { "t": "Are still", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "noisy crows.", "nl": 2 },
            { "t": "The", "nl": 0 },
            { "t": "dormouse", "nl": 0 },
            { "t": "squats and eats", "nl": 1 },
            { "t": "Choice", "nl": 0 },
            { "t": "little dainty", "nl": 0 },
            { "t": "bits", "nl": 1 },
            { "t": "Beneath", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "spreading roots", "nl": 0 },
            { "t": "of a", "nl": 0 },
            { "t": "broad lime;", "nl": 1 },
            { "t": "Nibbling his fill", "nl": 0 },
            { "t": "he stops", "nl": 0 },
            { "t": "from time to time", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "listens", "nl": 0 },
            { "t": "where he sits.", "nl": 2 },
            { "t": "From far", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "lowings come", "nl": 1 },
            { "t": "Of", "nl": 0 },
            { "t": "cattle", "nl": 0 },
            { "t": "driven home:", "nl": 1 },
            { "t": "From farther still", "nl": 0 },
            { "t": "the wind", "nl": 0 },
            { "t": "brings fitfully", "nl": 1 },
            { "t": "The vast", "nl": 0 },
            { "t": "continual murmur", "nl": 0 },
            { "t": "of the", "nl": 0 },
            { "t": "sea,", "nl": 1 },
            { "t": "Now loud,", "nl": 0 },
            { "t": "now", "nl": 0 },
            { "t": "almost dumb.", "nl": 2 },
            { "t": "The", "nl": 0 },
            { "t": "gnats", "nl": 0 },
            { "t": "whirl in the air,", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "evening gnats;", "nl": 0 },
            { "t": "and there", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "owl", "nl": 0 },
            { "t": "opes broad", "nl": 0 },
            { "t": "his eyes", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "wings to sail", "nl": 1 },
            { "t": "For prey;", "nl": 0 },
            { "t": "the bat wakes;", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "shell-less snail", "nl": 1 },
            { "t": "Comes forth,", "nl": 0 },
            { "t": "clammy and bare.", "nl": 2 },
            { "t": "Hark!", "nl": 0 },
            { "t": "that's", "nl": 0 },
            { "t": "the nightingale,", "nl": 1 },
            { "t": "Telling", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "self-same tale", "nl": 1 },
            { "t": "Her song", "nl": 0 },
            { "t": "told", "nl": 0 },
            { "t": "when", "nl": 0 },
            { "t": "this ancient earth", "nl": 0 },
            { "t": "was young:", "nl": 1 },
            { "t": "So", "nl": 0 },
            { "t": "echoes answered", "nl": 0 },
            { "t": "when", "nl": 0 },
            { "t": "her song", "nl": 0 },
            { "t": "was sung", "nl": 1 },
            { "t": "In", "nl": 0 },
            { "t": "the first", "nl": 0 },
            { "t": "wooded vale.", "nl": 2 },
            { "t": "We", "nl": 0 },
            { "t": "call it love", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "pain", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "passion", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "her strain;", "nl": 1 },
            { "t": "And yet", "nl": 0 },
            { "t": "we little understand", "nl": 0 },
            { "t": "or", "nl": 0 },
            { "t": "know:", "nl": 1 },
            { "t": "Why should it", "nl": 0 },
            { "t": "not be", "nl": 0 },
            { "t": "rather joy", "nl": 0 },
            { "t": "that so", "nl": 1 },
            { "t": "Throbs", "nl": 0 },
            { "t": "in", "nl": 0 },
            { "t": "each", "nl": 0 },
            { "t": "throbbing vein?", "nl": 2 },
            { "t": "In", "nl": 0 },
            { "t": "separate herds", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "deer", "nl": 1 },
            { "t": "Lie;", "nl": 0 },
            { "t": "here", "nl": 0 },
            { "t": "the bucks,", "nl": 0 },
            { "t": "and here", "nl": 0 },
            { "t": "The does,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "by its mother", "nl": 0 },
            { "t": "sleeps the fawn:", "nl": 1 },
            { "t": "Through", "nl": 0 },
            { "t": "all the hours", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "night", "nl": 0 },
            { "t": "until the dawn", "nl": 1 },
            { "t": "They sleep,", "nl": 0 },
            { "t": "forgetting fear.", "nl": 2 },
            { "t": "The", "nl": 0 },
            { "t": "hare", "nl": 0 },
            { "t": "sleeps", "nl": 0 },
            { "t": "where it lies,", "nl": 1 },
            { "t": "With", "nl": 0 },
            { "t": "wary half-closed eyes;", "nl": 1 },
            { "t": "The cock", "nl": 0 },
            { "t": "has ceased to crow,", "nl": 0 },
            { "t": "the hen", "nl": 0 },
            { "t": "to cluck:", "nl": 1 },
            { "t": "Only", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "fox", "nl": 0 },
            { "t": "is out,", "nl": 0 },
            { "t": "some heedless duck", "nl": 1 },
            { "t": "Or", "nl": 0 },
            { "t": "chicken", "nl": 0 },
            { "t": "to surprise.", "nl": 2 },
            { "t": "Remote,", "nl": 0 },
            { "t": "each single star", "nl": 1 },
            { "t": "Comes out,", "nl": 0 },
            { "t": "till", "nl": 0 },
            { "t": "there they are", "nl": 1 },
            { "t": "All shining brightly:", "nl": 0 },
            { "t": "how", "nl": 0 },
            { "t": "the dews", "nl": 0 },
            { "t": "fall damp!", "nl": 1 },
            { "t": "While", "nl": 0 },
            { "t": "close at hand", "nl": 0 },
            { "t": "the glow-worm", "nl": 0 },
            { "t": "lights her lamp", "nl": 1 },
            { "t": "Or", "nl": 0 },
            { "t": "twinkles from afar.", "nl": 2 },
            { "t": "But", "nl": 0 },
            { "t": "evening", "nl": 0 },
            { "t": "now is done", "nl": 1 },
            { "t": "As much as", "nl": 0 },
            { "t": "if", "nl": 0 },
            { "t": "the sun", "nl": 1 },
            { "t": "Day-giving", "nl": 0 },
            { "t": "had", "nl": 0 },
            { "t": "arisen", "nl": 0 },
            { "t": "in the east:", "nl": 1 },
            { "t": "For", "nl": 0 },
            { "t": "night has come;", "nl": 0 },
            { "t": "and the", "nl": 0 },
            { "t": "great calm", "nl": 0 },
            { "t": "has ceased,", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "quiet sands", "nl": 0 },
            { "t": "have run.", "nl": 2 },
        ],
    },
    {
        "id": 46,
        "author": "Phillis Wheatley",
        "author_img": "phillis_wheatley2.jpg",
        "title": "On Virtue",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/409",
        "lines": [
            { "t": "O", "nl": 0 },
            { "t": "Thou", "nl": 0 },
            { "t": "bright jewel", "nl": 0 },
            { "t": "in", "nl": 0 },
            { "t": "my", "nl": 0 },
            { "t": "aim", "nl": 0 },
            { "t": "I strive", "nl": 1 },
            { "t": "To", "nl": 0 },
            { "t": "comprehend", "nl": 0 },
            { "t": "thee.", "nl": 0 },
            { "t": "Thine", "nl": 0 },
            { "t": "own words", "nl": 0 },
            { "t": "declare", "nl": 1 },
            { "t": "Wisdom", "nl": 0 },
            { "t": "is", "nl": 0 },
            { "t": "higher", "nl": 0 },
            { "t": "than a fool", "nl": 0 },
            { "t": "can reach.", "nl": 1 },
            { "t": "I", "nl": 0 },
            { "t": "cease", "nl": 0 },
            { "t": "to wonder,", "nl": 0 },
            { "t": "and no more", "nl": 0 },
            { "t": "attempt", "nl": 1 },
            { "t": "Thine height", "nl": 0 },
            { "t": "t’ explore,", "nl": 0 },
            { "t": "or fathom", "nl": 0 },
            { "t": "thy profound.", "nl": 1 },
            { "t": "But,", "nl": 0 },
            { "t": "O my soul,", "nl": 0 },
            { "t": "sink not", "nl": 0 },
            { "t": "into", "nl": 0 },
            { "t": "despair,", "nl": 1 },
            { "t": "Virtue", "nl": 0 },
            { "t": "is", "nl": 0 },
            { "t": "near thee,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "gentle hand", "nl": 1 },
            { "t": "Would", "nl": 0 },
            { "t": "no", "nl": 0 },
            { "t": "embrace thee,", "nl": 0 },
            { "t": "hovers o’er", "nl": 0 },
            { "t": "thine head.", "nl": 1 },
            { "t": "Fain", "nl": 0 },
            { "t": "would", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "heav’n-born", "nl": 0 },
            { "t": "soul", "nl": 0 },
            { "t": "with her", "nl": 0 },
            { "t": "converse,", "nl": 1 },
            { "t": "Then", "nl": 0 },
            { "t": "seek,", "nl": 0 },
            { "t": "then", "nl": 0 },
            { "t": "court her", "nl": 0 },
            { "t": "for her", "nl": 0 },
            { "t": "promis’d bliss.", "nl": 1 },
            { "t": "Auspicious", "nl": 0 },
            { "t": "queen,", "nl": 0 },
            { "t": "thine heav’nly", "nl": 0 },
            { "t": "pinions", "nl": 0 },
            { "t": "spread,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "lead", "nl": 0 },
            { "t": "celestial Chastity", "nl": 0 },
            { "t": "along;", "nl": 1 },
            { "t": "Lo!", "nl": 0 },
            { "t": "now", "nl": 0 },
            { "t": "her sacred retinue", "nl": 0 },
            { "t": "descends,", "nl": 1 },
            { "t": "Array’d", "nl": 0 },
            { "t": "in", "nl": 0 },
            { "t": "glory", "nl": 0 },
            { "t": "from", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "orbs above.", "nl": 1 },
            { "t": "Attend me,", "nl": 0 },
            { "t": "Virtue,", "nl": 0 },
            { "t": "thro’", "nl": 0 },
            { "t": "my", "nl": 0 },
            { "t": "youthful years!", "nl": 1 },
            { "t": "O", "nl": 0 },
            { "t": "leave me", "nl": 0 },
            { "t": "not", "nl": 0 },
            { "t": "to the", "nl": 0 },
            { "t": "false joys", "nl": 0 },
            { "t": "of time!", "nl": 1 },
            { "t": "But", "nl": 0 },
            { "t": "guide my steps", "nl": 0 },
            { "t": "to", "nl": 0 },
            { "t": "endless life", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "bliss.", "nl": 1 },
            { "t": "Greatness,", "nl": 0 },
            { "t": "or", "nl": 0 },
            { "t": "Goodness,", "nl": 0 },
            { "t": "say", "nl": 0 },
            { "t": "what", "nl": 0 },
            { "t": "I shall", "nl": 0 },
            { "t": "call thee,", "nl": 1 },
            { "t": "To", "nl": 0 },
            { "t": "give me", "nl": 0 },
            { "t": "an", "nl": 0 },
            { "t": "higher appellation", "nl": 0 },
            { "t": "still,", "nl": 1 },
            { "t": "Teach me", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "better strain,", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "nobler lay,", "nl": 1 },
            { "t": "O", "nl": 0 },
            { "t": "thou,", "nl": 0 },
            { "t": "enthron’d with Cherubs", "nl": 0 },
            { "t": "in", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "realms of day.", "nl": 2 },
        ],
    },

    {
        "id": 47,
        "author": "Phillis Wheatley",
        "author_img": "phillis_wheatley2.jpg",
        "title": "A Hymn to the Morning",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/409",
        "lines": [
            { "t": "Attend", "nl": 0 },
            { "t": "my", "nl": 0 },
            { "t": "lays,", "nl": 0 },
            { "t": "ye", "nl": 0 },
            { "t": "ever honour’d", "nl": 0 },
            { "t": "nine,", "nl": 1 },
            { "t": "Assist", "nl": 0 },
            { "t": "my labours,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "my strains", "nl": 0 },
            { "t": "refine;", "nl": 1 },
            { "t": "In", "nl": 0 },
            { "t": "smoothest", "nl": 0 },
            { "t": "numbers", "nl": 0 },
            { "t": "pour", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "notes", "nl": 0 },
            { "t": "along,", "nl": 1 },
            { "t": "For", "nl": 0 },
            { "t": "bright Aurora", "nl": 0 },
            { "t": "now", "nl": 0 },
            { "t": "demands", "nl": 0 },
            { "t": "my song.", "nl": 1 },
            { "t": "Aurora", "nl": 0 },
            { "t": "hail,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "all", "nl": 0 },
            { "t": "the thousand", "nl": 0 },
            { "t": "dies,", "nl": 1 },
            { "t": "Which", "nl": 0 },
            { "t": "deck", "nl": 0 },
            { "t": "thy progress", "nl": 0 },
            { "t": "through", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "vaulted skies:", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "morn awakes,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "wide extends", "nl": 0 },
            { "t": "her rays,", "nl": 0 },
            { "t": "On", "nl": 0 },
            { "t": "ev’ry leaf", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "gentle zephyr", "nl": 0 },
            { "t": "plays;", "nl": 1 },
            { "t": "Harmonious", "nl": 0 },
            { "t": "lays", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "feather’d", "nl": 0 },
            { "t": "race resume,", "nl": 1 },
            { "t": "Dart", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "bright eye,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "shake", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "painted plume.", "nl": 1 },
            { "t": "Ye", "nl": 0 },
            { "t": "shady groves,", "nl": 0 },
            { "t": "your", "nl": 0 },
            { "t": "verdant gloom", "nl": 0 },
            { "t": "display", "nl": 1 },
            { "t": "To", "nl": 0 },
            { "t": "shield", "nl": 0 },
            { "t": "your poet", "nl": 0 },
            { "t": "from", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "burning day:", "nl": 1 },
            { "t": "Calliope", "nl": 0 },
            { "t": "awake", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "sacred lyre,", "nl": 1 },
            { "t": "While", "nl": 0 },
            { "t": "thy", "nl": 0 },
            { "t": "fair sisters", "nl": 0 },
            { "t": "fan", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "pleasing fire:", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "bow’rs,", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "gales,", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "variegated skies", "nl": 1 },
            { "t": "In", "nl": 0 },
            { "t": "all", "nl": 0 },
            { "t": "their pleasures", "nl": 0 },
            { "t": "in", "nl": 0 },
            { "t": "my bosom rise.", "nl": 1 },
            { "t": "See", "nl": 0 },
            { "t": "in the east", "nl": 0 },
            { "t": "th’", "nl": 0 },
            { "t": "illustrious", "nl": 0 },
            { "t": "king of day!", "nl": 1 },
            { "t": "His", "nl": 0 },
            { "t": "rising radiance", "nl": 0 },
            { "t": "drives", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "shades", "nl": 0 },
            { "t": "away—", "nl": 1 },
            { "t": "But", "nl": 0 },
            { "t": "Oh!", "nl": 0 },
            { "t": "I feel", "nl": 0 },
            { "t": "his", "nl": 0 },
            { "t": "fervid beams", "nl": 0 },
            { "t": "too strong,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "scarce begun,", "nl": 0 },
            { "t": "concludes", "nl": 0 },
            { "t": "th’", "nl": 0 },
            { "t": "abortive song.", "nl": 2 },
        ],
    },
    {
        "id": 48,
        "author": "Amy Lowell",
        "author_img": "amy_lowell.jpg",
        "title": "Roads",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/261",
        "lines": [
            { "t": "I know", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "country", "nl": 0 },
            { "t": "laced with roads,", "nl": 1 },
            { "t": "They", "nl": 0 },
            { "t": "join the hills", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "they", "nl": 0 },
            { "t": "span the brooks,", "nl": 1 },
            { "t": "They", "nl": 0 },
            { "t": "weave", "nl": 0 },
            { "t": "like a shuttle", "nl": 0 },
            { "t": "between", "nl": 0 },
            { "t": "broad fields,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "slide discreetly", "nl": 0 },
            { "t": "through", "nl": 0 },
            { "t": "hidden nooks.", "nl": 1 },
            { "t": "They", "nl": 0 },
            { "t": "are", "nl": 0 },
            { "t": "canopied", "nl": 0 },
            { "t": "like", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "Persian dome", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "carpeted", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "orient dyes.", "nl": 1 },
            { "t": "They", "nl": 0 },
            { "t": "are", "nl": 0 },
            { "t": "myriad-voiced,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "musical,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "scented", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "happiest memories.", "nl": 1 },
            { "t": "O", "nl": 0 },
            { "t": "Winding roads", "nl": 0 },
            { "t": "that", "nl": 0 },
            { "t": "I know", "nl": 0 },
            { "t": "so well,", "nl": 1 },
            { "t": "Every", "nl": 0 },
            { "t": "twist and turn,", "nl": 0 },
            { "t": "every", "nl": 0 },
            { "t": "hollow and hill!", "nl": 1 },
            { "t": "They", "nl": 0 },
            { "t": "are", "nl": 0 },
            { "t": "set in my heart", "nl": 0 },
            { "t": "to", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "pulsing tune", "nl": 1 },
            { "t": "Gay", "nl": 0 },
            { "t": "as", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "honey-bee humming", "nl": 0 },
            { "t": "in June.", "nl": 1 },
            { "t": "'T is", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "rhythmic", "nl": 0 },
            { "t": "beat", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "horse's feet", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "pattering paws", "nl": 0 },
            { "t": "of a", "nl": 0 },
            { "t": "sheep-dog bitch;", "nl": 1 },
            { "t": "'T is", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "creaking trees,", "nl": 0 },
            { "t": "and the", "nl": 0 },
            { "t": "singing breeze,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "rustle of leaves", "nl": 0 },
            { "t": "in", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "road-side ditch.", "nl": 2 },
            { "t": "A cow in a meadow", "nl": 0 },
            { "t": "shakes", "nl": 0 },
            { "t": "her", "nl": 0 },
            { "t": "bell", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "the notes", "nl": 0 },
            { "t": "cut sharp", "nl": 0 },
            { "t": "through", "nl": 0 },
            { "t": "the autumn air,", "nl": 1 },
            { "t": "Each chattering brook", "nl": 0 },
            { "t": "bears", "nl": 0 },
            { "t": "a fleet of leaves", "nl": 1 },
            { "t": "Their cargo", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "rainbow,", "nl": 0 },
            { "t": "and just now", "nl": 0 },
            { "t": "where", "nl": 1 },
            { "t": "The sun", "nl": 0 },
            { "t": "splashed bright", "nl": 0 },
            { "t": "on the road ahead", "nl": 1 },
            { "t": "A startled rabbit", "nl": 0 },
            { "t": "quivered and fled.", "nl": 1 },
            { "t": "O Uphill roads", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "roads that dip down!", "nl": 1 },
            { "t": "You", "nl": 0 },
            { "t": "curl", "nl": 0 },
            { "t": "your", "nl": 0 },
            { "t": "sun-spattered length", "nl": 0 },
            { "t": "along,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "your march", "nl": 0 },
            { "t": "is beaten", "nl": 0 },
            { "t": "into a song", "nl": 1 },
            { "t": "By the", "nl": 0 },
            { "t": "softly ringing", "nl": 0 },
            { "t": "hoofs of a horse", "nl": 1 },
            { "t": "And the", "nl": 0 },
            { "t": "panting breath of the dogs", "nl": 0 },
            { "t": "I love.", "nl": 1 },
            { "t": "The pageant of Autumn", "nl": 0 },
            { "t": "follows its course", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "the blue sky of Autumn", "nl": 0 },
            { "t": "laughs above.", "nl": 2 },
            { "t": "And", "nl": 0 },
            { "t": "the song", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "the country", "nl": 0 },
            { "t": "become as one,", "nl": 1 },
            { "t": "I see it as music,", "nl": 0 },
            { "t": "I hear it as light;", "nl": 1 },
            { "t": "Prismatic and shimmering,", "nl": 0 },
            { "t": "trembling to tone,", "nl": 1 },
            { "t": "The land of desire,", "nl": 0 },
            { "t": "my soul's delight.", "nl": 1 },
            { "t": "And always", "nl": 0 },
            { "t": "it beats", "nl": 0 },
            { "t": "in my listening ears", "nl": 1 },
            { "t": "With the gentle thud", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "a horse's stride,", "nl": 1 },
            { "t": "With", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "swift-falling steps", "nl": 0 },
            { "t": "of many dogs,", "nl": 1 },
            { "t": "Following,", "nl": 0 },
            { "t": "following", "nl": 0 },
            { "t": "at my side.", "nl": 1 },
            { "t": "O Roads", "nl": 0 },
            { "t": "that", "nl": 0 },
            { "t": "journey to fairyland!", "nl": 1 },
            { "t": "Radiant highways", "nl": 0 },
            { "t": "whose", "nl": 0 },
            { "t": "vistas gleam,", "nl": 1 },
            { "t": "Leading me on,", "nl": 0 },
            { "t": "under crimson leaves,", "nl": 1 },
            { "t": "To the", "nl": 0 },
            { "t": "opaline gates", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "the Castles of Dream.", "nl": 2 },
        ],
    },

    {
        "id": 49,
        "author": "Sara Teasdale",
        "author_img": "sara_teasdale.jpg",
        "title": "Places",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/591",
        "lines": [
            { "t": "Places I love", "nl": 0 },
            { "t": "come back", "nl": 0 },
            { "t": "to me", "nl": 0 },
            { "t": "like music,", "nl": 1 },
            { "t": "Hush me", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "heal me", "nl": 0 },
            { "t": "when I am", "nl": 0 },
            { "t": "very tired;", "nl": 1 },
            { "t": "I see", "nl": 0 },
            { "t": "the oak woods", "nl": 0 },
            { "t": "at", "nl": 0 },
            { "t": "Saxton's", "nl": 0 },
            { "t": "flaming", "nl": 1 },
            { "t": "In a flare", "nl": 0 },
            { "t": "of crimson", "nl": 0 },
            { "t": "by the frost", "nl": 0 },
            { "t": "newly fired;", "nl": 1 },
            { "t": "And I am", "nl": 0 },
            { "t": "thirsty", "nl": 0 },
            { "t": "for the", "nl": 0 },
            { "t": "spring", "nl": 0 },
            { "t": "in the valley", "nl": 0 },
            { "t": "As", "nl": 0 },
            { "t": "for a kiss", "nl": 0 },
            { "t": "ungiven", "nl": 0 },
            { "t": "and long", "nl": 0 },
            { "t": "desired.", "nl": 2 },
            { "t": "I know", "nl": 0 },
            { "t": "a bright world", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "snowy hills", "nl": 0 },
            { "t": "at Boonton,", "nl": 1 },
            { "t": "A", "nl": 0 },
            { "t": "blue and white", "nl": 0 },
            { "t": "dazzling light", "nl": 0 },
            { "t": "on everything", "nl": 0 },
            { "t": "one sees,", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "ice-covered", "nl": 0 },
            { "t": "branches", "nl": 0 },
            { "t": "of the", "nl": 0 },
            { "t": "hemlocks sparkle", "nl": 1 },
            { "t": "Bending low", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "tinkling", "nl": 0 },
            { "t": "in the", "nl": 0 },
            { "t": "sharp", "nl": 0 },
            { "t": "thin breeze,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "iridescent", "nl": 0 },
            { "t": "crystals", "nl": 0 },
            { "t": "fall and crackle", "nl": 0 },
            { "t": "on the", "nl": 0 },
            { "t": "snow-crust", "nl": 1 },
            { "t": "With", "nl": 0 },
            { "t": "the winter sun", "nl": 0 },
            { "t": "drawing", "nl": 0 },
            { "t": "cold blue shadows", "nl": 0 },
            { "t": "from the", "nl": 0 },
            { "t": "trees.", "nl": 2 },
            { "t": "Violet now,", "nl": 0 },
            { "t": "in", "nl": 0 },
            { "t": "veil on veil", "nl": 0 },
            { "t": "of evening", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "hills", "nl": 0 },
            { "t": "across from", "nl": 0 },
            { "t": "Cromwell", "nl": 0 },
            { "t": "grow dreamy", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "far;", "nl": 1 },
            { "t": "A", "nl": 0 },
            { "t": "wood-thrush", "nl": 0 },
            { "t": "is", "nl": 0 },
            { "t": "singing", "nl": 0 },
            { "t": "soft", "nl": 0 },
            { "t": "as a", "nl": 0 },
            { "t": "viol", "nl": 1 },
            { "t": "In the", "nl": 0 },
            { "t": "heart", "nl": 0 },
            { "t": "of the", "nl": 0 },
            { "t": "hollow", "nl": 0 },
            { "t": "where", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "dark pools", "nl": 0 },
            { "t": "are;", "nl": 0 },
            { "t": " ", "nl": 0 },
            { "t": "The", "nl": 0 },
            { "t": "primrose", "nl": 0 },
            { "t": "has opened", "nl": 0 },
            { "t": "her", "nl": 0 },
            { "t": "pale yellow", "nl": 0 },
            { "t": "flowers", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "heaven", "nl": 0 },
            { "t": "is", "nl": 0 },
            { "t": "lighting", "nl": 0 },
            { "t": "star after star.", "nl": 1 },
            { "t": " ", "nl": 0 },
            { "t": "Places", "nl": 0 },
            { "t": "I love", "nl": 0 },
            { "t": "come back", "nl": 0 },
            { "t": "to me", "nl": 0 },
            { "t": "like music—", "nl": 1 },
            { "t": "Mid-ocean,", "nl": 0 },
            { "t": "midnight,", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "waves buzz drowsily;", "nl": 1 },
            { "t": "In the", "nl": 0 },
            { "t": "ship's", "nl": 0 },
            { "t": "deep churning", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "eerie phosphorescence", "nl": 1 },
            { "t": "Is like", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "souls", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "people", "nl": 0 },
            { "t": "who were", "nl": 0 },
            { "t": "drowned", "nl": 0 },
            { "t": "at sea,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "I can", "nl": 0 },
            { "t": "hear", "nl": 0 },
            { "t": "a man's", "nl": 0 },
            { "t": "voice,", "nl": 0 },
            { "t": "speaking,", "nl": 0 },
            { "t": "hushed,", "nl": 0 },
            { "t": "insistent,", "nl": 1 },
            { "t": "At midnight,", "nl": 0 },
            { "t": "in", "nl": 0 },
            { "t": "mid-ocean,", "nl": 0 },
            { "t": "hour on hour", "nl": 0 },
            { "t": "to me.", "nl": 2 },
        ],
    },
    {
        "id": 50,
        "author": "Emma Lazarus",
        "author_img": "emma_lazarus.jpg",
        "title": "A New Colossus",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/3295",
        "lines": [
            { "t": "Not like", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "brazen giant", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "Greek fame,", "nl": 1 },
            { "t": "With conquering", "nl": 0 },
            { "t": "limbs astride", "nl": 0 },
            { "t": "from", "nl": 0 },
            { "t": "land to land;", "nl": 1 },
            { "t": "Here", "nl": 0 },
            { "t": "at our", "nl": 0 },
            { "t": "sea-washed,", "nl": 0 },
            { "t": "sunset", "nl": 0 },
            { "t": "gates", "nl": 0 },
            { "t": "shall stand", "nl": 1 },
            { "t": "A mighty woman", "nl": 0 },
            { "t": "with a torch,", "nl": 0 },
            { "t": "whose flame", "nl": 1 },
            { "t": "Is the", "nl": 0 },
            { "t": "imprisoned lightning,", "nl": 0 },
            { "t": "and her name", "nl": 1 },
            { "t": "Mother", "nl": 0 },
            { "t": "of Exiles.", "nl": 0 },
            { "t": "From her", "nl": 0 },
            { "t": "beacon-hand", "nl": 1 },
            { "t": "Glows", "nl": 0 },
            { "t": "world-wide", "nl": 0 },
            { "t": "welcome;", "nl": 0 },
            { "t": "her mild eyes", "nl": 0 },
            { "t": "command", "nl": 1 },
            { "t": "The air-bridged", "nl": 0 },
            { "t": "harbor", "nl": 0 },
            { "t": "that", "nl": 0 },
            { "t": "twin cities", "nl": 0 },
            { "t": "frame.", "nl": 1 },
            { "t": "“Keep,", "nl": 0 },
            { "t": "ancient lands,", "nl": 0 },
            { "t": "your storied pomp!”", "nl": 0 },
            { "t": "cries she", "nl": 1 },
            { "t": "With", "nl": 0 },
            { "t": "silent lips.", "nl": 0 },
            { "t": "“Give me", "nl": 0 },
            { "t": "your tired,", "nl": 0 },
            { "t": "your poor,", "nl": 1 },
            { "t": "Your", "nl": 0 },
            { "t": "huddled masses", "nl": 0 },
            { "t": "yearning", "nl": 0 },
            { "t": "to be free,", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "wretched refuse", "nl": 0 },
            { "t": "of your", "nl": 0 },
            { "t": "teeming shore.", "nl": 1 },
            { "t": "Send these,", "nl": 0 },
            { "t": "the homeless,", "nl": 0 },
            { "t": "tempest-tost", "nl": 0 },
            { "t": "to me,", "nl": 1 },
            { "t": "I lift", "nl": 0 },
            { "t": "my lamp", "nl": 0 },
            { "t": "beside", "nl": 0 },
            { "t": "the golden door!”", "nl": 2 },
        ],
    },
    {
        "id": 51,
        "author": "Emma Lazarus",
        "author_img": "emma_lazarus.jpg",
        "title": "Age and Death",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/3295",
        "lines": [
            { "t": "Come closer,", "nl": 0 },
            { "t": "kind,", "nl": 0 },
            { "t": "white,", "nl": 0 },
            { "t": "long-familiar friend,", "nl": 1 },
            { "t": "Embrace me,", "nl": 0 },
            { "t": "fold me", "nl": 0 },
            { "t": "to thy broad,", "nl": 0 },
            { "t": "soft breast.", "nl": 1 },
            { "t": "Life", "nl": 0 },
            { "t": "has grown", "nl": 0 },
            { "t": "strange and cold,", "nl": 0 },
            { "t": "but", "nl": 0 },
            { "t": "thou dost", "nl": 0 },
            { "t": "bend", "nl": 1 },
            { "t": "Mild eyes", "nl": 0 },
            { "t": "of blessing", "nl": 0 },
            { "t": "wooing", "nl": 0 },
            { "t": "to my rest.", "nl": 1 },
            { "t": "So often", "nl": 0 },
            { "t": "hast thou", "nl": 0 },
            { "t": "come,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "from my side", "nl": 1 },
            { "t": "So many", "nl": 0 },
            { "t": "hast thou", "nl": 0 },
            { "t": "lured,", "nl": 0 },
            { "t": "I only bide", "nl": 1 },
            { "t": "Thy beck,", "nl": 0 },
            { "t": "to follow", "nl": 0 },
            { "t": "glad", "nl": 0 },
            { "t": "thy steps", "nl": 0 },
            { "t": "divine.", "nl": 1 },
            { "t": "Thy world", "nl": 0 },
            { "t": "is peopled", "nl": 0 },
            { "t": "for me;", "nl": 0 },
            { "t": "this world's", "nl": 0 },
            { "t": "bare.", "nl": 1 },
            { "t": "Through all", "nl": 0 },
            { "t": "these years", "nl": 0 },
            { "t": "my couch", "nl": 0 },
            { "t": "thou didst", "nl": 0 },
            { "t": "prepare.", "nl": 1 },
            { "t": "Thou art", "nl": 0 },
            { "t": "supreme Love", "nl": 0 },
            { "t": "—kiss me", "nl": 0 },
            { "t": "—I am thine!", "nl": 2 },
        ],
    },
    {
        "id": 52,
        "author": "William Shakespeare",
        "author_img": "william_shakespeare.jpg",
        "title": "Macbeth",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/2264",
        "lines": [
            { "t": "There", "nl": 0 },
            { "t": "would have been", "nl": 0 },
            { "t": "a time", "nl": 0 },
            { "t": "for", "nl": 0 },
            { "t": "such a word.", "nl": 1 },
            { "t": "Tomorrow,", "nl": 0 },
            { "t": "and tomorrow,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "tomorrow,", "nl": 1 },
            { "t": "Creeps", "nl": 0 },
            { "t": "in this", "nl": 0 },
            { "t": "petty pace", "nl": 0 },
            { "t": "from day to day", "nl": 1 },
            { "t": "To the last", "nl": 0 },
            { "t": "syllable", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "recorded time,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "all our yesterdays", "nl": 0 },
            { "t": "have", "nl": 0 },
            { "t": "lighted fools", "nl": 1 },
            { "t": "The way", "nl": 0 },
            { "t": "to", "nl": 0 },
            { "t": "dusty death.", "nl": 0 },
            { "t": "Out, out,", "nl": 0 },
            { "t": "brief candle!", "nl": 1 },
            { "t": "Life’s but", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "walking shadow,", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "poor player", "nl": 1 },
            { "t": "That", "nl": 0 },
            { "t": "struts and frets", "nl": 0 },
            { "t": "his", "nl": 0 },
            { "t": "hour", "nl": 0 },
            { "t": "upon the stage", "nl": 1 },
            { "t": "And then", "nl": 0 },
            { "t": "is", "nl": 0 },
            { "t": "heard", "nl": 0 },
            { "t": "no more:", "nl": 0 },
            { "t": "it is", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "tale", "nl": 1 },
            { "t": "Told", "nl": 0 },
            { "t": "by", "nl": 0 },
            { "t": "an idiot,", "nl": 0 },
            { "t": "full of sound", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "fury,", "nl": 1 },
            { "t": "Signifying nothing.", "nl": 2 },
        ],
    },
    {
        "id": 53,
        "author": "William Shakespeare",
        "author_img": "william_shakespeare.jpg",
        "title": "Hamlet",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/2265",
        "lines": [
            { "t": "To be,", "nl": 0 },
            { "t": "or not to be,", "nl": 0 },
            { "t": "that is the question,", "nl": 1 },
            { "t": "Whether", "nl": 0 },
            { "t": "'tis nobler", "nl": 0 },
            { "t": "in the mind", "nl": 0 },
            { "t": "to suffer", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "slings and arrows", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "outrageous fortune,", "nl": 1 },
            { "t": "Or", "nl": 0 },
            { "t": "to take arms", "nl": 0 },
            { "t": "against", "nl": 0 },
            { "t": "a sea of troubles,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "by", "nl": 0 },
            { "t": "opposing", "nl": 0 },
            { "t": "end them?", "nl": 0 },
            { "t": "To die:", "nl": 0 },
            { "t": "to sleep;", "nl": 1 },
            { "t": "No more;", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "by a sleep", "nl": 0 },
            { "t": "to say", "nl": 0 },
            { "t": "we end", "nl": 1 },
            { "t": "The heart-ache", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "thousand natural shocks", "nl": 1 },
            { "t": "That", "nl": 0 },
            { "t": "flesh", "nl": 0 },
            { "t": "is heir to,", "nl": 0 },
            { "t": "'tis a consummation", "nl": 1 },
            { "t": "Devoutly to be wish'd.", "nl": 0 },
            { "t": "To die,", "nl": 0 },
            { "t": "to sleep;", "nl": 1 },
            { "t": "To sleep:", "nl": 0 },
            { "t": "perchance to dream:", "nl": 0 },
            { "t": "ay, there's the rub;", "nl": 1 },
            { "t": "For", "nl": 0 },
            { "t": "in that sleep of death", "nl": 0 },
            { "t": "what dreams", "nl": 0 },
            { "t": "may come", "nl": 1 },
            { "t": "When", "nl": 0 },
            { "t": "we have", "nl": 0 },
            { "t": "shuffled off", "nl": 0 },
            { "t": "this", "nl": 0 },
            { "t": "mortal coil,", "nl": 1 },
            { "t": "Must", "nl": 0 },
            { "t": "give us pause:", "nl": 0 },
            { "t": "there's", "nl": 0 },
            { "t": "the respect", "nl": 1 },
            { "t": "That makes calamity", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "so", "nl": 0 },
            { "t": "long life;", "nl": 1 },
            { "t": "For", "nl": 0 },
            { "t": "who would bear", "nl": 0 },
            { "t": "the whips", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "scorns of time,", "nl": 1 },
            { "t": "The oppressor's wrong,", "nl": 0 },
            { "t": "the proud man's", "nl": 0 },
            { "t": "contumely,", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "pangs", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "despised love,", "nl": 0 },
            { "t": "the law's delay,", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "insolence of office", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "spurns", "nl": 1 },
            { "t": "That", "nl": 0 },
            { "t": "patient merit", "nl": 0 },
            { "t": "of the", "nl": 0 },
            { "t": "unworthy takes,", "nl": 1 },
            { "t": "When", "nl": 0 },
            { "t": "he himself", "nl": 0 },
            { "t": "might", "nl": 0 },
            { "t": "his", "nl": 0 },
            { "t": "quietus make", "nl": 1 },
            { "t": "With", "nl": 0 },
            { "t": "a", "nl": 0 },
            { "t": "bare bodkin?", "nl": 0 },
            { "t": "who would", "nl": 0 },
            { "t": "fardels bear,", "nl": 1 },
            { "t": "To grunt", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "sweat", "nl": 0 },
            { "t": "under a weary life,", "nl": 1 },
            { "t": "But that", "nl": 0 },
            { "t": "the dread of something", "nl": 0 },
            { "t": "after death,", "nl": 1 },
            { "t": "The undiscover'd country", "nl": 0 },
            { "t": "from", "nl": 0 },
            { "t": "whose bourn", "nl": 1 },
            { "t": "No traveller returns,", "nl": 0 },
            { "t": "puzzles the will", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "makes us", "nl": 0 },
            { "t": "rather", "nl": 0 },
            { "t": "bear those ills", "nl": 0 },
            { "t": "we have", "nl": 1 },
            { "t": "Than", "nl": 0 },
            { "t": "fly to others", "nl": 0 },
            { "t": "that", "nl": 0 },
            { "t": "we know not of?", "nl": 1 },
            { "t": "Thus", "nl": 0 },
            { "t": "conscience", "nl": 0 },
            { "t": "does make", "nl": 0 },
            { "t": "cowards", "nl": 0 },
            { "t": "of us all;", "nl": 1 },
            { "t": "And thus", "nl": 0 },
            { "t": "the native hue", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "resolution", "nl": 1 },
            { "t": "Is", "nl": 0 },
            { "t": "sicklied o'er", "nl": 0 },
            { "t": "with", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "pale cast of thought,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "enterprises", "nl": 0 },
            { "t": "of", "nl": 0 },
            { "t": "great pith and moment", "nl": 1 },
            { "t": "With", "nl": 0 },
            { "t": "this regard", "nl": 0 },
            { "t": "their currents", "nl": 0 },
            { "t": "turn awry,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "lose", "nl": 0 },
            { "t": "the name of action.—", "nl": 0 },
            { "t": "Soft you now!", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "fair Ophelia!", "nl": 0 },
            { "t": "Nymph,", "nl": 0 },
            { "t": "in thy orisons", "nl": 1 },
            { "t": "Be", "nl": 0 },
            { "t": "all my sins", "nl": 0 },
            { "t": "remember'd.", "nl": 2 },
        ],
    },
    {
        "id": 54,
        "author": "Emily Dickinson",
        "author_img": "emily_dickinson.jpg",
        "title": "I Taste a Liquor Never Brewed",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/12242",
        "lines": [
            { "t": "I taste", "nl": 0 },
            { "t": "a liquor", "nl": 0 },
            { "t": "never brewed,", "nl": 2 },
            { "t": "From", "nl": 0 },
            { "t": "tankards", "nl": 0 },
            { "t": "scooped in pearl;", "nl": 1 },
            { "t": "Not", "nl": 0 },
            { "t": "all the vats", "nl": 0 },
            { "t": "upon the Rhine", "nl": 1 },
            { "t": "Yield", "nl": 0 },
            { "t": "such", "nl": 0 },
            { "t": "an alcohol!", "nl": 1 },
            { "t": "Inebriate", "nl": 0 },
            { "t": "of air", "nl": 0 },
            { "t": "am I,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "debauchee of dew,", "nl": 1 },
            { "t": "Reeling,", "nl": 0 },
            { "t": "through", "nl": 0 },
            { "t": "endless summer days,", "nl": 1 },
            { "t": "From inns", "nl": 0 },
            { "t": "of molten blue.", "nl": 1 },
            { "t": "When landlords", "nl": 0 },
            { "t": "turn", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "drunken bee", "nl": 1 },
            { "t": "Out of", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "foxglove's door,", "nl": 1 },
            { "t": "When", "nl": 0 },
            { "t": "butterflies", "nl": 0 },
            { "t": "renounce their drams,", "nl": 1 },
            { "t": "I shall", "nl": 0 },
            { "t": "but", "nl": 0 },
            { "t": "drink the more!", "nl": 1 },
            { "t": "Till", "nl": 0 },
            { "t": "seraphs", "nl": 0 },
            { "t": "swing their snowy hats,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "saints", "nl": 0 },
            { "t": "to windows run,", "nl": 1 },
            { "t": "To see", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "little tippler", "nl": 1 },
            { "t": "Leaning against", "nl": 0 },
            { "t": "the sun!", "nl": 2 },
        ],
    },
    {
        "id": 55,
        "author": "Emily Dickinson",
        "author_img": "emily_dickinson.jpg",
        "title": "The Chariot",
        "section_title": "",
        "link": "https://www.gutenberg.org/ebooks/12242",
        "lines": [
            { "t": "Because", "nl": 0 },
            { "t": "I could not", "nl": 0 },
            { "t": "stop", "nl": 0 },
            { "t": "for", "nl": 0 },
            { "t": "Death,", "nl": 1 },
            { "t": "He", "nl": 0 },
            { "t": "kindly stopped", "nl": 0 },
            { "t": "for me;", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "carriage", "nl": 0 },
            { "t": "held", "nl": 0 },
            { "t": "but", "nl": 0 },
            { "t": "just ourselves", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "Immortality.", "nl": 1 },
            { "t": "We", "nl": 0 },
            { "t": "slowly drove,", "nl": 0 },
            { "t": "he knew", "nl": 0 },
            { "t": "no haste,", "nl": 1 },
            { "t": "And", "nl": 0 },
            { "t": "I had put away", "nl": 1 },
            { "t": "My labor,", "nl": 0 },
            { "t": "and", "nl": 0 },
            { "t": "my leisure too,", "nl": 1 },
            { "t": "For", "nl": 0 },
            { "t": "his civility.", "nl": 1 },
            { "t": "We passed", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "school", "nl": 0 },
            { "t": "where children played,", "nl": 1 },
            { "t": "Their lessons", "nl": 0 },
            { "t": "scarcely done;", "nl": 1 },
            { "t": "We", "nl": 0 },
            { "t": "passed", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "fields of gazing grain,", "nl": 1 },
            { "t": "We", "nl": 0 },
            { "t": "passed", "nl": 0 },
            { "t": "the setting sun.", "nl": 1 },
            { "t": "We", "nl": 0 },
            { "t": "paused", "nl": 0 },
            { "t": "before a house", "nl": 0 },
            { "t": "that seemed", "nl": 1 },
            { "t": "A", "nl": 0 },
            { "t": "swelling of the ground;", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "roof", "nl": 0 },
            { "t": "was", "nl": 0 },
            { "t": "scarcely visible,", "nl": 1 },
            { "t": "The", "nl": 0 },
            { "t": "cornice", "nl": 0 },
            { "t": "but", "nl": 0 },
            { "t": "a mound.", "nl": 1 },
            { "t": "Since then", "nl": 0 },
            { "t": "'t is centuries;", "nl": 0 },
            { "t": "but", "nl": 0 },
            { "t": "each", "nl": 1 },
            { "t": "Feels shorter", "nl": 0 },
            { "t": "than the day", "nl": 1 },
            { "t": "I first surmised", "nl": 0 },
            { "t": "the", "nl": 0 },
            { "t": "horses' heads", "nl": 1 },
            { "t": "Were", "nl": 0 },
            { "t": "toward eternity.", "nl": 1 },
        ],
    },
];

export default sources;
