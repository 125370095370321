const twitter_url = 'https://twitter.com/Re_hash_app';
const levelLabels = [
    'Trainee',
    'Copy-Paster',
    'Aspirant',
    'Word Recycler',
    'All-Purpose Repurposer',
    'Remix Artist',
    'Recontextualizer',
    'Stanza Scrambler',
    'Lit Major',
    'Citation Sensation',
    'Re:hash Master',
];

const profileTags = [
    'Nervous',
    'Official',
    'Clerical',
    'Irksome',
    'Mannered',
    'Splendid',
    'Silver',
    'Astral',
    'Risible',
    'Literary',
    'Recycled',
    'Fraudulent',
    'Synthesized',
    'Injurious',
    'Gelatinous',
    'Rabid',
    'Saucy',
    'Loyal',
    'Lazy',
    'Creative',
    'Disgraced',
    'Pointless',
    'Withering',
    'Scurrilous',
    'Undead',
    'Bashful',
    'Brittle',
    'Feckless',
    'Opinionated',
    'Tenacious',
    'Spasmodic',
    'Puckish',
    'Pliable',
    'Disdained',
    'Frolicsome',
    'Extra',
    'Writerly',
    'Mischievous',
    'Friendly',
    'Distressing',
    'Verdant',
    'Adventurous',
    'Limpid',
    'Polygonal',
    'Gossamer',
    'Mercurial',
    'Ghostly',
    'Roguish',
    'Slothful',
    'Sleuthy',
];
const sloganNouns = [
    'Money',
    'The joruney',
    'My past',
    'My future',
    'My life',
    'Your failure',
    'Life itself',
    'Reading',
    'Food',
    'Service',
    'My body',
    'Code',
    'The world',
    'Misery',
];
const sloganVerbs = [
    'makes',
    'begins',
    'puts',
    'is',
    'is like',
    'teaches',
    'loves',
    'hates',
    'enlightens',
    'bores',
    'eludes',
    'excites',
    'makes',
];
const sloganFinishers = [
    'me',
    'all that matters',
    'the world go round',
    'with me',
    'life',
    'with a single step',
    'the lotion',
    'legion',
    'instructive',
    'bright',
    'your life',
    'this',
    'nonsense',
    'this nonsense',
    'away now',
    'you',
    'everyone',
    'me laugh',
    'a temple',
    'a dumpster fire',
    'a thing of beauty',
    'my nemesis',
    'my undoing',
    'company',
];

const dateDisplayFormat = `MMM Do, YYYY @h:mm a`;

export {
    twitter_url,
    levelLabels,
    profileTags,
    sloganNouns,
    sloganVerbs,
    sloganFinishers,
    dateDisplayFormat,
}
