const messageSources = {
    "common":[
        "a",
        "able",
        "about",
        "after",
        "all",
        "also",
        "an",
        "and",
        "any",
        "as",
        "ask",
        "at",
        "back",
        "bad",
        "be",
        "because",
        "big",
        "but",
        "by",
        "call",
        "can",
        "case",
        "child",
        "come",
        "company",
        "could",
        "day",
        "different",
        "do",
        "early",
        "even",
        "eye",
        "fact",
        "feel",
        "few",
        "find",
        "first",
        "for",
        "from",
        "get",
        "give",
        "go",
        "good",
        "government",
        "great",
        "group",
        "hand",
        "have",
        "high",
        "how",
        "I",
        "if",
        "important",
        "in",
        "into",
        "intp",
        "it",
        "its",
        "just",
        "know",
        "large",
        "last",
        "leave",
        "life",
        "like",
        "little",
        "long",
        "look",
        "make",
        "man",
        "me",
        "most",
        "my",
        "new",
        "next",
        "no",
        "not",
        "now",
        "number",
        "of",
        "old",
        "on",
        "one",
        "only",
        "or",
        "other",
        "our",
        "out",
        "over",
        "own",
        "part",
        "people",
        "person",
        "place",
        "point",
        "problem",
        "public",
        "right",
        "same",
        "say",
        "see",
        "seem",
        "small",
        "so",
        "some",
        "take",
        "tell",
        "than",
        "that",
        "the",
        "their",
        "them",
        "then",
        "there",
        "these",
        "they",
        "thing",
        "think",
        "this",
        "time",
        "to",
        "try",
        "two",
        "up",
        "us",
        "use",
        "want",
        "way",
        "we",
        "week",
        "well",
        "what",
        "when",
        "which",
        "who",
        "will",
        "with",
        "woman",
        "work",
        "world",
        "would",
        "year",
        "you",
        "young",
        "your"
    ],
    "adjectives":[
        "abashed",
        "abnormal",
        "abrasive",
        "abrupt",
        "absurd",
        "adolescent",
        "afraid",
        "alluring",
        "amazing",
        "antsy",
        "arid",
        "avaricious",
        "big-hearted",
        "bland",
        "brash",
        "brief",
        "certain",
        "cheerful",
        "chief",
        "cloudy",
        "colorless",
        "common",
        "conscious",
        "continuous",
        "curved",
        "curvy",
        "cylindrical",
        "dead",
        "dear",
        "defeated",
        "devilish",
        "discrete",
        "educational",
        "electrical",
        "electronic",
        "elfin",
        "evergreen",
        "exalted",
        "excitable",
        "excited",
        "extraneous",
        "exultant",
        "female",
        "finished",
        "flawless",
        "flickering",
        "forceful",
        "former",
        "forthright",
        "frail",
        "frank",
        "frantic",
        "freezing",
        "frigid",
        "front",
        "furtive",
        "gelatinous",
        "ghastly",
        "gleaming",
        "gloomy",
        "grand",
        "grassy",
        "great",
        "grizzled",
        "grotesque",
        "growling",
        "gummy",
        "hapless",
        "haunted",
        "helpful",
        "hospitable",
        "humming",
        "hurtful",
        "immense",
        "imperfect",
        "infected",
        "insecure",
        "insidious",
        "irate",
        "joyful",
        "juvenile",
        "kaleidoscopic",
        "kind",
        "lacking",
        "lasting",
        "learned",
        "limited",
        "liquid",
        "living",
        "loud",
        "lowly",
        "lucky",
        "major",
        "maniacal",
        "married",
        "masculine",
        "meek",
        "mental",
        "merciful",
        "mild",
        "minor",
        "moral",
        "multicolored",
        "musty",
        "near",
        "nonstop",
        "omniscient",
        "optimistic",
        "outlandish",
        "overdue",
        "perky",
        "pessimistic",
        "posh",
        "prestigious",
        "prize",
        "purring",
        "quarrelsome",
        "quickest",
        "quiet",
        "quintessential",
        "rabid",
        "responsible",
        "rewarding",
        "roasted",
        "safe",
        "salty",
        "same",
        "scented",
        "shy",
        "sleepy",
        "sniveling",
        "soggy",
        "some",
        "special",
        "spotless",
        "squalid",
        "steady",
        "stupendous",
        "sturdy",
        "subsequent",
        "succinct",
        "tangy",
        "terrific",
        "thankful",
        "therapeutic",
        "timely",
        "total",
        "tough",
        "tricky",
        "truthful",
        "typical",
        "unable",
        "unconscious",
        "unfit",
        "unhappy",
        "unique",
        "unwritten",
        "vast",
        "visible",
        "vital",
        "voracious",
        "warlike",
        "watchful",
        "webbed",
        "whirlwind",
        "wholesale",
        "writhing",
        "yellow"
    ],
    "nouns":[
        "ability",
        "accident",
        "acrobat",
        "ad",
        "addition",
        "administration",
        "advertising",
        "application",
        "area",
        "assistance",
        "assistant",
        "association",
        "audience",
        "baseball",
        "basis",
        "bathroom",
        "bird",
        "blood",
        "boat",
        "boyfriend",
        "burrito",
        "bus",
        "camera",
        "car",
        "cell",
        "charity",
        "chest",
        "child",
        "classroom",
        "climate",
        "clouds",
        "clown",
        "combination",
        "committee",
        "communication",
        "company",
        "comparison",
        "competition",
        "contribution",
        "cookie",
        "count",
        "courage",
        "criticism",
        "data",
        "debt",
        "decision",
        "definition",
        "department",
        "depression",
        "description",
        "desk",
        "dinner",
        "direction",
        "director",
        "dirt",
        "disaster",
        "disease",
        "doctor",
        "drama",
        "driver",
        "duke",
        "effort",
        "employer",
        "employment",
        "entertainment",
        "environment",
        "establishment",
        "estate",
        "expression",
        "failure",
        "feedback",
        "fish",
        "fishing",
        "fortune",
        "garbage",
        "gate",
        "ghost",
        "gloom",
        "goal",
        "grocery",
        "guest",
        "gyro",
        "hall",
        "heart",
        "heath",
        "history",
        "housing",
        "impression",
        "industry",
        "inspection",
        "inspector",
        "instance",
        "interaction",
        "internet",
        "introduction",
        "king",
        "lady",
        "language",
        "law",
        "library",
        "location",
        "loss",
        "maintenance",
        "mansion",
        "manufacturer",
        "math",
        "meaning",
        "message",
        "method",
        "midnight",
        "mode",
        "mood",
        "narwhal",
        "nation",
        "net",
        "newspaper",
        "night",
        "noun",
        "orange",
        "organization",
        "oven",
        "pair",
        "paper",
        "passion",
        "patience",
        "penalty",
        "percentage",
        "permission",
        "phantasm",
        "philosophy",
        "pho",
        "phone",
        "piano",
        "pie",
        "plasma",
        "platform",
        "player",
        "poet",
        "poetry",
        "policy",
        "power",
        "preference",
        "presence",
        "presentation",
        "problem",
        "procedure",
        "product",
        "queen",
        "reality",
        "recognition",
        "recommendation",
        "recording",
        "replacement",
        "republic",
        "resource",
        "revolution",
        "river",
        "sample",
        "satisfaction",
        "scene",
        "seal",
        "sector",
        "serf",
        "shirt",
        "shoe",
        "signature",
        "significance",
        "singer",
        "sir",
        "skill",
        "software",
        "speaker",
        "storage",
        "story",
        "stranger",
        "strategy",
        "submarine",
        "suggestion",
        "supermarket",
        "system",
        "tackle",
        "tale",
        "teacher",
        "teaching",
        "technology",
        "television",
        "temperature",
        "tennis",
        "tongue",
        "town",
        "train",
        "understanding",
        "variation",
        "variety",
        "weakness",
        "week",
        "wife",
        "world",
        "writing",
        "youth"
    ],
    "verbs":[
        "absorb",
        "account",
        "accumulate",
        "act",
        "affect",
        "aid",
        "allege",
        "alter",
        "approach",
        "arise",
        "assert",
        "assist",
        "assume",
        "attain",
        "attribute",
        "avoid",
        "await",
        "beat",
        "become",
        "begin",
        "believe",
        "bid",
        "bind",
        "blow",
        "boost",
        "bother",
        "bow",
        "buy",
        "capture",
        "carry",
        "carve",
        "catch",
        "challenge",
        "chase",
        "collapse",
        "collect",
        "combine",
        "come",
        "command",
        "comment",
        "commission",
        "commit",
        "complete",
        "confine",
        "confront",
        "connect",
        "continue",
        "convey",
        "cope",
        "correct",
        "decorate",
        "deem",
        "demonstrate",
        "descend",
        "design",
        "desire",
        "determine",
        "develop",
        "devote",
        "dig",
        "diminish",
        "dismiss",
        "disturb",
        "divide",
        "double",
        "doubt",
        "drag",
        "drain",
        "drift",
        "ease",
        "elect",
        "eliminate",
        "embark",
        "embody",
        "employ",
        "endorse",
        "enforce",
        "ensure",
        "enter",
        "entertain",
        "erect",
        "estimate",
        "exceed",
        "execute",
        "expand",
        "expect",
        "extend",
        "extract",
        "feed",
        "feel",
        "find",
        "fit",
        "fix",
        "flow",
        "fold",
        "forgive",
        "form",
        "free",
        "fund",
        "gain",
        "gasp",
        "get",
        "going",
        "grant",
        "grin",
        "guarantee",
        "head",
        "help",
        "hesitate",
        "hire",
        "hurt",
        "identify",
        "import",
        "impress",
        "induce",
        "influence",
        "inherit",
        "inspect",
        "introduce",
        "invite",
        "judge",
        "jump",
        "keep",
        "know",
        "lack",
        "land",
        "lean",
        "line",
        "live",
        "look",
        "measure",
        "melt",
        "mention",
        "monitor",
        "motivate",
        "murmur",
        "mutter",
        "negotiate",
        "occupy",
        "operate",
        "oppose",
        "overcome",
        "pack",
        "participate",
        "pick",
        "place",
        "plan",
        "plant",
        "possess",
        "precede",
        "presume",
        "pretend",
        "print",
        "process",
        "proclaim",
        "produce",
        "prompt",
        "protect",
        "protest",
        "put",
        "qualify",
        "range",
        "record",
        "recover",
        "recruit",
        "reduce",
        "refuse",
        "register",
        "regulate",
        "reinforce",
        "remark",
        "reply",
        "reproduce",
        "request",
        "rescue",
        "result",
        "retain",
        "reveal",
        "reward",
        "rid",
        "ring",
        "risk",
        "roll",
        "run",
        "sack",
        "separate",
        "serve",
        "set",
        "show",
        "slow",
        "spare",
        "spell",
        "spin",
        "stand",
        "state",
        "stress",
        "stretch",
        "study",
        "substitute",
        "summon",
        "suppress",
        "survive",
        "sweep",
        "swing",
        "take",
        "tap",
        "target",
        "term",
        "throw",
        "transform",
        "transmit",
        "underline",
        "visit",
        "warn",
        "wave",
        "withdraw",
        "wonder",
        "yield"
    ],
    "adverbs":[
        "accidentally",
        "actually",
        "adventurously",
        "afterwards",
        "also",
        "annually",
        "badly",
        "below",
        "boastfully",
        "briskly",
        "continually",
        "currently",
        "devotedly",
        "dimly",
        "dramatically",
        "easily",
        "eventually",
        "everywhere",
        "exactly",
        "extremely",
        "faithfully",
        "fiercely",
        "fortunately",
        "frenetically",
        "frequently",
        "frightfully",
        "fully",
        "furiously",
        "generously",
        "gracefully",
        "healthily",
        "hopelessly",
        "immediately",
        "jealously",
        "joshingly",
        "joyously",
        "justly",
        "kindheartedly",
        "knowingly",
        "knowledgeably",
        "lazily",
        "limply",
        "lively",
        "longingly",
        "loudly",
        "monthly",
        "moreover",
        "mortally",
        "nearly",
        "necessarily",
        "only",
        "painfully",
        "perfectly",
        "personally",
        "physically",
        "possibly",
        "questioningly",
        "quirkily",
        "recently",
        "reproachfully",
        "rightfully",
        "roughly",
        "scarily",
        "seemingly",
        "seldom",
        "shrilly",
        "significantly",
        "silently",
        "smoothly",
        "sometimes",
        "somewhat",
        "suspiciously",
        "swiftly",
        "sympathetically",
        "technically",
        "tenderly",
        "terribly",
        "thankfully",
        "thoroughly",
        "thoughtfully",
        "tightly",
        "tomorrow",
        "ultimately",
        "unbearably",
        "unexpectedly",
        "unnaturally",
        "usually",
        "verbally",
        "violently",
        "warmly",
        "weakly",
        "wholly",
        "worriedly",
        "youthfully"
    ],
    "pronouns":[
        "anyone",
        "anything",
        "everyone",
        "everything",
        "I",
        "it",
        "itself",
        "me",
        "myself",
        "nothing",
        "one",
        "someone",
        "something",
        "them",
        "themselves",
        "they",
        "us",
        "we",
        "who",
        "you"
    ],
    "prepositions":[
        "aboard",
        "about",
        "above",
        "across",
        "after",
        "against",
        "along",
        "amid",
        "among",
        "around",
        "as",
        "at",
        "before",
        "behind",
        "below",
        "beneath",
        "beside",
        "besides",
        "between",
        "beyond",
        "but",
        "by",
        "concerning",
        "considering",
        "despite",
        "down",
        "during",
        "except",
        "excepting",
        "excluding",
        "following",
        "for",
        "from",
        "in",
        "inside",
        "into",
        "like",
        "minus",
        "near",
        "of",
        "off",
        "on",
        "onto",
        "opposite",
        "outside",
        "over",
        "past",
        "per",
        "plus",
        "regarding",
        "round",
        "save",
        "since",
        "than",
        "through",
        "to",
        "toward",
        "towards",
        "under",
        "underneath",
        "unlike",
        "until",
        "up",
        "upon",
        "versus",
        "via",
        "with",
        "within",
        "without"
    ],
    "interjections":[
        "aha",
        "ahoy",
        "alas",
        "bingo",
        "blah",
        "boo",
        "bravo",
        "bye",
        "cheers",
        "darn",
        "drat",
        "eek",
        "encore",
        "eureka",
        "fiddlesticks",
        "gadzooks",
        "gee",
        "golly",
        "goodness",
        "gosh",
        "ha",
        "hello",
        "hey",
        "hmm",
        "hurray",
        "nah",
        "no",
        "oh",
        "oops",
        "ouch",
        "ow",
        "phew",
        "rats",
        "shh",
        "thanks",
        "wahoo",
        "well",
        "whoa",
        "wow",
        "yeah",
        "yes",
        "yikes",
        "yippee",
        "yo"
    ],
    "punctuation":[
        ".",
        "?",
        "!",
        "'",
        "\"",
        ",",
    ],
};

export default messageSources;
